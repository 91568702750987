import { useContext, useEffect, useState, type FC } from "react";
import { useParams } from "react-router-dom";
import { Button, Input, Modal, SelectPicker } from "rsuite";
import {
  invitationAcceptHandler,
  invitationDetail,
} from "../repositories/general";
import { Invitation } from "../model/invitation";
import { LoadingContext } from "../objects/loading";
import { errorToast } from "../utils/helper-ui";
import { asyncLocalStorage, clearStorage } from "../utils/storage";
import { TOKEN } from "../objects/constants";

interface InvitationAcceptProps {}

const InvitationAccept: FC<InvitationAcceptProps> = ({}) => {
  const { invitationId } = useParams();
  const [showTerm, setShowTerm] = useState(false);
  const [invitation, setInvitation] = useState<Invitation | null>(null);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [fullName, setFullName] = useState("");
  const [companyID, setCompanyID] = useState<string | null>(null);
  const [companyName, setCompanyName] = useState("");

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (mounted) {
      invitationDetail(invitationId!)
        .then((res) => res.json())
        .then((v) => {
          setInvitation(v.data);
        });
    }
  }, [mounted, invitationId]);

  useEffect(() => {
    if (invitation?.user?.full_name) {
      setFullName(invitation?.user?.full_name);
    }
  }, [invitation]);

  const Loading = () => (
    <div className="flex items-center justify-center">
      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-orange-600"></div>
    </div>
  );

  const renderCompanyName = () => (
    <div className="flex flex-col space-y-2">
      <label
        htmlFor="full_name"
        className="text-sm font-medium text-gray-900 block"
      >
        Company Name
      </label>
      <input
        className="text-gray-900 bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
        id="company_name"
        name="company_name"
        value={companyName}
        placeholder="Company Name"
        onChange={(value) => setCompanyName(value.target.value)}
      />
    </div>
  );

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 bg-gradient-to-tr from-violet-900 to-purple-600">
      <img src={"/images/logo.png"} className="w-16 py-8" />

      <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
        <div className="p-6 space-y-4 md:space-y-4 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl ">
            Welcome to <strong>AGENSIA</strong>!
          </h1>
          <h3>
            Hallo,{" "}
            {invitation?.member?.user?.full_name != ""
              ? invitation?.member?.user?.full_name
              : invitation?.email}
          </h3>

          <p className="text-sm font-light leading-relaxed text-gray-500 ">
            You’ve been personally invited by{" "}
            <strong>{invitation?.invited_by?.user?.full_name}</strong> to join
            Agensia as part of the <strong>{invitation?.team?.title}</strong> at{" "}
            <strong>{invitation?.company?.name}</strong> . 🎉
          </p>
          <p className="text-sm font-light leading-relaxed text-gray-500 ">
            Before we begin, could you please tell us your full name?.
          </p>

          <div className="flex flex-col space-y-2">
            <label
              htmlFor="full_name"
              className="text-sm font-medium text-gray-900 block"
            >
              What is your full name?
            </label>
            <input
              type="text"
              id="full_name"
              name="full_name"
              className="text-gray-900 bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
              placeholder="John Doe"
              required
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>

          {invitation?.invitation_type == "CLIENT" &&
            !invitation?.user &&
            renderCompanyName()}
          {invitation?.invitation_type == "CLIENT" && invitation?.user && (
            <>
              {(invitation?.user?.companies ?? []).length > 0 ? (
                <div className="flex flex-col space-y-2">
                  <label
                    htmlFor="full_name"
                    className="text-sm font-medium text-gray-900 block"
                  >
                    Select Company
                  </label>
                  <SelectPicker
                    data={invitation?.user?.companies ?? []}
                    value={companyID}
                    labelKey="name"
                    valueKey="id"
                    onChange={(value) => setCompanyID(value)}
                  />
                </div>
              ) : (
                renderCompanyName()
              )}
            </>
          )}

          <p className="text-sm font-light leading-relaxed text-gray-500 ">
            Once you’re ready, click below to join the adventure! We can’t wait
            to start working with you.
          </p>

          <div className="flex items-center justify-center flex-col">
            {isLoading ? (
              <Loading />
            ) : (
              <Button
                appearance="primary"
                className="mt-4 w-full"
                onClick={async () => {
                  setIsLoading(true);
                  try {
                    let resp = await invitationAcceptHandler({
                      invitation_id: invitationId!,
                      accepted: true,
                      full_name: fullName,
                      client_company_id: companyID,
                      company_name: companyName,
                    });
                    const resJson = await resp.json();
                    await clearStorage();
                    await asyncLocalStorage.setItem(TOKEN, resJson.token);

                    window.location.href = "/";
                  } catch (error) {
                    errorToast(`${error}`);
                  } finally {
                    setIsLoading(false);
                  }
                  //TODO: implement accept invitation
                }}
              >
                Accept Invitation
              </Button>
            )}
            <Button
              appearance="link"
              onClick={() => {
                setShowTerm(true);
              }}
            >
              Show Terms and Conditions
            </Button>
          </div>
        </div>
      </div>
      <Modal open={showTerm} onClose={() => setShowTerm(false)}>
        <Modal.Header>Terms and Conditions</Modal.Header>
        <Modal.Body>
          <>
            <p>
              Welcome to <strong>AGENSIA</strong>! By using our platform, you
              agree to the following terms and conditions. Please read them
              carefully.
            </p>

            <ol className="list-decimal" start={1}>
              <li>
                <h2 className="section-title font-bold text-lg mt-4">
                  Acceptance of Terms
                </h2>
                <p>
                  By accessing or using the services provided by{" "}
                  <strong>AGENSIA</strong>, you agree to be bound by these
                  terms. If you do not agree to any part of the terms, you may
                  not access or use the service.
                </p>
              </li>
              <li>
                <h2 className="section-title font-bold text-lg mt-4">
                  Account Registration
                </h2>
                <p>
                  To use certain features of the platform, you may be required
                  to register an account. You agree to provide accurate,
                  complete, and current information when registering.
                </p>
              </li>
              <li>
                <h2 className="section-title font-bold text-lg mt-4">
                  User Responsibilities
                </h2>
                <p>As a user, you are responsible for:</p>
                <ul>
                  <li>
                    Maintaining the confidentiality of your account information
                  </li>
                  <li>All activities that occur under your account</li>
                  <li>
                    Not using the platform for any unlawful or prohibited
                    activities
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="section-title font-bold text-lg mt-4">
                  Intellectual Property
                </h2>
                <p>
                  All content on the platform, including text, graphics, logos,
                  and software, is the property of <strong>AGENSIA</strong> or
                  its licensors. You are not allowed to use any of our
                  intellectual property without prior written consent.
                </p>
              </li>
              <li>
                <h2 className="section-title font-bold text-lg mt-4">
                  Data Privacy
                </h2>
                <p>
                  Your privacy is important to us. Please refer to our{" "}
                  <a href="/privacy-policy" className="text-blue-400">
                    Privacy Policy
                  </a>{" "}
                  for details on how we collect, use, and protect your personal
                  information.
                </p>
              </li>
              <li>
                <h2 className="section-title font-bold text-lg mt-4">
                  Limitation of Liability
                </h2>
                <p>
                  <strong>AGENSIA</strong> will not be held liable for any
                  indirect, incidental, or consequential damages resulting from
                  the use or inability to use the platform, even if we have been
                  advised of the possibility of such damages.
                </p>
              </li>
              <li>
                <h2 className="section-title font-bold text-lg mt-4">
                  Termination
                </h2>
                <p>
                  We reserve the right to suspend or terminate your access to
                  the platform at any time for any reason, including violation
                  of these terms.
                </p>
              </li>
              <li>
                <h2 className="section-title font-bold text-lg mt-4">
                  Changes to the Terms
                </h2>
                <p>
                  We may update these terms from time to time. Any changes will
                  be posted on this page, and continued use of the platform
                  constitutes your acceptance of the new terms.
                </p>
              </li>
              <li>
                <h2 className="section-title font-bold text-lg mt-4">
                  Governing Law
                </h2>
                <p>
                  These terms are governed by the laws of Indonesia, without
                  regard to its conflict of law principles.
                </p>
              </li>
              <li>
                <h2 className="section-title font-bold text-lg mt-4">
                  Contact Us
                </h2>
                <p>
                  If you have any questions about these terms, please contact us
                  at{" "}
                  <a href="mailto:support@agensia.com">support@agensia.com</a>.
                </p>
              </li>
            </ol>
            <p>Last Updated: 2024-08-01</p>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default InvitationAccept;
