import { useContext, useEffect, useState, type FC } from "react";
import { Modal, SelectPicker } from "rsuite";
import { Board, Column, Task } from "../model/board";
import { Project } from "../model/project";
import { errorToast } from "../utils/helper-ui";
import { getProject, getProjects } from "../repositories/project";
import { ProjectContext } from "../objects/project_object";
import { getBoard } from "../repositories/board";
import { updateTask } from "../repositories/task";

interface ModalMoveTaskProps {
  open: boolean;
  onClose: () => void;
  task?: Task | null;
  onTaskChange: (task: Task) => void;
}

const ModalMoveTask: FC<ModalMoveTaskProps> = ({
  open,
  onClose,
  task,
  onTaskChange,
}) => {
  const { project, setProject } = useContext(ProjectContext);
  const [projects, setProjects] = useState<Project[]>([]);
  const [mounted, setMounted] = useState(false);
  const [selectedProject, setSelectedProject] = useState<
    Project | null | undefined
  >(null);
  const [selectedBoard, setSelectedBoard] = useState<Board | null>(null);
  const [selectedColumn, setSelectedColumn] = useState<Column | null>(null);
  const [selectedProjectID, setSelectedProjectID] = useState("");
  const [selectedBoardID, setSelectedBoardID] = useState("");
  const [selectedColumnID, setSelectedColumnID] = useState("");

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) {
      return;
    }
    getAllProjects("");
  }, [mounted, project]);

  useEffect(() => {
    if (task && mounted) {
    }
  }, [task, mounted]);

  useEffect(() => {
    setSelectedProject(project);
    setSelectedProjectID(project?.id ?? "");
  }, [project]);
  const getAllProjects = async (search: string) => {
    try {
      const res = await getProjects({
        page: 1,
        limit: 5,
        search,
        project_id: project?.id,
      });
      const resJson = await res.json();
      setProjects(resJson.data);
    } catch (error) {
      errorToast(`${error}`);
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>Move Task {task?.title} </Modal.Header>
      <Modal.Body>
        <div className="space-y-4">
          <div>
            <p className=" font-[500]"> Project</p>
            <SelectPicker
              block
              data={projects}
              labelKey="title"
              valueKey="id"
              value={selectedProjectID}
              onChange={(val) => {
                setSelectedProjectID(val!);
                setSelectedProject(projects.find((p) => p.id == val)!);
              }}
              onSearch={getAllProjects}
              placeholder="Select Project"
            />
          </div>
          <div>
            <p className=" font-[500]"> Board</p>
            <SelectPicker
              block
              data={selectedProject?.boards ?? []}
              labelKey="title"
              valueKey="id"
              value={selectedBoardID}
              onChange={(val) => {
                setSelectedBoardID(val!);
                getBoard(val!)
                  .then((res) => res.json())
                  .then((res) => {
                    setSelectedBoard(res.data);
                  });
              }}
              onSearch={getAllProjects}
              placeholder="Select Board"
            />
          </div>
          <div>
            <p className=" font-[500]"> Group</p>
            <SelectPicker
              block
              data={selectedBoard?.columns ?? []}
              labelKey="title"
              valueKey="id"
              value={selectedColumnID}
              onChange={(val) => {
                setSelectedColumnID(val!);
                setSelectedColumn(
                  selectedBoard?.columns.find((b) => b.id == val)!
                );
              }}
              onSearch={getAllProjects}
              placeholder="Select Group"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="px-6 py-2 rounded-lg bg-blue-500 text-white"
          onClick={() => {
            onTaskChange({
              ...task!,
              column_id: selectedColumnID,
              position: 1000,
            });
          }}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalMoveTask;
