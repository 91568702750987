import { useEffect, useState, type FC } from "react";
import { Form, FormField, FormFieldType } from "../model/form";
import { Field, FormResponse } from "../model/form_response";
import { BsCheckCircle, BsCheckCircleFill } from "react-icons/bs";
import moment from "moment";
import { Modal, Tag, Pagination as RSPagination } from "rsuite";
import { HiPaperClip } from "react-icons/hi2";
import { getFormResponse } from "../repositories/form";
import { Pagination } from "../objects/pagination";
import { errorToast } from "../utils/helper-ui";

interface FormResponsePageProps {
  form: Form;
}

const FormResponsePage: FC<FormResponsePageProps> = ({ form }) => {
  const [fields, setFields] = useState<FormField[]>([]);
  const [responses, setResponses] = useState<FormResponse[]>([]);
  const [modalPreview, setModalPreview] = useState(false);
  const [preview, setPreview] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState<Pagination>();

  useEffect(() => {
    let _fields = [];
    for (const s of form.sections) {
      for (const f of s.fields) {
        _fields.push(f);
      }
    }

    setFields(_fields);
  }, [form]);

  useEffect(() => {
    getResponses();
  }, [form, page, limit, search]);

  const getResponses = async () => {
    try {
      let resp = await getFormResponse(form!.id, { page, limit, search });
      let respJson = await resp.json();
      setResponses(respJson.data);
      setPagination(respJson.pagination);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
    }
  };

  return (
    <div className="px-4">
      <table className="table table-auto  w-full">
        <thead>
          <tr>
            {fields.map((f) => (
              <th
                className="px-4 py-2 text-left border-r border-t last:border-r-0"
                key={f.id}
              >
                {f.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {responses.map((r) => {
            let _fields: Field[] = [];
            for (const s of r.sections) {
              for (const f of s.fields) {
                _fields.push(f);
              }
            }
            return (
              <tr key={r.id}>
                {_fields.map((f, i) => {
                  let value;
                  value = f.value;

                  if (f.type == FormFieldType.FileUpload) {
                    const ext = f.url.split(".").pop();
                    let is_image = [
                      "jpg",
                      "png",
                      "jpeg",
                      "gif",
                      "webp",
                    ].includes(ext!.toLowerCase());
                    value = (
                      <div
                        className=" aspect-square rounded-lg bg-gray-100 justify-center items-center flex flex-col hover:bg-gray-200 w-16 cursor-pointer p-2"
                        key={f.id}
                        onClick={() => {
                          if (is_image) {
                            setPreview(f.url);
                            setModalPreview(true);
                          } else {
                            window.open(f.url, "_blank");
                          }
                        }}
                      >
                        {is_image ? (
                          <img src={f.url} />
                        ) : (
                          <HiPaperClip size={24} />
                        )}
                      </div>
                    );
                  }
                  if (
                    f.type == FormFieldType.RadioButton ||
                    f.type == FormFieldType.Dropdown
                  ) {
                    value = (fields[i]?.options ?? []).find(
                      (o) => o.value == f.value
                    )?.label;
                  }
                  if (f.type == FormFieldType.ToggleSwitch) {
                    value = f.value && (
                      <BsCheckCircleFill className="text-green-400" />
                    );
                  }
                  if (f.type == FormFieldType.DatePicker) {
                    value = (
                      <small>
                        {moment(f.value).format("DD MMM YYYY, HH:mm:ss")}
                      </small>
                    );
                  }
                  if (f.type == FormFieldType.DateRangePicker) {
                    value = (
                      <small>
                        {moment(f.value[0]).format("DD MMM YYYY, HH:mm:ss")}
                        {" ~ "}
                        {moment(f.value[1]).format("DD MMM YYYY, HH:mm:ss")}
                      </small>
                    );
                  }
                  if (f.type == FormFieldType.Checkbox) {
                    value = (
                      <ul className=" list-disc ml-2">
                        {(f.value ?? []).map((e: any) => (
                          <li key={e}>
                            {
                              (fields[i]?.options ?? []).find(
                                (o) => o.value == e
                              )?.label
                            }
                          </li>
                        ))}
                      </ul>
                    );
                  }
                  return (
                    <td
                      className="px-4 py-2 text-left border-r border-t last:border-r-0"
                      key={f.id}
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {(pagination?.total_pages ?? 0) > 1 && (
        <div className="p-4">
          <RSPagination
            prev
            last
            next
            first
            size="xs"
            total={pagination?.total_records ?? 100}
            limit={limit ?? 10}
            activePage={page}
            onChangePage={(val) => setPage(val)}
            maxButtons={5}
          />
        </div>
      )}
      {modalPreview && (
        <Modal
          className="custom-modal"
          size={"lg"}
          open={modalPreview}
          onClose={() => setModalPreview(false)}
        >
          <Modal.Body>
            <img src={preview} className="w-full rounded" />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};
export default FormResponsePage;
