import { createContext } from "react";

type BgColorContextType = {
  color: string;
  setColor: (string: string) => void;
};

export const BgColorContext = createContext<BgColorContextType>({
  color: "#ede9fe",
  setColor: (val) => false,
});
