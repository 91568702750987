import { customFetch } from "../utils/http";

export const uploadFile = async (file: File, onProgress: (progress: number) => void) => {
  const formData = new FormData();
  formData.append("file", file);

  const options = {
    onUploadProgress: (progressEvent: ProgressEvent) => {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onProgress(progress);
    },
  };

  return await customFetch("file/upload", {
    method: "POST",
    body: formData,
    ...options,
  }, true);
};
