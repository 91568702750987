import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { Avatar, Button, Modal, SelectPicker } from "rsuite";
import { Team } from "../model/team";
import { getTeam, getTeams } from "../repositories/team";
import { initials } from "../utils/helper";
import { errorToast } from "../utils/helper-ui";

interface ModalTeamProps {
  open: boolean;
  onClose: () => void;
  onSave: (val: Team) => void;
}

const ModalTeam: FC<ModalTeamProps> = ({ open, onClose, onSave }) => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeamID, setSelectedTeamID] = useState("");
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getTeams({ page: 1, limit: 5 })
      .then((res) => res.json())
      .then((resp) => {
        setTeams(resp.data);
      })
      .catch((error) => {
        errorToast(error);
      });
  }, [mounted]);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Add Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col space-y-4">
          <div>
            <SelectPicker
              data={teams}
              value={selectedTeamID}
              placeholder="Search team"
              block
              valueKey="id"
              labelKey="title"
              onChange={(val) => {
                setSelectedTeamID(val!);
                getTeam(val!)
                  .then((res) => res.json())
                  .then((resp) => {
                    setSelectedTeam(resp.data);
                  });
              }}
              renderMenuItem={(label, item) => (
                <div className="flex flex-row items-center gap-2">
                  <div
                    className="w-3 h-3 rounded-full"
                    style={{ backgroundColor: item.color }}
                  ></div>
                  {label}
                </div>
              )}
              onSearch={(val) => {
                if (val) {
                  getTeams({ page: 1, limit: 5, search: val })
                    .then((res) => res.json())
                    .then((resp) => {
                      setTeams(resp.data);
                    })
                    .catch((error) => {
                      errorToast(error);
                    });
                }
              }}
            />
          </div>
          {selectedTeam && (
            <div className="px-4">
              {selectedTeam.members.map((e) => (
                <div
                  className="flex flex-row items-center gap-2 mb-2"
                  key={e.id}
                >
                  <Avatar
                    alt={initials(e?.user.full_name)}
                    src={e?.user.picture_url}
                    color="violet"
                    size="xs"
                    circle
                  />
                  {e?.user.full_name}
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          appearance="primary"
          className="w-32"
          onClick={() => onSave(selectedTeam!)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalTeam;
