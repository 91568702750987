import type { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import OnBoarding from "../pages/OnBoarding";
import NotFound from "../pages/NotFound";
import ProjectDetail from "../pages/ProjectDetail";
import BoardDetail from "../pages/BoardDetail";
import TeamPage from "../pages/TeamPage";
import MemberPage from "../pages/MemberPage";
import DocDetail from "../pages/DocDetail";
import DropBoxDetail from "../pages/DropBoxDetail";
import ClientPage from "../pages/ClientPage";
import CompanyDetail from "../pages/CompanyDetail";
import InboxPage from "../pages/InboxPage";
import MyWorkPage from "../pages/MyWorkPage";
import ProjectPage from "../components/ProjectPage";
import ProfilePage from "../components/ProfilePage";
import FormDetail from "../pages/FormDetail";
import ChannelPage from "../pages/ChannelPage";

interface PrivateRoutesProps {}

const PrivateRoutes: FC<PrivateRoutesProps> = ({}) => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/my-work" element={<MyWorkPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/inbox" element={<InboxPage />} />
      <Route path="/inbox/:inboxType" element={<InboxPage />} />
      <Route path="/channel" element={<ChannelPage />} />
      <Route path="/channel/:channelId" element={<ChannelPage />} />
      <Route path="/inbox/:inboxType/:inboxId" element={<InboxPage />} />
      <Route path="/company/:companyId" element={<CompanyDetail />} />
      <Route path="/company/:companyId/team" element={<TeamPage />} />
      <Route path="/company/:companyId/member" element={<MemberPage />} />
      <Route path="/company/:companyId/client" element={<ClientPage />} />
      <Route path="/onboarding" element={<OnBoarding />} />
      <Route path="/projects" element={<ProjectPage />} />
      <Route path="/project/:projectId" element={<ProjectDetail />} />
      <Route path="/project/:projectId/doc/:docId" element={<DocDetail />} />
      <Route path="/project/:projectId/drop_box/:dropBoxId" element={<DropBoxDetail />} />
      <Route path="/project/:projectId/form/:formId" element={<FormDetail />} />
      <Route
        path="/project/:projectId/board/:boardId"
        element={<BoardDetail />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default PrivateRoutes;
