import { Member, MemberReq } from "../model/member";
import { customFetch } from "../utils/http";
import { PaginationReq } from "../objects/pagination";

export const createMember = async (member: MemberReq) => {
    return await customFetch(`member`, {
        method: "POST",
        body: JSON.stringify(member),
    });
    
};

export const getMembers = async (pagination: PaginationReq) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }
    return await customFetch(`member?${new URLSearchParams(params)}`);
    
};
export const getMember = async (id: string) => {
    return await customFetch(`member/${id}`);
    
};

export const updateMember = async (id: string, member: MemberReq) => {
    return await customFetch(`member/${id}`, {
        method: "PUT",
        body: JSON.stringify(member),
    });
    
};
export const memberAddTeam = async (id: string, team_ids: string) => {
    return await customFetch(`member/${id}/addTeam`, {
        method: "PUT",
        body: JSON.stringify({team_ids}),
    });
    
};

export const deleteMember = async (id: string): Promise<void> => {
    await customFetch(`member/${id}`, {
        method: "DELETE",
    });
};
