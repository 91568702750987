import moment from "moment";
import { ReactNode, useContext, useState, type FC } from "react";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import {
  Avatar,
  AvatarGroup,
  Checkbox,
  DatePicker,
  SelectPicker,
} from "rsuite";
import { Column, Task, TaskReq } from "../model/board";
import { ActivityLogReq, Project } from "../model/project";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { priorityList } from "../objects/constants";
import { createTask } from "../repositories/task";
import {
  getPriorityColor,
  getTextColorBasedOnBackground,
  initials,
} from "../utils/helper";
import AddTask from "./AddTask";
import ModalColor from "./ModalColor";
import StatusSlider from "./StatusSlider";
import { StrictModeDroppable } from "./StrictModeDroppable";
import { Member } from "../model/member";
import { PiEyeBold } from "react-icons/pi";
import { addActivityLogProject } from "../repositories/project";
import { CompanyContext } from "../objects/company_context";
import { useParams } from "react-router-dom";

interface ListBoardProps {
  project: Project | null;
  data: Column[];
  onDragEnd: (result: DropResult) => void;
  onColumnsChange: (cols: Column[]) => void;
  onColumnChange: (column: Column) => void;
  onTaskChange: (task: Task) => void;
  ws?: WebSocket | null;
  profile?: User;
  onSelectTask: (task: Task) => void;
  onClickContributor: (task: Task, val: Member[]) => void;
  onUpdateActivity: (act: ActivityLogReq) => void
}

const ListBoard: FC<ListBoardProps> = ({
  data,
  onDragEnd,
  onColumnsChange,
  onColumnChange,
  onTaskChange,
  onSelectTask,
  onClickContributor,
  onUpdateActivity,
  ws,
  profile,
}) => {
  const { color, setColor } = useContext(BgColorContext);
  const { company, setCompany } = useContext(CompanyContext);
  const [modalColor, setModalColor] = useState(false);
  const { boardId, projectId } = useParams();
  const [selectedColumn, setSelectedColumn] = useState<Column | null>(null);
  const [boxWidth, setBoxWidth] = useState([
    0, 400, 200, 200, 140, 140, 100, 100,
  ]);
  type Priority = "Lowest" | "Low" | "Medium" | "High" | "Highest";


  const resizable = (index: number, children: ReactNode) => {
    return (
      <ResizableBox
        width={boxWidth[index]}
        axis="x"
        resizeHandles={["e"]}
        onResize={(e, data) => {
          //   console.log(data.size);
          boxWidth[index] = data.size.width;
          setBoxWidth([...boxWidth]);
        }}
      >
        {children}
      </ResizableBox>
    );
  };
  const renderHeader = (column: Column) => (
    <div className="flex flex-row items-center text-sm">
      <div
        className="border-t border-b border-r border-l-4 border-l-orange-400 rounded-tl-[5px] h-10 p-1 flex flex-col items-center justify-center"
        style={{ borderLeftColor: column.color }}
      >
        <Checkbox />
      </div>
      {resizable(1, <div className="cell-resizable">Task</div>)}
      {resizable(2, <div className="cell-resizable">Creator</div>)}
      {resizable(3, <div className="cell-resizable">Assigned To</div>)}
      {resizable(4, <div className="cell-resizable">Date</div>)}
      {resizable(5, <div className="cell-resizable">Due</div>)}
      {resizable(6, <div className="cell-resizable">Priority</div>)}
      {resizable(7, <div className="cell-resizable">Status</div>)}
      <div
        className="border-t border-b  border-r-4 border-r-orange-400 rounded-tr-[5px]  h-10 py-1 px-2  flex items-center truncate text-ellipsis font-[500] flex-1"
        style={{ borderRightColor: column.color }}
      ></div>
    </div>
  );
  // const renderBody = (column: Column, task: Task, index: number) => (
  //   <Draggable draggableId={task.id} index={index} key={task.id}>
  //     {(provided, snapshot) => (
  //       <div
  //         className="flex flex-row items-center bg-white"
  //         ref={provided.innerRef}
  //         {...provided.draggableProps}
  //         {...provided.dragHandleProps}
  //       >
  //         <div
  //           className="border-b border-r border-l-4 border-l-orange-400  h-10 p-1 flex flex-col items-center justify-center"
  //           style={{ borderLeftColor: column.color }}
  //         >
  //           <Checkbox />
  //         </div>
  //         {resizable(
  //           1,
  //           <div className="cell-body" style={{ fontWeight: "normal" }}>
  //             <input
  //               onBlur={() => {
  //                 onTaskChange(task);
  //               }}
  //               type="text"
  //               value={task.title}
  //               className="focus:ring-1 focus:outline-none focus:ring-primary-300 w-[75%]"
  //               onChange={(val) => {
  //                 let newColumns = [
  //                   ...data.map((e) => {
  //                     if (e.id == column.id) {
  //                       e.tasks = [
  //                         ...e.tasks.map((t) => {
  //                           if (t.id == task.id) {
  //                             t.title = val.target.value;
  //                           }
  //                           return t;
  //                         }),
  //                       ];
  //                     }
  //                     return e;
  //                   }),
  //                 ];
  //                 onColumnsChange(newColumns);
  //               }}
  //             />
  //             <div
  //               className="flex-1 h-10 group/item"
  //               onClick={() => onSelectTask(task)}
  //             >
  //               <div className="group/edit invisible h-full justify-center flex text-center text-xs items-center group-hover/item:visible text-gray-600 gap-2">
  //                 <PiEyeBold className="w-4 h-4" />
  //                 Open
  //               </div>
  //             </div>
  //           </div>
  //         )}
  //         {resizable(
  //           2,
  //           <div className="cell-body justify-center gap-4">
  //             <Avatar
  //               size="xs"
  //               bordered
  //               circle
  //               key={task.reported.user.full_name}
  //               src={task.reported.user.picture_url}
  //               alt={initials(task.reported.user.full_name)}
  //               color="violet"
  //             />
  //             <p>{task.reported.user.full_name}</p>
  //           </div>
  //         )}
  //         {resizable(
  //           3,
  //           <div
  //             className="cell-body justify-center"
  //             onClick={() => onClickContributor(task, task.contributors)}
  //           >
  //             {task.contributors.length == 0 && (
  //               <div className="text-xs">Choose Member First</div>
  //             )}
  //             <AvatarGroup stack>
  //               {task.contributors
  //                 .filter((member, i) => i < 4)
  //                 .map((member) => (
  //                   <Avatar
  //                     size="xs"
  //                     bordered
  //                     circle
  //                     key={member.user.full_name}
  //                     src={member.user.picture_url}
  //                     alt={initials(member.user.full_name)}
  //                     color="violet"
  //                   />
  //                 ))}
  //               {task.contributors.length - 4 > 0 && (
  //                 <Avatar
  //                   size="xs"
  //                   bordered
  //                   circle
  //                   style={{ background: "#111" }}
  //                 >
  //                   +{task.contributors.length - 4}
  //                 </Avatar>
  //               )}
  //             </AvatarGroup>
  //           </div>
  //         )}
  //         {resizable(
  //           4,
  //           <div className="cell-body justify-start">
  //             <DatePicker
  //               format="dd MMM yyyy"
  //               value={task?.date ? moment(task?.date).toDate() : undefined}
  //               classPrefix="custom-date"
  //               onChange={(val) => {
  //                 onTaskChange({
  //                   ...task,
  //                   date: moment(val).startOf("day").toISOString(),
  //                 });
  //               }}
  //             />
  //             {/* {task.due_date && moment(task.due_date).format("DD MMM YYYY")} */}
  //           </div>
  //         )}
  //         {resizable(
  //           5,
  //           <div className="cell-body justify-start">
  //             <DatePicker
  //               shouldDisableDate={(val) => {
  //                 if (moment(val).isBefore(task.date)) {
  //                   return true;
  //                 }
  //                 return false;
  //               }}
  //               format="dd MMM yyyy"
  //               value={
  //                 task?.due_date ? moment(task?.due_date).toDate() : undefined
  //               }
  //               classPrefix="custom-date"
  //               onChange={(val) => {
  //                 onTaskChange({
  //                   ...task,
  //                   due_date: moment(val).startOf("day").toISOString(),
  //                 });
  //               }}
  //             />
  //             {/* {task.due_date && moment(task.due_date).format("DD MMM YYYY")} */}
  //           </div>
  //         )}
  //         {resizable(
  //           6,
  //           <div
  //             className="cell-body justify-center"
  //             style={{
  //               backgroundColor: getPriorityColor(task.priority),
  //               color: getTextColorBasedOnBackground(
  //                 getPriorityColor(task.priority)
  //               ),
  //             }}
  //           >
  //             <SelectPicker
  //               cleanable={false}
  //               classPrefix="custom-select"
  //               value={task.priority}
  //               data={priorityList.map((e) => ({ value: e, label: e }))}
  //               onChange={(val) => {
  //                 onTaskChange({
  //                   ...task,
  //                   priority: val! as Priority,
  //                 });
  //               }}
  //               renderValue={(val, item) => (
  //                 <div className="flex flex-row flex-1">
  //                   <div
  //                     style={{
  //                       backgroundColor: getPriorityColor(val),
  //                       color: getTextColorBasedOnBackground(
  //                         getPriorityColor(val)
  //                       ),
  //                     }}
  //                   ></div>
  //                   {val}
  //                 </div>
  //               )}
  //               renderMenuItem={(label, item) => (
  //                 <div className="flex flex-row flex-1">
  //                   <div
  //                     style={{
  //                       backgroundColor: getPriorityColor(item.value),
  //                       color: getTextColorBasedOnBackground(
  //                         getPriorityColor(item.value)
  //                       ),
  //                       width: 5,
  //                       height: 20,
  //                       marginRight: 5,
  //                     }}
  //                   ></div>
  //                   {label}
  //                 </div>
  //               )}
  //             />
  //           </div>
  //         )}
  //         {resizable(
  //           7,
  //           <div className="cell-body justify-center" style={{ padding: 0 }}>
  //             <StatusSlider
  //               percentage={task.progress}
  //               onChange={(val) => {
  //                 onTaskChange({
  //                   ...task,
  //                   progress: val,
  //                 });
  //               }}
  //             />
  //           </div>
  //         )}
  //         <div
  //           className=" border-b  border-r-4 border-r-orange-400   h-10 py-1 px-2 justify-end  flex items-center truncate text-ellipsis font-[500] flex-1"
  //           style={{ borderRightColor: column.color }}
  //         ></div>
  //       </div>
  //     )}
  //   </Draggable>
  // );
  const renderFooter = (column: Column) => (
    <div className="flex flex-row items-center text-sm">
      <div
        className=" border-b  border-l-4 border-l-orange-400 rounded-bl-[5px] h-10 p-1 flex flex-col items-center justify-center"
        style={{ borderLeftColor: column.color }}
      >
        <div className="w-[37px]"></div>
      </div>
      {resizable(
        1,
        <div
          className="cell-resizable-footer cell-resizable-bottom text-xs"
          style={{ fontWeight: "normal" }}
        >
          <AddTask
            onBlur={(val) => {
              if (val == "") return;
              let req: TaskReq = {
                title: val,
                priority: "High",
                type: "Other",
                description: "{}",
                date: moment().toISOString(),
                progress: 0,
                dependencies: [],
                contributors: [],
                column_id: column.id,
                position: column.tasks.length - 1,
              };
              createTask(req).then((res) => {
                if (ws) {
                  ws.send(
                    JSON.stringify({
                      type: "UPDATE",
                      action: "ADD_TASK",
                      targetId: column.board_id,
                      targetType: "BOARD",
                      userId: profile?.id,
                    })
                  );
                }
              });
            }}
          />
        </div>
      )}
      {resizable(
        2,
        <div className="cell-resizable-footer cell-resizable-bottom"></div>
      )}
      {resizable(
        3,
        <div className="cell-resizable-footer cell-resizable-bottom"></div>
      )}

      {resizable(
        4,
        <div className="cell-resizable-footer cell-resizable-bottom"></div>
      )}
      {resizable(
        5,
        <div className="cell-resizable-footer cell-resizable-bottom"></div>
      )}
      {resizable(
        6,
        <div className="cell-resizable-footer cell-resizable-bottom"></div>
      )}
      {resizable(
        7,
        <div className="cell-resizable-footer cell-resizable-bottom"></div>
      )}
      <div
        className=" border-b  border-r-4 border-r-orange-400 rounded-br-[5px]  h-10 py-1 px-2  flex items-center truncate text-ellipsis font-[500] flex-1"
        style={{ borderRightColor: column.color }}
      ></div>
    </div>
  );
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="p-4 overflow-y-auto">
        {data.map((column: Column) => (
          <div key={column.id} className="mb-8">
            <div className="mb-2">
              <div className="flex flex-row gap-2 items-center">
                <div
                  className=" aspect-square w-4 border-[0.5px] cursor-pointer rounded-full"
                  style={{
                    backgroundColor: column.color ?? color,
                    borderColor: getTextColorBasedOnBackground(
                      column.color ?? color
                    ),
                  }}
                  onClick={() => {
                    setSelectedColumn(column);
                    setModalColor(true);
                  }}
                />
                <h1
                  className="font-[500] text-xl text-orange-500"
                  style={{ color: column.color }}
                >
                  <input
                    type="text"
                    value={column.title}
                    className="focus:ring-1 focus:outline-none focus:ring-gray-200 "
                    onChange={(val) => {
                      let newColumns = [
                        ...data.map((c) => {
                          if (column.id == c.id) {
                            c.title = val.target.value;
                          }
                          return c;
                        }),
                      ];
                      onColumnsChange(newColumns);
                    }}
                    onBlur={() => {
                      onColumnChange(column);
                    }}
                  />
                </h1>
              </div>
            </div>
            <table className="table-auto w-full">
              <thead className="">
                <tr className="">
                  <th
                    className="border-r border-t text-left border-l-4 border-l-orange-400  h-10 "
                    style={{
                      width: 32,
                      borderLeftColor: column.color,
                      borderTopLeftRadius: 10,
                    }}
                  >
                    <Checkbox />
                  </th>
                  <th
                    className="border-r border-t text-left px-4 py-2"
                    style={{
                      width: boxWidth[1],
                    }}
                  >
                    Task
                  </th>
                  <th
                    className="border-r border-t text-left px-4 py-2"
                    style={{
                      width: boxWidth[2],
                    }}
                  >
                    Creator
                  </th>
                  <th
                    className="border-r border-t text-left px-4 py-2"
                    style={{
                      width: boxWidth[3],
                    }}
                  >
                    Assigned To
                  </th>
                  <th
                    className="border-r border-t text-left px-4 py-2"
                    style={{
                      width: boxWidth[4],
                    }}
                  >
                    Date
                  </th>
                  <th
                    className="border-r border-t text-left px-4 py-2"
                    style={{
                      width: boxWidth[5],
                    }}
                  >
                    Due
                  </th>
                  <th
                    className="border-r border-t text-left px-4 py-2"
                    style={{
                      width: boxWidth[6],
                    }}
                  >
                    Priority
                  </th>
                  <th
                    className="border-t border-r-4 text-left px-4 py-2"
                    style={{
                      width: 32,
                      borderRightColor: column.color,
                      borderTopRightRadius: 10,
                    }}
                  >
                    Status
                  </th>
                </tr>
              </thead>

              <StrictModeDroppable droppableId={column.id} type="ITEM">
                {(provided, snapshot) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {column.tasks.map((task, index) => (
                      <Draggable
                        draggableId={task.id}
                        index={index}
                        key={task.id}
                      >
                        {(provided, snapshot) => (
                          <tr
                            className={`bg-white w-full ${
                              snapshot.isClone && "!fixed w-[1000px]"
                            }`}
                            key={task.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <td
                              className="border-r border-t text-left border-l-4 border-l-orange-400  h-10 "
                              style={{
                                width: 32,
                                borderLeftColor: column.color,
                                borderTopLeftRadius: 10,
                              }}
                            >
                              <Checkbox />
                            </td>
                            <td
                              className="border-r border-t text-left "
                              style={{
                                width: boxWidth[1],
                              }}
                            >
                              <div
                                className="cell-body"
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                <input
                                  onBlur={() => {
                                    onTaskChange(task);
                                  }}
                                  type="text"
                                  value={task.title}
                                  className="focus:ring-1 focus:outline-none focus:ring-primary-300 w-[75%]"
                                  onChange={(val) => {
                                    let newColumns = [
                                      ...data.map((e) => {
                                        if (e.id == column.id) {
                                          e.tasks = [
                                            ...e.tasks.map((t) => {
                                              if (t.id == t.id) {
                                                t.title = val.target.value;
                                              }
                                              return t;
                                            }),
                                          ];
                                        }
                                        return e;
                                      }),
                                    ];
                                    onColumnsChange(newColumns);
                                  }}
                                />
                                <div
                                  className="flex-1 h-10 group/item"
                                  onClick={() => onSelectTask(task)}
                                >
                                  <div className="group/edit invisible h-full justify-center flex text-center text-xs items-center group-hover/item:visible text-gray-600 gap-2">
                                    <PiEyeBold className="w-4 h-4" />
                                    Open
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td
                              className="border-r border-t text-left "
                              style={{
                                width: boxWidth[2],
                              }}
                            >
                              <div className="cell-body gap-2">
                                <Avatar
                                  size="xs"
                                  bordered
                                  circle
                                  key={task.reported.user.full_name}
                                  src={task.reported.user.picture_url}
                                  alt={initials(task.reported.user.full_name)}
                                  color="violet"
                                />
                                <p>{task.reported.user.full_name}</p>
                              </div>
                            </td>
                            <td
                              className="border-r border-t text-left "
                              style={{
                                width: boxWidth[3],
                              }}
                            >
                              <div
                                className="cell-body justify-center"
                                onClick={() =>
                                  onClickContributor(task, task.contributors)
                                }
                              >
                                {task.contributors.length == 0 && (
                                  <div className="text-xs">
                                    Choose Member First
                                  </div>
                                )}
                                <AvatarGroup stack>
                                  {task.contributors
                                    .filter((member, i) => i < 4)
                                    .map((member) => (
                                      <Avatar
                                        size="xs"
                                        bordered
                                        circle
                                        key={member.user.full_name}
                                        src={member.user.picture_url}
                                        alt={initials(member.user.full_name)}
                                        color="violet"
                                      />
                                    ))}
                                  {task.contributors.length - 4 > 0 && (
                                    <Avatar
                                      size="xs"
                                      bordered
                                      circle
                                      style={{ background: "#111" }}
                                    >
                                      +{task.contributors.length - 4}
                                    </Avatar>
                                  )}
                                </AvatarGroup>
                              </div>
                            </td>
                            <td
                              className="border-r border-t text-left "
                              style={{
                                width: boxWidth[4],
                              }}
                            >
                              <div className="cell-body justify-start">
                                <DatePicker
                                  format="dd MMM yyyy"
                                  value={
                                    task?.date
                                      ? moment(task?.date).toDate()
                                      : undefined
                                  }
                                  classPrefix="custom-date"
                                  onChange={(val) => {
                                    onTaskChange({
                                      ...task,
                                      date: moment(val)
                                        .startOf("day")
                                        .toISOString(),
                                    });
                                  }}
                                />
                                {/* {task.due_date && moment(task.due_date).format("DD MMM YYYY")} */}
                              </div>
                            </td>
                            <td
                              className="border-r border-t text-left "
                              style={{
                                width: boxWidth[5],
                              }}
                            >
                              <div className="cell-body justify-start">
                                <DatePicker
                                  shouldDisableDate={(val) => {
                                    if (moment(val).isBefore(task.date)) {
                                      return true;
                                    }
                                    return false;
                                  }}
                                  format="dd MMM yyyy"
                                  value={
                                    task?.due_date
                                      ? moment(task?.due_date).toDate()
                                      : undefined
                                  }
                                  classPrefix="custom-date"
                                  onChange={(val) => {
                                    onTaskChange({
                                      ...task,
                                      due_date: moment(val)
                                        .startOf("day")
                                        .toISOString(),
                                    });
                                  }}
                                />
                                {/* {task.due_date && moment(task.due_date).format("DD MMM YYYY")} */}
                              </div>
                            </td>
                            <td
                              className="border-r border-t text-left "
                              style={{
                                width: boxWidth[6],
                              }}
                            >
                              <div
                                className="cell-body justify-center"
                                style={{
                                  backgroundColor: getPriorityColor(
                                    task.priority
                                  ),
                                  color: getTextColorBasedOnBackground(
                                    getPriorityColor(task.priority)
                                  ),
                                }}
                              >
                                <SelectPicker
                                  cleanable={false}
                                  classPrefix="custom-select"
                                  value={task.priority}
                                  data={priorityList.map((e) => ({
                                    value: e,
                                    label: e,
                                  }))}
                                  onChange={(val) => {
                                    onUpdateActivity({
                                      company_id: company!.id,
                                      project_id: projectId!,
                                      board_id: boardId!,
                                      task_id: task!.id,
                                      activity_type: "UPDATE_PRIORITY",
                                      context_type: "TASK",
                                      pre_condition: JSON.stringify({
                                        priority: task?.priority ?? "Lowest",
                                      }),
                                      data: JSON.stringify({
                                        priority: val! as Priority,
                                      }),
                                    });
                                   
                                    onTaskChange({
                                      ...task,
                                      priority: val! as Priority,
                                    });
                                  }}
                                  renderValue={(val, item) => (
                                    <div className="flex flex-row flex-1">
                                      <div
                                        style={{
                                          backgroundColor:
                                            getPriorityColor(val),
                                          color: getTextColorBasedOnBackground(
                                            getPriorityColor(val)
                                          ),
                                        }}
                                      ></div>
                                      {val}
                                    </div>
                                  )}
                                  renderMenuItem={(label, item) => (
                                    <div className="flex flex-row flex-1">
                                      <div
                                        style={{
                                          backgroundColor: getPriorityColor(
                                            item.value
                                          ),
                                          color: getTextColorBasedOnBackground(
                                            getPriorityColor(item.value)
                                          ),
                                          width: 5,
                                          height: 20,
                                          marginRight: 5,
                                        }}
                                      ></div>
                                      {label}
                                    </div>
                                  )}
                                />
                              </div>
                            </td>
                            <td
                              className="border-t text-left  border-r-4"
                              style={{
                                width: 32,
                                borderRightColor: column.color,
                                borderTopRightRadius: 10,
                              }}
                            >
                              <div
                                className="cell-body justify-center"
                                style={{ padding: 0 }}
                              >
                                <StatusSlider
                                  companyId={company!.id}
                                  boardId={boardId!}
                                  projectId={projectId!}
                                  taskId={task.id}
                                  profile={profile!}
                                  ws={ws}
                                  percentage={task.progress}
                                  onChange={(val) => {
                                    onTaskChange({
                                      ...task,
                                      progress: val,
                                    });
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {(snapshot.isUsingPlaceholder ||
                      column.tasks.length == 0) && (
                      <tr className="h-4 bg-white w-full">
                        <td
                          className="border-l-4 border-r-4 border-t"
                          colSpan={8}
                          style={{
                            borderLeftColor: column.color,
                            borderRightColor: column.color,
                          }}
                        >
                          {provided.placeholder}
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </StrictModeDroppable>
              <tfoot className="">
                <tr className="">
                  <th
                    className="border-r border-t border-b text-left border-l-4 border-l-orange-400  h-10 "
                    style={{
                      width: 32,
                      borderLeftColor: column.color,
                      borderTopLeftRadius: 10,
                    }}
                  >
                    <Checkbox />
                  </th>
                  <th className="border-r border-t border-b text-left px-4 py-2"></th>
                  <th className="border-r border-t border-b text-left px-4 py-2"></th>
                  <th className="border-r border-t border-b text-left px-4 py-2"></th>
                  <th className="border-r border-t border-b text-left px-4 py-2"></th>
                  <th className="border-r border-t border-b text-left px-4 py-2"></th>
                  <th className="border-r border-t border-b text-left px-4 py-2"></th>
                  <th
                    className="border-t border-b border-r-4 text-left px-4 py-2"
                    style={{
                      width: 32,
                      borderRightColor: column.color,
                      borderTopRightRadius: 10,
                    }}
                  ></th>
                </tr>
              </tfoot>
            </table>

            {/* {renderHeader(e)}
            <StrictModeDroppable droppableId={e.id}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {e.tasks.map((t, i) => renderBody(e, t, i))}
                  {provided.placeholder}
                </div>
              )}
              
            </StrictModeDroppable>

            {renderFooter(e)} */}
          </div>
        ))}
      </div>
      <ModalColor
        open={modalColor}
        onClose={() => setModalColor(false)}
        color={color}
        onChange={(val, e) => {
          if (selectedColumn) {
            onColumnChange({
              ...selectedColumn,
              color: val.hex,
            });
            setModalColor(false);
            setSelectedColumn(null);
          }
        }}
      />
    </DragDropContext>
  );
};
export default ListBoard;
