import { Board, Column } from "./board"
import { FormResponse } from "./form_response"
import { Project } from "./project"

export interface Form {
    id: string
    title: string
    code: string
    description: string
    picture_url: string
    cover_url: string
    picture: string
    cover: string
    storage_provider: string
    sections: FormSection[]
    submit_url: string
    method: string
    headers: string
    project_id: string
    project: Project
    board_id: string
    board: Board
    column_id: string
    column: Column
    is_public: boolean
    status: 'DRAFT' | 'PUBLISH' | 'DISABLED'
    data: string
    responses: FormResponse[],
}


export interface FormReq {
    title?: string
    description?: string
    picture?: string
    cover?: string
    submit_url?: string
    method?: string
    headers?: string
    project_id?: string
    board_id?: string
    column_id?: string
    is_public: boolean
}

export const enum FormFieldType {
	TextField = "text",
	TextArea = "textarea",
	RadioButton = "radio",
	Checkbox = "checkbox",
	Dropdown = "dropdown",
	DatePicker = "date",
	DateRangePicker = "date_range",
	NumberField = "number",
	Currency = "currency",
	EmailField = "email",
	PasswordField = "password",
	FileUpload = "file",
	ToggleSwitch = "toggle",
}

export interface FormFieldOption {
    label: string
    value: string
}

export interface FormField {
    id: string
    label: string
    type: FormFieldType
    options: FormFieldOption[]
    required: boolean
    is_multi: boolean
    placeholder: string
    default_value: string
    help_text: string
    disabled: boolean
}

export interface FormSection {
    id: string
    section_title: string
    description: string
    fields: FormField[]
}
