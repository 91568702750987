import { useContext, useEffect, useState, type FC } from "react";
import { FaRegEnvelope } from "react-icons/fa6";
import { PiMagnifyingGlass, PiWarning } from "react-icons/pi";
import { Avatar, SelectPicker, Tag } from "rsuite";
import { Member } from "../model/member";
import { Role } from "../model/role";
import { Team } from "../model/team";
import { User } from "../model/user";
import { COMPANY_ID } from "../objects/constants";
import { LoadingContext } from "../objects/loading";
import { getRoles } from "../repositories/general";
import { createInvitation } from "../repositories/invitation";
import { searchUser } from "../repositories/search";
import { initials, isValidEmail } from "../utils/helper";
import { errorToast, successToast } from "../utils/helper-ui";
import { asyncLocalStorage } from "../utils/storage";

interface SearchPeopleProps {
  team?: Team | null;
  invitationType: "MEMBER" | "CLIENT";
  onSuccess?: () => void;
  externalOnly?: boolean;
  existingsMembers?: Member[];
  onChangeMembers?: (val: Member[]) => void;
  onSelectMember?: (val: Member) => void;
  onDeleteMember?: (val: Member) => void;
  disableTag?: boolean
}

const SearchPeople: FC<SearchPeopleProps> = ({
  team,
  invitationType,
  onSuccess,
  externalOnly = false,
  existingsMembers = [],
  onChangeMembers,
  onDeleteMember,
  onSelectMember,
  disableTag
  
}) => {
  const [search, setSearch] = useState("");
  const [searchRole, setSearchRole] = useState("");
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [email, setEmail] = useState<string | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [selectedMembers, setSelectedMembers] = useState<Member[]>(existingsMembers);
  useEffect(() => {
    asyncLocalStorage.getItem(COMPANY_ID).then((resp) => {
      setCompanyID(resp);
    });
    searchUser(search)
      .then((resp) => resp.json())
      .then((resp) => setSearchResults(resp.data));
  }, [search]);

  useEffect(() => {
    getRoles({ page: 1, limit: 5, search: searchRole })
      .then((resp) => resp.json())
      .then((resp) => setRoles(resp.data));
  }, []);

  useEffect(() => {
    // setSelectedMembers(existingsMembers.map((e) => e));
  }, [existingsMembers]);

  useEffect(() => {
    onChangeMembers?.(selectedMembers);
  }, [selectedMembers]);

  if (email) {
    return (
      <div className="flex flex-col ">
        <div className="flex-1 p-4 relative min-h-[300px] space-y-4">
          <h1>Invite {email} </h1>
          {invitationType == "MEMBER" ? (
            <>
              <p className="text-gray-600 text-sm">
                You can invite people to join your workspace by their email
                address. Please choose Role of the person you want to invite.
              </p>
              <SelectPicker
                data={roles}
                searchable
                block
                placeholder="Search Roles"
                valueKey="id"
                labelKey="name"
                value={selectedRoleId}
                renderMenuItem={(label, item) => {
                  return (
                    <div>
                      <h3 className="font-[500]">{label}</h3>
                      <p className="text-xs">{item.description}</p>
                    </div>
                  );
                }}
                onChange={(val) => {
                  setSelectedRoleId(val!);
                }}
              />
            </>
          ) : (
            <p>
              You can invite external clients to join your workspace by their
              email address. 
            </p>
          )}
        </div>
        <button
          onClick={async () => {
            setIsLoading(true);
            try {
              await createInvitation({
                email,
                role_id: invitationType == "CLIENT" ? null : selectedRoleId,
                project_id: null,
                member_id: null,
                team_id: team?.id ?? null,
                invitation_type: invitationType,
              });
              successToast(
                `The ${invitationType == "CLIENT" ? "client" : "member"} has been successfully invited, please inform them to check their email shortly.`
              );
              onSuccess?.();
            } catch (error) {
              console.log(error);
              errorToast(`${error}`);
            } finally {
              setIsLoading(false);
            }
          }}
          className="mt-4 w-full bg-blue-500 hover:bg-blue-700 text-white font-[500] py-2 px-4 rounded-full"
        >
          Send Invitation
        </button>
      </div>
    );
  }

  const searchCompanyID = (): boolean => {
    let ids: string[] = [];
    for (const cids of searchResults.map((e) =>
      (e.companies ?? []).map((c) => c.id)
    )) {
      for (const id of cids) {
        if (!ids.includes(id)) {
          ids.push(id);
        }
      }
    }
    return ids.includes(companyID);
  };

  return (
    <div className="py-4 relative min-h-[300px]">
      <div className="relative">
        <PiMagnifyingGlass
          size={20}
          className="absolute right-2 top-2.5 text-gray-400 "
        />
        <input
          type="text"
          className="w-full p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 pr-8"
          placeholder="Search People by Name or Email"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      {!disableTag &&
       <div className="py-4 flex flex-wrap gap-y-2">
       {selectedMembers.map((e) => (
         <Tag
           key={e.id}
           closable
           style={{
             backgroundColor: "#673ab7",
             color: "white",
           }}
           onClose={(val) => {
             if (onDeleteMember) {
               onDeleteMember(e)
               return
             }
             setSelectedMembers(selectedMembers.filter((m) => m.id !== e.id));
           }}
         >
           {e.user?.full_name}
         </Tag>
       ))}
     </div>
      }
     
      {search.length > 0 && (
        <div
          className="mt-2 border rounded-md shadow-sm absolute top-[54px] left-6 bg-white"
          style={{ width: "calc(100% - 50px)" }}
        >
          {!externalOnly && (
            <div className="p-2 ">
              <span className="text-gray-500 text-xs">People</span>
            </div>
          )}
          {!externalOnly && (
            <ul>
              {searchResults
                .filter((e) => e.member)
                .map((e) => (
                  <li
                    key={e.id}
                    onClick={() => {
                      if (e.member) {
                        if (onSelectMember) {
                          onSelectMember(e.member)
                          setSearchResults([])
                          setSearch("")
                          return
                        }
                        if (
                          !selectedMembers
                            .map((e) => e.id)
                            .includes(e.member.id)
                        ) {
                          e.member.user = {
                            id: e.id,
                            full_name: e.full_name,
                            email: e.email,
                            picture: e.picture,
                            picture_url: e.picture_url,
                          };
                          setSelectedMembers([...selectedMembers, e.member]);
                        }
                      }
                      setSearch("");
                    }}
                  >
                    <div className="p-2 flex items-center hover:bg-gray-100 cursor-pointer flex-row gap-2">
                      <Avatar
                        src={e.picture}
                        alt={initials(e.full_name)}
                        color="violet"
                        size="xs"
                        circle
                      />
                      <div className="flex flex-col">
                        <span className="font-[500]">{e.full_name}</span>
                        <small className="text-gray-500">{e.email}</small>
                      </div>
                    </div>
                  </li>
                ))}
              {searchResults.length == 0 && (
                <li>
                  <div className="p-2 flex items-center ">
                    <span className="ml-2">No People Exists ....</span>
                  </div>
                </li>
              )}
            </ul>
          )}
          {externalOnly &&
            isValidEmail(search) &&
            searchResults.length > 0 &&
            searchResults.map((e) => e.email).includes(search) &&
            searchCompanyID() && (
              <>
                <div className="p-4 text-xs flex flex-row gap-2 items-center">
                  <PiWarning size={64} className="text-orange-600" />
                  <div className="flex flex-col">
                    <span className="ml-2">
                      Warning: this email is already a member of the project /
                      company. Inviting a new member will not work. If you are
                      trying to add a member, please try again with a different
                      email address.
                    </span>
                    {invitationType == "CLIENT" && (
                      <button
                        className="ml-auto p-2 rounded-md bg-green-500 text-white font-[500] hover:bg-green-700"
                        onClick={() => {
                          setEmail(search);
                        }}
                      >
                        Invite Anyway
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          {isValidEmail(search) && !searchCompanyID() && (
            <div
              className="p-2 flex items-center hover:text-white hover:rounded-b cursor-pointer text-green-500 hover:bg-green-700 "
              onClick={() => {
                setEmail(search);
              }}
            >
              <FaRegEnvelope />
              <span className="ml-2 ">Invite a new people by email</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default SearchPeople;
