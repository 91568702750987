import { useState, type FC } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { register } from "../repositories/auth";
import { errorToast, successToast } from "../utils/helper-ui";

interface RegisterProps {}

const Register: FC<RegisterProps> = ({}) => {
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const Loading = () => (
    <div className="flex items-center justify-center">
      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-orange-600"></div>
    </div>
  );

  const handleReg = async () => {
    setIsLoading(true);
    try {
      const res = await register({ name, email });
      if (res.ok) {
        successToast("SUCCEED, Please check your email");
      } else {
        throw ("Registration Failed");
      }
    } catch (e) {
      // console.log(e);
      errorToast(`${e}`);
    } finally {
      setIsLoading(false);
    }
  };
  let renderRegForm = () => (
    <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <div>
          <h1 className="text-xl font-bold mb-0 leading-tight tracking-tight text-gray-900 md:text-2xl ">
            Daftar Agensia
          </h1>
          <p className="m-0">Silakan isi data diri anda</p>
        </div>
        <div className="space-y-4 md:space-y-6">
          <div>
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Nama Lengkap
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
              placeholder="Nama Lengkap"
              required={true}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
              placeholder="name@company.com"
              required={true}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>

          {/* <div className="flex items-center justify-between">
                <div className="flex items-start"></div>
                <a
                  href="/forgot"
                  className="text-sm font-medium text-primary-600 hover:underline "
                >
                  Lupa password?
                </a>
              </div> */}
          {isLoading ? (
            Loading()
          ) : (
            <button
              onClick={handleReg}
              className="w-full text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
            >
              Sign Up
            </button>
          )}

          {/* <div className="flex justify-center">
    
                    <GoogleLogin
                        onSuccess={onSuccessGoogle}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />
    
                </div> */}
          {/* <div className="flex justify-center">
                <p>
                  Belum punya akun?{" "}
                  <a
                    className=" text-pink-600 hover:font-bold hover:text-pink-600"
                    href="/register"
                  >
                    Daftar Sekarang
                  </a>
                </p>
              </div> */}
        </div>
      </div>
    </div>
  );
  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 bg-gradient-to-tr from-violet-900 to-purple-600">
      <img src={"/images/logo.png"} className="w-16 py-8" />
      {renderRegForm()}
    </div>
  );
};
export default Register;
