import React, { useContext, useEffect, useState } from "react";
import { CiCamera } from "react-icons/ci";
import { GoHome, GoMention } from "react-icons/go";
import { HiOutlineInbox } from "react-icons/hi2";
import { LuChevronDown, LuFormInput, LuPowerOff } from "react-icons/lu";
import {
  PiDropboxLogoLight,
  PiHandshakeLight,
  PiKanban,
  PiPlus,
  PiSquaresFour,
  PiUsers,
  PiUsersThree,
} from "react-icons/pi";
import { TiDocumentText } from "react-icons/ti";
import { VscChecklist } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Button,
  Dropdown,
  Input,
  Loader,
  Modal,
  SelectPicker,
  Toggle,
  Uploader,
} from "rsuite";
import { BoardTemplate } from "../model/board";
import { Company } from "../model/company";
import { Project } from "../model/project";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { COMPANY_ID, PROJECT, TOKEN } from "../objects/constants";
import { LoadingContext } from "../objects/loading";
import { PermissionsContext } from "../objects/permissions_object";
import { ProjectContext } from "../objects/project_object";
import { getBoardTemplate } from "../repositories/board";
import { getAssignedCompanies, getCompanies } from "../repositories/my";
import {
  addActivityLogProject,
  createProject,
  getProject,
  getProjects,
} from "../repositories/project";
import { initials } from "../utils/helper";
import { errorToast } from "../utils/helper-ui";
import {
  asyncLocalStorage,
  clearStorage,
  getCompanyStorage,
  getProjectStorage,
  setCompanyIDStorage,
  setCompanyStorage,
  setProjectStorage,
} from "../utils/storage";
import { CompanyContext } from "../objects/company_context";
import { GoStar } from "react-icons/go";
import { BsTrash3 } from "react-icons/bs";
import { VscSend } from "react-icons/vsc";
import { RiDraftLine } from "react-icons/ri";
import { countInbox } from "../repositories/inbox";
import { CounterContext } from "../objects/counter_provider";
import ModalCompany from "./ModalCompany";
import { BiHash } from "react-icons/bi";

export interface SidebarProps {
  profile: User;
}

const Sidebar: React.FC<SidebarProps> = ({ profile }) => {
  const { project: activeProject, setProject } = useContext(ProjectContext);
  const { color, setColor } = useContext(BgColorContext);
  const { counter, setCounter } = useContext(CounterContext);
  const [company, setCompany] = useState<Company | null>(null);
  const { company: activeCompany, setCompany: setActiveCompany } =
    useContext(CompanyContext);
  const [mounted, setMounted] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [assignedCompanies, setAssignedCompanies] = useState<Company[]>([]);
  const [searchCompany, setSearchCompany] = useState("");
  const [searchProject, setSearchProject] = useState("");
  const { permissions, setPermissions } = useContext(PermissionsContext);
  const [projects, setProjects] = useState<Project[]>([]);
  // const [activeProject, setActiveProject] = useState<Project | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [templateKey, setTemplateKey] = useState("");
  const [templates, setTemplates] = useState<BoardTemplate[]>([]);
  const [addBacklog, setAddBacklog] = useState(false);
  const [addDropBox, setAddDropBox] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [token, setToken] = useState("");
  const [companyID, setCompanyID] = useState("");
  const nav = useNavigate();
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [modalCompanyOpen, setModalCompanyOpen] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    asyncLocalStorage.getItem(TOKEN).then((v) => {
      setToken(v);
    });
    asyncLocalStorage.getItem(COMPANY_ID).then((v) => {
      setCompanyID(v);
    });
    countInbox()
      .then((res) => res.json())
      .then((v) => {
        setCounter({
          ...counter,
          inbox_unread: v.data,
        });
      });
    getCompanyStorage()
      .then((v) => {
        setCompany(v);
      })
      .catch((err) => {
        console.log(err);
      });
    getCompanies()
      .then((res) => res.json())
      .then((v) => {
        setCompanies(v.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getAssignedCompanies()
      .then((res) => res.json())
      .then((v) => {
        setAssignedCompanies(v.data);
      });

    getBoardTemplate({ page: 1, limit: 0 })
      .then((res) => res.json())
      .then((v) => {
        setTemplates(v.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getProjectStorage().then((v) => {
      setProject(v);
    });
  }, [mounted]);

  useEffect(() => {}, [companies]);

  useEffect(() => {
    if (!mounted) return;
    getAllProjects();
  }, [searchProject, mounted]);

  const getAllProjects = () => {
    getProjects({ page: 1, limit: 0, search: searchProject })
      .then((res) => res.json())
      .then((v) => {
        setProjects(v.data);
      });
  };

  return (
    <div
      className="h-full "
      style={{
        backgroundColor: color,
        width: 280,
        height: "calc(100vh - 50px)",

      }}
    >
      <div className="bg-white h-full rounded-tr-[15px] flex flex-col ">
        <div className="p-4 flex flex-row gap-2 items-center hover:bg-gray-50 rounded-tr-2xl cursor-pointer">
          <Avatar
            src={profile?.picture_url}
            alt={initials(profile?.full_name)}
            circle
            color="violet"
          />
          <div className="flex justify-center flex-col item" onClick={() =>nav(`/profile`)}>
            <h3 className="text-lg">{profile?.full_name}</h3>
            <p className="text-sm text-gray-600">{profile?.role_title}</p>
          </div>
        </div>
        <div className="flex-1 overflow-y-auto">
          <ul className="p-4 space-y-3">
            <li
              onClick={() => nav("/")}
              className={`menu-items flex flex-row justify-between menu-items-active`}
            >
              <div className="flex flex-row items-center gap-2">
                <GoHome /> Home
              </div>
            </li>
            <li
              onClick={() => nav("/inbox")}
              className={`menu-items flex flex-row justify-between `}
            >
              <div className="flex flex-row items-center gap-2">
                <HiOutlineInbox /> Inbox
              </div>
              {(counter?.inbox_unread ?? 0) > 0 && (
                <Badge
                  content={
                    (counter?.inbox_unread ?? 0) < 100
                      ? counter?.inbox_unread ?? 0
                      : "99+"
                  }
                />
              )}
            </li>
            <li className=" pl-4 pr-4">
              <ul>
                <li
                  className="flex flex-row gap-2 p-2 items-center cursor-pointer rounded-lg hover:bg-gray-100 "
                  onClick={() => nav(`/inbox/draft`)}
                >
                  <RiDraftLine /> Draft
                </li>
                <li
                  className="flex flex-row gap-2 p-2 items-center cursor-pointer rounded-lg hover:bg-gray-100 "
                  onClick={() => nav(`/inbox/sent`)}
                >
                  <VscSend /> Sent
                </li>
                <li
                  className="flex flex-row gap-2 p-2 items-center cursor-pointer rounded-lg hover:bg-gray-100 "
                  onClick={() => nav(`/inbox/starred`)}
                >
                  <GoStar /> Starred
                </li>
                <li
                  className="flex flex-row gap-2 p-2 items-center cursor-pointer rounded-lg hover:bg-gray-100 "
                  onClick={() => nav(`/inbox/trash`)}
                >
                  <BsTrash3 /> Trash
                </li>
              </ul>
            </li>

            <li
              onClick={() => nav("/my-work")}
              className={`menu-items flex flex-row justify-between`}
            >
              <div className="flex flex-row items-center gap-2">
                <GoMention /> My Work
              </div>
            </li>
            {activeCompany &&
            <li
            onClick={() => nav("/channel")}
            className={`menu-items flex flex-row justify-between`}
            >
              <div className="flex flex-row items-center gap-2">
                <BiHash /> Channels
              </div>
            </li>
            }
          </ul>
          <div className="border-b-[0.5px] border-b-gray-300"></div>
          <div className="flex flex-col p-3 ">
            <div className="flex flex-row gap-2 items-center justify-between">
              <div
                className="flex flex-row gap-2 cursor-pointer"
                onClick={() => {
                  if (company) nav(`/company/${company?.id}`);
                }}
              >
                <Avatar
                  alt={company ? initials(company?.name) : "AG"}
                  src={company?.logo}
                  circle
                  size="xs"
                  color="violet"
                />{" "}
                <span className="hover:font-[500]">
                  {company ? company?.name : "Select Company"}
                </span>
              </div>

              <Dropdown
                className="custom-menu"
                title={<LuChevronDown />}
                noCaret
                menuStyle={{ width: 240 }}
                placement="bottomEnd"
                trigger={"click"}
              >
                <div className=" ">
                  <form className="flex flex-row gap-2 w-full px-4 py-2">
                    <input
                      type="search"
                      className="w-full px-4 py-2 rounded-md border border-gray-200 focus:outline-none focus:ring-1 text-gray-500 focus:ring-blue-200"
                      placeholder="Search Company..."
                      value={searchCompany}
                      onChange={(e) => setSearchCompany(e.target.value)}
                    />
                  </form>
                  <div className="p-2">
                    {companies
                      .filter((e) =>
                        e.name
                          .toLowerCase()
                          .includes(searchCompany.toLowerCase())
                      )
                      .slice(0, 5)
                      .map((e) => (
                        <div
                          className="flex flex-row gap-2 py-2 px-2 rounded-lg cursor-pointer hover:bg-gray-100"
                          key={e.id}
                          onClick={async () => {
                            await asyncLocalStorage.removeItem(PROJECT);
                            e.is_client = false;
                            setCompanyIDStorage(e.id);
                            setCompanyStorage(e);
                            setActiveCompany(e);
                            setProject(null);

                            window.location.href = "/";
                          }}
                        >
                          <Avatar
                            alt={initials(e.name)}
                            src={e.logo}
                            circle
                            size="xs"
                            color="violet"
                          />{" "}
                          <span>{e.name}</span>
                        </div>
                      ))}
                  </div>
                  {assignedCompanies?.length > 0 && (
                    <>
                      <div className="border-b-[0.5px] border-b-gray-400"></div>
                      <div className="px-2">
                        <small className="px-2">As Client</small>
                        {assignedCompanies
                          .filter((e) =>
                            e.name
                              .toLowerCase()
                              .includes(searchCompany.toLowerCase())
                          )
                          .slice(0, 5)
                          .map((e) => (
                            <div
                              className="flex flex-row gap-2 py-2 px-2 rounded-lg cursor-pointer hover:bg-gray-100"
                              key={e.id}
                              onClick={async () => {
                                await asyncLocalStorage.removeItem(PROJECT);
                                e.is_client = true;
                                setCompanyIDStorage(e.id);
                                setCompanyStorage(e);
                                setActiveCompany(e);
                                setProject(null);

                                window.location.href = "/";
                              }}
                            >
                              <Avatar
                                alt={initials(e.name)}
                                src={e.logo}
                                circle
                                size="xs"
                                color="violet"
                              />{" "}
                              <span>{e.name}</span>
                            </div>
                          ))}
                      </div>
                    </>
                  )}

                  <div className="border-b-[0.5px] border-b-gray-400"></div>
                  <div className="py-2 px-2 flex flex-row justify-between">
                    <div className="flex flex-row items-center text-xs gap-2 cursor-pointer rounded-lg hover:bg-gray-100 p-2" onClick={() => setModalCompanyOpen(true)}>
                      <PiPlus className="w-2" /> Company
                    </div>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
          {company && !company.is_client && (
            <div className="pb-4 pl-8 pr-4">
              <ul>
                {permissions.includes("read_team") && (
                  <li
                    className="flex flex-row gap-2 p-2 items-center cursor-pointer rounded-lg hover:bg-gray-100 "
                    onClick={() => nav(`/company/${company.id}/team`)}
                  >
                    <PiUsersThree /> Team
                  </li>
                )}
                {permissions.includes("read_member") && (
                  <li
                    className="flex flex-row gap-2 p-2 items-center cursor-pointer rounded-lg hover:bg-gray-100 "
                    onClick={() => nav(`/company/${company.id}/member`)}
                  >
                    <PiUsers /> Member
                  </li>
                )}
                {permissions.includes("read_client") && (
                  <li
                    className="flex flex-row gap-2 p-2 items-center cursor-pointer rounded-lg hover:bg-gray-100 "
                    onClick={() => nav(`/company/${company.id}/client`)}
                  >
                    <PiHandshakeLight /> Client
                  </li>
                )}
              </ul>
            </div>
          )}
          <div className="border-b-[0.5px] border-b-gray-300"></div>
          <div className="flex flex-col p-3 flex-1">
            {company && (
              <div className="flex flex-row gap-2 items-center justify-between">
                <div
                  className="flex flex-row gap-2 cursor-pointer"
                  onClick={() => nav(`/project/${activeProject?.id}`)}
                >
                  <Avatar
                    alt={
                      projects.length == 0
                        ? "AG"
                        : initials(activeProject?.title)
                    }
                    circle
                    size="xs"
                    color="blue"
                    src={activeProject?.picture_url}
                  />{" "}
                  <span>
                    {projects.length == 0
                      ? "No Project"
                      : activeProject?.title ?? "Choose Project"}
                  </span>
                </div>

                <Dropdown
                  className="custom-menu"
                  title={<LuChevronDown />}
                  noCaret
                  menuStyle={{ width: 240 }}
                  placement="bottomEnd"
                  trigger={"click"}
                >
                  <div className=" ">
                    <form className="flex flex-row gap-2 w-full px-4 py-2">
                      <input
                        type="search"
                        className="w-full px-4 py-2 rounded-md border border-gray-200 focus:outline-none focus:ring-1 text-gray-500 focus:ring-blue-200"
                        placeholder="Search Project..."
                        value={searchProject}
                        onChange={(e) => setSearchProject(e.target.value)}
                      />
                    </form>
                    <div className="p-2 max-h-[300px] overflow-y-auto">
                      {projects
                        .filter((e) =>
                          e.title
                            .toLowerCase()
                            .includes(searchProject.toLowerCase())
                        )
                        .map((e) => (
                          <Dropdown.Item
                            key={e.id}
                            onClick={() => {
                              setProjectStorage(e);
                              setProject(e);
                              nav(`/project/${e.id}`);
                            }}
                          >
                            <div className="flex flex-row gap-2 ">
                              <Avatar
                                alt={initials(e.title)}
                                src={e.picture_url}
                                circle
                                size="xs"
                                color="blue"
                              />{" "}
                              <span>{e.title}</span>
                            </div>
                          </Dropdown.Item>
                        ))}
                    </div>
                    <div className="border-b-[0.5px] border-b-gray-400"></div>
                    <div className="py-2 px-2 flex flex-row justify-between">
                      {permissions.includes("create_project") && (
                        <div
                          className="flex flex-row items-center text-xs gap-2 cursor-pointer rounded-lg hover:bg-gray-100 p-2"
                          onClick={() => setShowModal(true)}
                        >
                          <PiPlus className="w-2" /> Project
                        </div>
                      )}
                      <div
                        className="flex flex-row items-center text-xs gap-2 cursor-pointer rounded-lg hover:bg-gray-100 p-2"
                        onClick={() => nav("/projects")}
                      >
                        <PiSquaresFour /> All Project
                      </div>
                    </div>
                  </div>
                </Dropdown>
              </div>
            )}
            {company && (
              <div className="py-4 pl-4 pr-4">
                <ul>
                  {(activeProject?.boards ?? []).map((e) => (
                    <li
                      className="flex flex-row gap-2 p-2 items-center cursor-pointer rounded-lg hover:bg-gray-100 "
                      key={e.id}
                      onClick={() =>
                        nav(`/project/${activeProject?.id}/board/${e.id}`)
                      }
                    >
                      {e.type == "MAIN" && <PiKanban />}
                      {e.type == "BACKLOG" && <VscChecklist />}
                      {e.title}
                    </li>
                  ))}
                  {!activeCompany?.is_client && (
                    <>
                      {(activeProject?.documents ?? []).map((e) => (
                        <li
                          className="flex flex-row gap-2 p-2 items-center cursor-pointer rounded-lg hover:bg-gray-100 "
                          key={e.id}
                          onClick={() =>
                            nav(`/project/${activeProject?.id}/doc/${e.id}`)
                          }
                        >
                          <TiDocumentText />
                          {e.title}
                        </li>
                      ))}
                    </>
                  )}

                  {(activeProject?.drop_boxes ?? []).map((e) => (
                    <li
                      className="flex flex-row gap-2 p-2 items-center cursor-pointer rounded-lg hover:bg-gray-100  "
                      key={e.id}
                      onClick={() =>
                        nav(`/project/${activeProject?.id}/drop_box/${e.id}`)
                      }
                    >
                      <PiDropboxLogoLight />
                      {e.title}
                    </li>
                  ))}
                  {(activeProject?.forms ?? []).map((e) => (
                    <li
                      className="flex flex-row gap-2 p-2 items-center cursor-pointer rounded-lg hover:bg-gray-100  "
                      key={e.id}
                      onClick={() =>
                        nav(`/project/${activeProject?.id}/form/${e.id}`)
                      }
                    >
                      <LuFormInput />
                      {e.title}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="border-b-[0.5px] border-b-gray-300"></div>
        <ul className="p-4 space-y-3">
          <li
            className={`menu-items flex flex-row justify-between `}
            onClick={() => {
              clearStorage();
              window.location.href = "/";
            }}
          >
            <div className="flex flex-row items-center gap-2">
              <LuPowerOff /> Logout
            </div>
          </li>
        </ul>
      </div>
      <Modal size={"lg"} open={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Add Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-3">
              <div className="flex flex-col space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Project Title
                  </label>
                  <Input
                    placeholder="Project Title"
                    value={title}
                    onChange={(e) => setTitle(e)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Description
                  </label>

                  <textarea
                    rows={3}
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full border border-gray-300 rounded-md p-2 focus:ring-1 focus:outline-none focus:ring-primary-300"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Project Type
                  </label>
                  <SelectPicker
                    data={templates}
                    valueKey="code"
                    labelKey="title"
                    block
                    value={templateKey}
                    onChange={(e) => setTemplateKey(e!)}
                    placeholder="Select Template"
                  />
                </div>
                <div>
                  <Toggle
                    checked={addBacklog}
                    onChange={(e) => setAddBacklog(e)}
                  >
                    Add Backlog
                  </Toggle>
                </div>
                <div>
                  <Toggle
                    checked={addDropBox}
                    onChange={(e) => setAddDropBox(e)}
                  >
                    Add DropBox
                  </Toggle>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <Uploader
                fileListVisible={false}
                listType="picture"
                onUpload={(file) => {
                  setIsLoading(true);
                }}
                accept="image/*"
                action={`${process.env.REACT_APP_API_URL}/file/upload`}
                onSuccess={async (resp) => {
                  setFile(resp.data.path);
                  setFileUrl(resp.data.url);
                  setIsLoading(false);
                }}
                headers={{
                  authorization: `Bearer ${token}`,
                  "ID-Company": companyID,
                }}
                onError={() => {
                  errorToast("Upload Failed");
                }}
              >
                <button style={{ width: 200, height: 200 }}>
                  {isLoading && <Loader backdrop center />}
                  {fileUrl ? (
                    <img src={fileUrl} width="100%" height="100%" />
                  ) : (
                    <CiCamera style={{ fontSize: 80 }} />
                  )}
                </button>
              </Uploader>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)} appearance="subtle">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              if (title.length > 0) {
                try {
                  setIsLoading(true);
                  let resp = await createProject({
                    title,
                    description,
                    template_key: templateKey,
                    add_backlog: addBacklog,
                    add_drop_box: addDropBox,
                    picture: file,
                  });
                  let respJson = await resp.json();
                  let project = await getProject(respJson.data.last_id);
                  let projectJson = await project.json();
                  setProject(projectJson.data);
                  setProjectStorage(projectJson.data);
                  await    addActivityLogProject({
                    company_id: activeCompany!.id,
                    project_id: projectJson.data.id,
                    activity_type: "CREATE_PROJECT",
                    context_type: "PROJECT",
                    pre_condition: "{}",
                    data: JSON.stringify(projectJson.data)
                  })
                  nav(`/project/${projectJson.data.id}`);

                  setShowModal(false);
               
                  getAllProjects();
                } catch (error) {
                  errorToast(`${error}`);
                } finally {
                  setIsLoading(false);
                }
                // addProject(title);
              }
            }}
            appearance="primary"
            color="violet"
          >
            Add Project
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalCompany
        open={modalCompanyOpen}
        onClose={() => setModalCompanyOpen(false)}
      />
    </div>
  );
};

export default Sidebar;
