import { customFetch } from "../utils/http";
import { PaginationReq } from "../objects/pagination";
import { InvitationApproveRequest } from "../model/invitation";

export const getRoles = async (pagination: PaginationReq) => {
  const params: Record<string, string> = {
      page: pagination.page.toString(),
      limit: pagination.limit.toString(),

  };
  if (pagination.search && pagination.search != "") {
      params["search"] = pagination.search
  }
  return await customFetch(`general/roles?${new URLSearchParams(params)}`);
  
};
export const invitationDetail = async (id: string) => {
 
  return await customFetch(`general/invitation/${id}`);
};
export const getTaskDescTemplate = async () => {
 
  return await customFetch(`general/taskDescTemplate`);
};
export const invitationAcceptHandler = async (req: InvitationApproveRequest) => {
 
  return await customFetch(`general/invitation/accept`, {
    method: "POST",
    body: JSON.stringify(req),
  });
};
