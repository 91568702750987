import { useEffect, useState, type FC } from "react";
import { Modal, SelectPicker } from "rsuite";
import { createCompany, getProfile } from "../repositories/my";
import { getProfileStorage } from "../utils/storage";
import { User } from "../model/user";
import { errorToast, successToast } from "../utils/helper-ui";

interface ModalCompanyProps {
  open: boolean;
  onClose: () => void;
}

const ModalCompany: FC<ModalCompanyProps> = ({ open, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState<User | null>(null);
  const [step, setStep] = useState(2);
  const [mounted, setMounted] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [employeeCount, setEmployeeCount] = useState(10);
  const [companySize, setCompanySize] = useState([
    { label: "2-10", value: 10 },
    { label: "11-20", value: 20 },
    { label: "21-30", value: 30 },
    { label: "31-40", value: 40 },
    { label: "41-50", value: 50 },
    { label: "51-60", value: 60 },
    { label: "61-70", value: 70 },
    { label: "71-80", value: 80 },
    { label: "81-90", value: 90 },
    { label: "91-100", value: 100 },
    { label: "> 100", value: 0 },
  ]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;

    getProfileStorage()
      .then((v) => {
        setProfile(v.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [mounted]);

  const create = async () => {
    try {
      setIsLoading(true);
      const res = await createCompany({
        name: companyName,
        address: companyAddress,
        phone: companyPhone,
        employee_count: employeeCount,
      });

      successToast("Berhasil mengirim data perusahaan");
      window.location.href = "/";
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  const Loading = () => (
    <div className="flex items-center justify-center">
      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-orange-600"></div>
    </div>
  );
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Create Company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" space-y-4 ">
          {step == 1 && <></>}
          {step == 2 && (
            <>
              <div className=" font-ubuntu">
                <form className="space-y-4">
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="company_name"
                      className="text-gray-700 font-[500]"
                    >
                      Nama Perusahaan
                    </label>
                    <input
                      type="text"
                      id="company_name"
                      className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
                      placeholder="PT. Agensia Indonesia"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="company_address"
                      className="text-gray-700 font-[500]"
                    >
                      Alamat Perusahaan
                    </label>
                    <textarea
                      rows={5}
                      id="company_address"
                      className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
                      placeholder="Jalan Raya Bogor No. 123"
                      value={companyAddress}
                      onChange={(e) => setCompanyAddress(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="company_phone"
                      className="text-gray-700 font-[500]"
                    >
                      Nomor Telepon Perusahaan
                    </label>
                    <input
                      type="text"
                      id="company_phone"
                      className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
                      placeholder="08123456789"
                      value={companyPhone}
                      onChange={(e) => setCompanyPhone(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="company_employee"
                      className="text-gray-700 font-[500]"
                    >
                      Jumlah Karyawan
                    </label>
                    <SelectPicker
                      data={companySize}
                      value={employeeCount}
                      onChange={(val) => {
                        setEmployeeCount(val!);
                      }}
                    />
                  </div>
                </form>
              </div>
            </>
          )}
          {step == 3 && (
            <>
              <div className=" font-ubuntu">
                <div className="space-y-4">
                  <div className="flex flex-col gap-2">
                    <p className="font-bold">
                      Nama Perusahaan:{" "}
                      <span className="font-normal">{companyName}</span>
                    </p>
                    <p className="font-bold">
                      Alamat Perusahaan:{" "}
                      <span className="font-normal">{companyAddress}</span>
                    </p>
                    <p className="font-bold">
                      Nomor Telepon Perusahaan:{" "}
                      <span className="font-normal">{companyPhone}</span>
                    </p>
                    <p className="font-bold">
                      Jumlah Karyawan:{" "}
                      <span className="font-normal">{employeeCount}</span>
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {step == 3 && isLoading && Loading()}
        <div className="flex justify-end">
          {step == 3 && !isLoading && (
            <button
              onClick={create}
              className="text-white bg-violet-600 hover:bg-violet-700 focus:ring-4 focus:ring-violet-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
            >
              Kirim
            </button>
          )}

          {step < 3 && (
            <button
              onClick={() => {
                if (
                  !companyName ||
                  !companyAddress ||
                  !companyPhone ||
                  !employeeCount
                ) {
                  errorToast("Harap isi semua field");
                  return;
                }

                setStep(step + 1);
              }}
              className="text-white bg-violet-600 hover:bg-violet-700 focus:ring-4 focus:ring-violet-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
            >
              Selanjutnya
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalCompany;
