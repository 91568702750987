export let PERMISSIONS = "permissions"
export let TOKEN = "token"
export let PROFILE = "profile"
export let COMPANY_ID = "company-id"
export let COMPANY = "company"
export let PROJECT = "project"

export let priorityList = [
    "Lowest", "Low", "Medium", "High", "Highest"
]


