import React, { useEffect } from "react";
import { useState, type FC } from "react";
import { Progress } from "rsuite";
import {
  getTextColorBasedOnBackground,
  hexColorFromProgress,
  invertHexColor,
} from "../utils/helper";

interface CircleStatusProps {
  percentage: number;
  onChange: (val: number) => void;
}

const CircleStatus: FC<CircleStatusProps> = ({ percentage, onChange }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);
  return (
    <>
      {showEdit ? (
        <input
          type="number"
          className="focus:ring-0 focus:outline-none focus:ring-gray-200 text-center p-2"
          value={progress}
          onChange={(e) => {
            let val = parseInt(e.target.value);
            if (val > 100) val = 100;
            setProgress(val);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onChange(progress);
              setShowEdit(false);
            }
          }}
          style={{ WebkitAppearance: "none", textAlign: "right" }}
        />
      ) : (
        <div style={{ width: 40 }} onClick={() => setShowEdit(true)}>
          <Progress.Circle
            className="custom-progress"
            percent={percentage}
            strokeColor={hexColorFromProgress(percentage)}
            strokeWidth={10}
          />
        </div>
      )}
    </>
  );
};
export default CircleStatus;
