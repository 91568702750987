import { Company, CompanyReq } from "../model/company";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/http";

export const createCompany = async (company: Company) => {
  return await customFetch("company", {
    method: "POST",
    body: JSON.stringify(company),
  });
};

export const getCompanies = async (pagination: PaginationReq) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };
  if (pagination.search && pagination.search != "") {
    params["search"] = pagination.search;
  }
  return await customFetch(`company?${new URLSearchParams(params)}`);
};

export const getCompany = async (id: string) => {
  return await customFetch(`company/${id}`);
};

export const updateCompany = async (id: string, company: CompanyReq) => {
  return await customFetch(`company/${id}`, {
    method: "PUT",
    body: JSON.stringify(company),
  });
};
