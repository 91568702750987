import { Board, BoardReq } from "../model/board";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/http";

export const createBoard = async (board: BoardReq) => {
  return await customFetch("board", {
    method: "POST",
    body: JSON.stringify(board),
  });
};

export const getBoards = async (pagination?: PaginationReq) => {
  const params: Record<string, string> = {
    
  };
  if (pagination?.search && pagination?.search != "") {
    params["search"] = pagination?.search;
  }
  if (pagination?.page) {
    params["page"] = pagination?.page.toString();
  }
  if (pagination?.limit) {
    params["limit"] = pagination?.limit.toString();
  }
  if (pagination?.project_id) {
    params["project_id"] = pagination?.project_id;
  }
  return await customFetch(`board?${new URLSearchParams(params)}`);
};

export const getBoard = async (id: string) => {
  return await customFetch(`board/${id}`);
};
export const getBoardSummary = async (id: string) => {
  return await customFetch(`board/${id}/summary`);
};
export const getBoardTemplate = async (pagination: PaginationReq) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };
  if (pagination.search && pagination.search != "") {
    params["search"] = pagination.search;
  }
  return await customFetch(`board/templates?${new URLSearchParams(params)}`);
};

export const updateBoard = async (id: string, board: any) => {
  return await customFetch(`board/${id}`, {
    method: "PUT",
    body: JSON.stringify(board),
  });
};

export const deleteBoard = async (id: string) => {
  return await customFetch(`board/${id}`, {
    method: "DELETE",
  });
};
