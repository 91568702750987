import { useEffect, useState, type FC } from "react";
import { useParams } from "react-router-dom";
import { getBoardSummary } from "../repositories/board";
import { BoardSummary, Task } from "../model/board";
import { User } from "../model/user";
import TaskProgressPieChart from "./chart/TaskProgressPieChart";
import CustomAvatar from "./CustomAvatar";
import PriorityBarChart from "./chart/PriorityBarChart";
import moment from "moment";

interface BoardSummaryPageProps {
  profile: User | undefined;
  onClickTask: (task: Task) => void;
}

const BoardSummaryPage: FC<BoardSummaryPageProps> = ({
  profile,
  onClickTask,
}) => {
  const [mounted, setMounted] = useState(false);
  const { boardId } = useParams();
  const [summary, setSummary] = useState<BoardSummary>();
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getAllSummary();
  }, [mounted, boardId]);

  const getAllSummary = () => {
    getBoardSummary(boardId!)
      .then((res) => res.json())
      .then((res) => {
        setSummary(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className=" min-h-screen p-8">
      <header className="text-center text-gray-800 mb-8">
        <h1 className="text-2xl font-semibold">
          {new Date().getHours() < 12
            ? "Good morning"
            : new Date().getHours() < 17
            ? "Good afternoon"
            : "Good evening"}
          , {profile?.full_name}
        </h1>
        <p className="text-gray-600">
          Here's a summary of your project's status, priorities, workload, and
          more.
        </p>
      </header>

      <div className="grid  gap-4 lg:grid-cols-12">
        {/* Card 1 */}
        <div className="bg-gray-50 hover:bg-gray-100 p-4 rounded-lg hover:shadow xl:col-span-3 col-span-6">
          <p className="text-lg text-gray-700 font-[500]">
            {summary?.done_tasks_count} Done
          </p>
          <p className="text-sm text-gray-500">in the last 7 days</p>
        </div>

        {/* Card 2 */}
        <div className="bg-gray-50 hover:bg-gray-100 p-4 rounded-lg hover:shadow xl:col-span-3 col-span-6">
          <p className="text-lg text-gray-700 font-[500]">
            {summary?.updated_tasks_count} Updated
          </p>
          <p className="text-sm text-gray-500">in the last 7 days</p>
        </div>

        {/* Card 3 */}
        <div className="bg-gray-50 hover:bg-gray-100 p-4 rounded-lg hover:shadow xl:col-span-3 col-span-6">
          <p className="text-lg text-gray-700 font-[500]">
            {summary?.created_tasks_count} Created
          </p>
          <p className="text-sm text-gray-500">in the last 7 days</p>
        </div>

        {/* Card 4 */}
        <div className="bg-gray-50 hover:bg-gray-100 p-4 rounded-lg hover:shadow xl:col-span-3 col-span-6">
          <p className="text-lg text-gray-700 font-[500]">
            {summary?.due_tasks_count} Due
          </p>
          <p className="text-sm text-gray-500">in the next 7 days</p>
        </div>

        {/* Status Overview */}
        <div className="bg-gray-50 hover:bg-gray-100 p-6 rounded-lg hover:shadow col-span-6 xl:col-span-4">
          <h2 className="text-sm font-semibold text-gray-700 mb-4">
            Status Overview
          </h2>
          <div className="text-center">
            <TaskProgressPieChart data={summary?.tasks_by_columns ?? []} />
          </div>
        </div>

        {/* Recent Activity */}
        <div className="bg-gray-50 hover:bg-gray-100 p-6 rounded-lg hover:shadow col-span-6 xl:col-span-4">
          <h2 className="text-sm font-semibold text-gray-700 mb-4">
            Recent Activity
          </h2>
          <ul className="text-sm text-gray-600">
            {(summary?.recent_activities ?? []).map((item) => (
              <li
                className="text-sm flex flex-row items-center gap-2 mb-2 last:mb-0"
                key={item.id}
              >
                <div className="flex flex-row gap-1">
                  <CustomAvatar
                    size={36}
                    alt={item.creator.user.full_name}
                    src={item.creator.user.picture_url}
                  />
                </div>
                <div className=" flex-1">
                  <div>
                    <span className="mr-1">{item.creator.user.full_name}</span>
                    <strong className="text-blue-400">
                      {item.activity_type}{" "}
                    </strong>{" "}
                    at{" "}
                    <span
                      className="italic text-blue-400 hover:font-[500] hover:text-blue-600 cursor-pointer truncate"
                      onClick={() => onClickTask(item.task!)}
                    >
                      {item.task?.title}
                    </span>
                  </div>
                    <span className="text-gray-500 italic text-xs">{moment(item.created_at).fromNow()}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Priority Breakdown */}
        <div className="bg-gray-50 hover:bg-gray-100 p-6 rounded-lg hover:shadow col-span-6 xl:col-span-4">
          <h2 className="text-sm font-semibold text-gray-700 mb-4">
            Priority Breakdown
          </h2>
          <div className="flex items-center">
            <PriorityBarChart data={summary?.tasks_by_priorities ?? []} />
          </div>
        </div>

        {/* Types of Work */}
        {/* <div className="bg-gray-50 hover:bg-gray-100 p-6 rounded-lg hover:shadow col-span-6 xl:col-span-4">
          <h2 className="text-sm font-semibold text-gray-700 mb-4">
            Types of Work
          </h2>
          <div className="flex justify-between items-center text-sm text-gray-600">
            <p>Task</p>
            <p>100%</p>
          </div>
        </div> */}

        {/* Team Workload */}
        {/* <div className="bg-gray-50 hover:bg-gray-100 p-6 rounded-lg hover:shadow col-span-6 xl:col-span-4">
          <h2 className="text-sm font-semibold text-gray-700 mb-4">
            Team Workload
          </h2>
          <p className="text-sm text-gray-600">Unassigned: 100%</p>
        </div> */}

        {/* Related Projects */}
        {/* <div className="bg-gray-50 hover:bg-gray-100 p-6 rounded-lg hover:shadow col-span-6 xl:col-span-4">
          <h2 className="text-sm font-semibold text-gray-700 mb-4">
            Related Projects
          </h2>
          <button className="bg-blue-500 text-white px-4 py-2 rounded">
            Create a Project
          </button>
        </div> */}
      </div>
    </div>
  );
};
export default BoardSummaryPage;
