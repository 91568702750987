import { Column, ColumnReq } from "../model/board";
import { customFetch } from "../utils/http";
import { PaginationReq } from "../objects/pagination";

export const createColumn = async (column: ColumnReq) => {
    return await customFetch(`column`, {
        method: "POST",
        body: JSON.stringify(column),
    });
    
};

export const getColumns = async (pagination: PaginationReq) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }
    if (pagination.board_id) {
        params["board_id"] = pagination.board_id;
      }
    return await customFetch(`column?${new URLSearchParams(params)}`);
    
};
export const getColumn = async (id: string) => {
    return await customFetch(`column/${id}`);
    
};

export const updateColumn = async (id: string, column: ColumnReq) => {
    return await customFetch(`column/${id}`, {
        method: "PUT",
        body: JSON.stringify(column),
    });
    
};

export const deleteColumn = async (id: string): Promise<void> => {
    await customFetch(`column/${id}`, {
        method: "DELETE",
    });
};
