import { TaskFieldReq, TaskReq } from "../model/board";
import { CommentReq } from "../model/comment";
import { FileReq } from "../model/file";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/http";

export const createTask = async (task: TaskReq) => {
    return await customFetch(`task`, {
        method: "POST",
        body: JSON.stringify(task),
    });
    
};

export const getTasks = async (pagination: PaginationReq) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }
    return await customFetch(`task?${new URLSearchParams(params)}`);
    
};
export const getTask = async (id: string) => {
    return await customFetch(`task/${id}`);
    
};

export const duplicateTask = async (id: string) => {
    return await customFetch(`task/${id}/duplicate`, {
        method: "PUT",
    });
};

export const updateTask = async (id: string, task: TaskReq) => {
    return await customFetch(`task/${id}`, {
        method: "PUT",
        body: JSON.stringify(task),
    });
    
};
export const addFieldTask = async (id: string, req: TaskFieldReq) => {
    return await customFetch(`task/${id}/addField`, {
        method: "PUT",
        body: JSON.stringify(req),
    });
    
};
export const updateFieldTask = async (id: string, fieldId: string, req: TaskFieldReq) => {
    return await customFetch(`task/${id}/updateField/${fieldId}`, {
        method: "PUT",
        body: JSON.stringify(req),
    });
    
};
export const taskAddContributors = async (id: string, contributor_ids: string[]) => {
    return await customFetch(`task/${id}/addContributor`, {
        method: "PUT",
        body: JSON.stringify({contributor_ids}),
    });
    
};

export const taskAddFiles = async (id: string, files: FileReq[]) => {
    return await customFetch(`task/${id}/addFile`, {
        method: "PUT",
        body: JSON.stringify(files),
    });
    
};
export const taskAddComment = async (id: string, comment: CommentReq) => {
    return await customFetch(`task/${id}/addComment`, {
        method: "PUT",
        body: JSON.stringify(comment),
    });
    
};

export const deleteTask = async (id: string): Promise<void> => {
    await customFetch(`task/${id}`, {
        method: "DELETE",
    });
};
