import { FormResponseReq } from "../model/form_response";
import { customFetch } from "../utils/http";

export const getPublicForm = (formID: string) => {
  return customFetch(`public/form/${formID}`);
};
export const savePublicForm = (formID: string, req: FormResponseReq) => {
  return customFetch(`public/form/${formID}`, {
    method: "POST",
    body: JSON.stringify(req),
  });
};
