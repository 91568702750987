import type { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Verification from "../pages/Verification";

interface PublicRoutesProps {}

const PublicRoutes: FC<PublicRoutesProps> = ({}) => (
  <Routes>
    <Route path="login" element={<Login />} />
    <Route path="register" element={<Register />} />
    <Route path="verification/:token" element={<Verification />} />
    <Route path='*' element={<Navigate to='/login' replace />} />
  </Routes>
);
export default PublicRoutes;
