import React, { useContext, useEffect, useState } from "react";
import { DropBoxItem } from "../model/drop_box_item";
import moment from "moment";
import CustomAvatar from "./CustomAvatar";
import { BgColorContext } from "../objects/color_object";
import { Modal } from "rsuite";
import CommentBox from "./CommentBox";
import { CommentReq } from "../model/comment";
import { User } from "../model/user";
import { Member } from "../model/member";
import { Client } from "../model/client";
import { errorToast } from "../utils/helper-ui";
import { LoadingContext, LoadingContextType } from "../objects/loading";
import {
  addCommentItemDropBox,
  getDropBoxItem,
} from "../repositories/drop_box";
import { useParams } from "react-router-dom";
import CommentList, { CommentParse } from "./CommentList";

type DropItemBoxProps = {
  data: DropBoxItem;
  profile?: User | null;
  members: Member[];
  clients: Client[];
};

const DropItemBox: React.FC<DropItemBoxProps> = ({
  data,
  profile,
  members,
  clients,
}) => {
  const { color, setColor } = useContext(BgColorContext);
  const [preview, setPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [dropBoxItem, setDropBoxItem] = useState<DropBoxItem>();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { projectId, dropBoxId } = useParams();

  useEffect(() => {
    setDropBoxItem(data);
  }, [data]);
  if (!dropBoxItem) return null;
  return (
    <div className="bg-white shadow-md rounded-lg p-4 mb-6 max-w-[600px] w-full transition-all duration-300 ease-in-out">
      {/* User Info */}
      <div className="flex items-center mb-4 gap-2">
        <CustomAvatar
          src={dropBoxItem!.creator?.user?.picture_url}
          alt={dropBoxItem!.creator?.user?.full_name}
          size={36}
        />
        <div>
          <h4 className="font-semibold">
            {dropBoxItem!.creator?.user?.full_name}
          </h4>
          <p className="text-sm text-gray-500">
            {moment(dropBoxItem!.created_at).fromNow()}
          </p>
        </div>
      </div>

      {/* DropItemBox Content */}
      <div className="mb-4">
        <CommentParse text={dropBoxItem!.title} />
      </div>

      {/* DropItemBox Image */}
      {/* {dropBoxItem!.files[0].url && (
        <div className="mb-4">
          <img
            src={dropBoxItem!.files[0].url}
            alt="DropItemBox content"
            className="rounded-lg w-full"
          />
        </div>
      )} */}

      <div className="mb-4 aspect-auto  bg-gray-100 rounded-lg shadow-inner  w-full p-4 grid grid-cols-3 gap-4">
        {dropBoxItem!.files.map((e) => (
          <div
            key={e.id}
            className=" aspect-square rounded-lg bg-white flex flex-col justify-center items-center cursor-pointer relative"
          >
            {e.is_image ? (
              <img
                src={e.url}
                className=" object-cover rounded-lg aspect-square"
                onError={() => {}}
                onClick={() => {
                  setPreview(true);
                  setPreviewUrl(e.url);
                }}
              />
            ) : (
              <img
                src="/images/folder.png"
                onClick={() => window.open(e.url)}
              />
            )}
          </div>
        ))}
      </div>

      {/* Likes & Comments */}
      <div className="flex justify-between items-center text-sm text-gray-500 mb-3">
        {/* <p>{likes} likes</p> */}
        <p>0 likes</p>
        <p>{dropBoxItem!.comments.length} comments</p>
      </div>
      <CommentBox
      channels={[]}
        profile={profile}
        members={members}
        clients={clients}
        onSend={async (val) => {
          try {
            setIsLoading(true);
            await addCommentItemDropBox(
              projectId!,
              dropBoxId!,
              dropBoxItem!.id,
              val
            );
            let resp = await getDropBoxItem(
              projectId!,
              dropBoxId!,
              dropBoxItem!.id
            );
            let respJson = await resp.json();
            setDropBoxItem(respJson.data);
          } catch (e) {
            errorToast("${e}");
          } finally {
            setIsLoading(false);
          }
        }}
      />
      {dropBoxItem.comments.length > 0 && (
        <div className="mt-8">
          <CommentList comments={dropBoxItem.comments} />
        </div>
      )}

      {/* Comments Section */}
      <div className=" py-4"></div>
      <Modal open={preview} onClose={() => setPreview(false)}>
        <Modal.Body style={{}}>
          {previewUrl && (
            <img src={previewUrl} className="w-full h-full rounded-lg " />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DropItemBox;
