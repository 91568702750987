import { useEffect, useState, type FC } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/id";
import { CalendarEvent } from "../model/event";
import { Column, Task } from "../model/board";
import { getTextColorBasedOnBackground } from "../utils/helper";
import { ActivityLogReq, Project } from "../model/project";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

interface BigCalendarProps {
  project: Project | null;
  data: Column[];
  onColumnChange: (column: Column) => void
  onTaskChange: (task: Task) => void
  onSelectTask: (task: Task) => void
  onUpdateActivity: (act: ActivityLogReq) => void
  
}

const BigCalendar: FC<BigCalendarProps> = ({ data, onColumnChange, onTaskChange, onSelectTask, onUpdateActivity }) => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    let newEvents: CalendarEvent[] = [];
    for (const column of data) {
      for (const task of column.tasks) {
        newEvents.push({
          id: task.id,
          start: moment(task.date).toDate(),
          end: moment(task.due_date).toDate(),
          title: `${task.title}`,
          bgColor: column.color,
          textColor: getTextColorBasedOnBackground(column.color),
          task
        });
      }
    }
    setEvents(newEvents);
  }, [data]);

  const onEventResize = (
    data: CalendarEvent,
    start: string | Date,
    end: string | Date
  ) => {
    let newEvent = [
      ...events.map((e) => {
        if (e.id == data.id) {
          e.start = start as Date;
          e.end = end as Date;
        }
        return e;
      }),
    ];
    setEvents(newEvent);
  };

  const onEventDrop = (
    data: CalendarEvent,
    start: string | Date,
    end: string | Date
  ) => {
    let newEvent = [
      ...events.map((e) => {
        if (e.id == data.id) {
          e.start = start as Date;
          e.end = end as Date;
        }
        return e;
      }),
    ];
    setEvents(newEvent);
  };
  let eventStyleGetter = (
    event: CalendarEvent,
    start: Date,
    end: Date,
    isSelected: boolean
  ) => {
    // console.log(event);
    var backgroundColor = event.bgColor;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      opacity: 0.8,
      color: event.textColor,
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };
  let formats = {
    timeGutterFormat: 'HH:mm',
  }
  const onSelectEvent = (event: CalendarEvent) => {
    // console.log(event.task);
    onSelectTask(event.task)
  };
  return (
    <div className="p-4 overflow-y-auto">
      <DnDCalendar
        defaultDate={moment().toDate()}
        defaultView="month"
        events={events}
        localizer={localizer}
        onEventDrop={(val) => {
          onEventDrop(val.event as CalendarEvent, val.start, val.end);
        }}
        onEventResize={(val) => {
          onEventResize(val.event as CalendarEvent, val.start, val.end);
        }}
        onSelectEvent={(val) => {
          onSelectEvent(val as CalendarEvent);
        }}
        resizable
        style={{ height: "100vh" }}
        eventPropGetter={eventStyleGetter as any}
        showMultiDayTimes
      />
    </div>
  );
};
export default BigCalendar;
