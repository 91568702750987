// TaskProgressPieChart.tsx
import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";

interface TaskProgress {
  column_id: string;
  column_name: string;
  count: number;
  color: string;
}

interface TaskProgressPieChartProps {
  data: TaskProgress[];
}

const TaskProgressPieChart: React.FC<TaskProgressPieChartProps> = ({ data }) => {
  // Extract the necessary data for the chart
  const chartLabels = data.map((item) => item.column_name);
  const chartData = data.map((item) => item.count);
  const chartColors = data.map((item) => item.color);

  const chartOptions = {
    chart: {
      type: 'pie',
    },
    labels: chartLabels,
    colors: chartColors,
    legend: {
      position: 'right',
    },
    responsive: [
      {
        breakpoint: undefined,
        options: {
          chart: {
          },
          legend: {
            position: 'right',
          },
        },
      },
    ],
  };

  const series = chartData; // Data for each column's task count

  return (
    <div>
      <Chart
        options={chartOptions as ApexOptions}
        series={series}
        type="pie"
      />
    </div>
  );
};

export default TaskProgressPieChart;
