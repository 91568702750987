import type { FC } from "react";
import { useContext, useEffect, useRef, useState } from "react";
import MainLayout from "../components/MainLayout";
import ProjectBoard from "../components/ProjectBoard";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { ProjectContext } from "../objects/project_object";
import { useNavigate, useParams } from "react-router-dom";
import { Inbox } from "../model/inbox";
import { LoadingContext } from "../objects/loading";
import {
  countInbox,
  createInbox,
  deleteInbox,
  getInbox,
  getInboxes,
  inboxFavorite,
  inboxUnFavorite,
} from "../repositories/inbox";
import { errorToast, successToast } from "../utils/helper-ui";
import { VscSend } from "react-icons/vsc";
import { Button, Divider, Drawer, Input, Modal, Tag } from "rsuite";
import InlineForm from "../components/InlineForm";
import AsyncSelect from "react-select/async";
import { searchUser } from "../repositories/search";
import ReactQuill from "react-quill-new";
import { Delta } from "quill/core";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { FileReq } from "../model/file";
import { uploadFile } from "../repositories/file";
import { GrAttachment } from "react-icons/gr";
import { HiOutlineInbox, HiPaperClip, HiXMark } from "react-icons/hi2";
import { RiDraftLine, RiReplyLine } from "react-icons/ri";
import { components, MultiValue } from "react-select";
import moment from "moment";
import CustomAvatar from "../components/CustomAvatar";
import { GoStar, GoStarFill } from "react-icons/go";
import { BsTrash3 } from "react-icons/bs";
import { CounterContext } from "../objects/counter_provider";
import { getRandomColor, getTextColorBasedOnBackground } from "../utils/helper";
import ComposeForm from "../components/ComposeForm";

interface InboxPageProps {}

const InboxPage: FC<InboxPageProps> = ({}) => {
  const { counter, setCounter } = useContext(CounterContext);
  const { project: activeProject, setProject: setActiveProject } =
    useContext(ProjectContext);
  const [profile, setProfile] = useState<User>();
  const { color, setColor } = useContext(BgColorContext);
  const { inboxType, inboxId } = useParams();
  const [inboxes, setInboxes] = useState<Inbox[]>([]);
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState("");
  const [modalCompose, setModalCompose] = useState(false);
  const [inbox, setInbox] = useState<Inbox | null>(null);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState<any>();
  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FileReq[]>([]);
  const [originalFiles, setOriginalFiles] = useState<File[]>([]);
  const [recipients, setRecipients] = useState<
    MultiValue<{ value: string; label: string; email: string }>
  >([]);
  const nav = useNavigate();
  const [modalPreview, setModalPreview] = useState(false);
  const [preview, setPreview] = useState("");
  const [selectedInbox, setSelectedInbox] = useState<Inbox | null>(null);
  const [inboxDraft, setInboxDraft] = useState<Inbox | null>(null);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getAllInboxes();
  }, [mounted, inboxType]);

  const getAllInboxes = async () => {
    try {
      setIsLoading(true);
      const res = await getInboxes({ page, limit, search, type: inboxType });
      const json = await res.json();
      setInboxes(json.data);
    } catch (err) {
      errorToast(`${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!mounted) return;
    if (inboxId) {
      getInbox(inboxId!)
        .then((res) => res.json())
        .then((v) => {
          setInbox(v.data);
          getAllInboxes();
          countInbox()
            .then((res) => res.json())
            .then((v) => {
              setCounter({
                ...counter,
                inbox_unread: v.data,
              });
            });
        });
    }
  }, [inboxId, mounted]);

  const renderHeader = () => {
    switch (inboxType) {
      case "draft":
        return (
          <div className="flex justify-between items-center gap-2 text-gray-500">
            <RiDraftLine />
            <div className="">
              <h1 className="text-lg font-[500]">Draft</h1>
            </div>
          </div>
        );
      case "sent":
        return (
          <div className="flex justify-between items-center gap-2 text-gray-500">
            <VscSend />
            <div className="">
              <h1 className="text-lg font-[500]">Sent</h1>
            </div>
          </div>
        );
      case "starred":
        return (
          <div className="flex justify-between items-center gap-2 text-gray-500">
            <GoStar />
            <div className="">
              <h1 className="text-lg font-[500]">Starred</h1>
            </div>
          </div>
        );
      case "trash":
        return (
          <div className="flex justify-between items-center gap-2 text-gray-500">
            <BsTrash3 />
            <div className="">
              <h1 className="text-lg font-[500]">Trash</h1>
            </div>
          </div>
        );

      default:
        return (
          <div className="flex justify-between items-center gap-2 text-gray-500">
            <HiOutlineInbox />
            <div className="">
              <h1 className="text-lg font-[500]">Inbox</h1>
            </div>
          </div>
        );
    }
  };

  const msgDetail = (msg: Inbox | null, isReply: boolean) => (
    <div
      className="flex flex-col  overflow-y-auto border-b pb-4 mb-4 last:mb-0 w-full"
      key={msg?.id}
    >
      <div className="flex flex-row gap-2 mb-4">
        <div className="w-12"></div>
        <div className="flex-1 flex justify-between items-center">
          <h1 className="font-[500] text-2xl">{msg?.title}</h1>
          {!isReply && (
            <button
              className="rounded-lg border bg-white flex flex-row gap-2 px-4 py-2 text-xs text-gray-600 hover:bg-gray-100 items-center "
              onClick={() => {
                setSubject("RE: " + msg?.title);
                setSelectedInbox(msg);
                setModalCompose(true);
                setTimeout(() => {}, 300);
              }}
            >
              <RiReplyLine /> Reply
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <div className="w-12">
          <CustomAvatar
            src={msg?.from?.picture_url}
            alt={msg?.from?.full_name}
          />
        </div>
        <div className="flex-1 overflow-y-auto h-full">
          <div className="flex flex-row justify-between">
            <div>
              <h3 className="font-[500] text-lg">{msg?.from?.full_name}</h3>
              {!isReply && (
                <p className="text-gray-600 text-sm">
                  <strong>to: </strong>
                  <small>
                    {msg?.recipients
                      .map((e) => `${e.to.full_name} (${e.to.email})`)
                      .join(", ")}
                  </small>
                </p>
              )}
            </div>
            <div className="text-xs italic flex flex-row gap-2 items-center">
              {moment(msg?.created_at).fromNow()}

              <div
                className=" cursor-pointer"
                onClick={async () => {
                  try {
                    setIsLoading(true);
                    if (
                      (msg?.favorites ?? [])
                        .map((e) => e.id)
                        .includes(profile!.id)
                    ) {
                      await inboxUnFavorite(msg!.id);
                    } else {
                      await inboxFavorite(msg!.id);
                    }

                    const res = await getInbox(inboxId!);
                    const v = await res.json();
                    setInbox(v.data);
                  } catch (e) {
                    errorToast("Failed to favorite/unfavorite");
                  } finally {
                    setIsLoading(false);
                  }
                }}
              >
                {!(msg?.favorites ?? [])
                  .map((e) => e.id)
                  .includes(profile!.id) ? (
                  <GoStar className=" text-gray-400" />
                ) : (
                  <GoStarFill className=" text-orange-400" />
                )}
              </div>
            </div>
          </div>
          <Divider />
          <ReactQuill
            modules={{ toolbar: false }}
            className="w-full quill-preview"
            readOnly={true}
            theme="snow"
            value={msg?.body}
          />
          <div className="flex flex-wrap flex-row gap-2">
            {msg?.files.map((e) => {
              const extension = e.url.split(".").pop();
              let isImage = ["jpg", "png", "jpeg", "gif", "webp"].includes(
                extension ?? "".toLowerCase()
              );
              return (
                <div
                  className=" aspect-square rounded-lg bg-gray-100 justify-center items-center flex flex-col hover:bg-gray-200 w-16 cursor-pointer"
                  key={e.id}
                  onClick={() => {
                    if (isImage) {
                      setPreview(e.url);
                      setModalPreview(true);
                    } else {
                      window.open(e.url, "_blank");
                    }
                  }}
                >
                  <HiPaperClip size={24} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
  const renderDetail = () => (
    <div className=" col-span-9 h-full border-l p-4  overflow-y-auto ">
      {inbox && msgDetail(inbox, false)}
      <div className="flex flex-row gap-2 mb-4">
        <div className="w-12"></div>
        <div className="flex-1 flex justify-between items-center flex-col  mt-4 pt-4">
          {inbox?.replies.map((e) => msgDetail(e, true))}
        </div>
      </div>
    </div>
  );
  const renderList = () => (
    <div className=" grid grid-cols-12  h-full">
      <div className=" col-span-3 h-full flex-col flex overflow-y-auto">
        <div className="p-4 flex  flex-row justify-between items-center  w-full ">
          {renderHeader()}
          <button
            className="rounded-lg border bg-white flex flex-row gap-2 px-4 py-2 text-xs text-gray-600 hover:bg-gray-100 items-center "
            onClick={() => setModalCompose(true)}
          >
            <VscSend /> Compose
          </button>
        </div>
        <div className="border-t"></div>
        <div className="flex-1 ">
          {inboxes.length == 0 && (
            <div className="flex justify-center items-center h-full">
              <div className=" rounded-full p-8 bg-gray-50">
                <img src="/images/empty.png" alt="" className=" opacity-20" />
              </div>
            </div>
          )}
          <ul>
            {inboxes.map((e) => (
              <li
                key={e.id}
                className="hover:bg-gray-50 border-b last:border-b-0 cursor-pointer"
                onClick={() => {
                  if (e.status == "DRAFT") {
                    setInboxDraft(e);
                    setSubject(e.title);
                    setBody(e.body);
                    setModalCompose(true);
                    return;
                  }
                  if (e.parent_id) {
                    nav(`/inbox/${inboxType ?? "inbox"}/${e.parent_id}`);
                    return;
                  }
                  nav(`/inbox/${inboxType ?? "inbox"}/${e.id}`);
                }}
              >
                <div className="p-2">
                  <div className="flex flex-row justify-between">
                    <div>
                      <div className="flex flex-row justify-between items-center gap-4">
                        <h3 className="font-[500] text-sm">{e.title}</h3>
                      </div>
                      <p className="text-gray-600 text-sm">
                        <strong>to: </strong>
                        <small>
                          {e.recipients
                            .map((e) => `${e.to.full_name} (${e.to.email})`)
                            .join(", ")}
                        </small>
                      </p>
                      {e.files.length > 0 && (
                        <Tag>
                          <div className="flex flex-row items-center justify-center gap-2">
                            <HiPaperClip /> {e.files.length}
                          </div>
                        </Tag>
                      )}
                    </div>
                    <small className="italic text-right text-[8pt] flex flex-col items-end gap-2 justify-center ">
                      {moment(e.created_at).fromNow()}

                      {e.recipients
                        .filter((e) => !e.is_read)
                        .map((e) => e.to_id)
                        .includes(profile!.id) &&
                        (inboxType == "inbox" || inboxType == undefined) && (
                          <div className="w-2 h-2 rounded-full bg-green-500"></div>
                        )}
                    </small>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {renderDetail()}
    </div>
  );
  return (
    <MainLayout
      onLoadProfile={setProfile}
      onLoadPermissions={(val) => {
        // console.log(val)
      }}
    >
      <div
        className="flex flex-col flex-1 overflow-x-auto  h-full"
        style={{ backgroundColor: color }}
      >
        <div
          className="  h-full bg-white ml-4 rounded-tl-[15px]  flex flex-col"
          style={{ height: "calc(100vh - 50px)" }}
        >
          {renderList()}
        </div>
      </div>
      <Drawer
        size={"lg"}
        open={modalCompose}
        onClose={() => {
          setModalCompose(false);
          setSelectedInbox(null);
          setInboxDraft(null);
          setRecipients([]);
        }}
      >
        <Drawer.Header>
          <Drawer.Title>
            {selectedInbox ? "RE: " + selectedInbox?.title : "New Message"}
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{ padding: 0 }}>
          {profile && (
            <ComposeForm
              onSuccess={(val) => {
                if (val) {
                  setInbox(val)
                }
                setModalCompose(false);
                setSubject("");
                setBody(undefined);
                setOriginalFiles([]);
                getAllInboxes();
                if (inboxDraft) {
                  deleteInbox(inboxDraft.id!)
                    .then((res) => res.json())
                    .then((v) => {
                      getAllInboxes();
                      setInboxDraft(null);
                    });
                }
              }}
              selectedInbox={selectedInbox}
              inboxDraft={inboxDraft}
              profile={profile}
            />
          )}
        </Drawer.Body>
      </Drawer>
      <Modal
        size={"lg"}
        open={modalPreview}
        onClose={() => setModalPreview(false)}
      >
        <Modal.Body>
          {modalPreview && <img src={preview} className="w-full rounded" />}
        </Modal.Body>
      </Modal>
    </MainLayout>
  );
};
export default InboxPage;
