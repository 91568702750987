import { useContext, useEffect, useState, type FC } from "react";
import MainLayout from "../components/MainLayout";
import { User } from "../model/user";
import { ProjectContext } from "../objects/project_object";
import { BgColorContext } from "../objects/color_object";
import { Doc } from "../model/doc";
import { useParams } from "react-router-dom";
import { getDoc, updateDoc } from "../repositories/doc";
import { LoadingContext } from "../objects/loading";
import ReactQuill from "react-quill-new";
import { Delta } from "quill/core";
import { errorToast } from "../utils/helper-ui";
import { TfiSave } from "react-icons/tfi";
import { getProject } from "../repositories/project";

interface DocDetailProps {}

const DocDetail: FC<DocDetailProps> = ({}) => {
  const { project: activeProject, setProject: setActiveProject } =
    useContext(ProjectContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [profile, setProfile] = useState<User>();
  const { color, setColor } = useContext(BgColorContext);
  const [mounted, setMounted] = useState(false);
  const [content, setContent] = useState<Delta | null>(null);
  useEffect(() => {
    setMounted(true);
  }, []);

  const [doc, setDoc] = useState<Doc | null>(null);
  const { projectId, docId } = useParams();
  useEffect(() => {
    if (!mounted) return;
    setIsLoading(true);
    getDoc(projectId!, docId!)
      .then((val) => val.json())
      .then((val) => {
        setDoc(val.data);
        setContent(val.data.body);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  }, [mounted, projectId, docId]);

  useEffect(() => {}, [doc]);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const updateDocContent = async () => {
    setIsLoading(true);
    try {
      const res = await updateDoc(projectId!, docId!, {
        title: doc?.title!,
        body: JSON.stringify(content),
        project_id: projectId!,
      });
      const val = await res.json();
      let resProject = await getProject(projectId!);
      let valProject = await resProject.json();
      setActiveProject(valProject.data);
    } catch (err) {
      errorToast(`${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MainLayout>
      <div
        className="flex flex-col flex-1 overflow-x-auto  h-full"
        style={{ backgroundColor: color }}
      >
        <div className="  h-full bg-white ml-4 rounded-tl-[15px]  flex flex-col">
          <div>
            <div className="pt-4 px-4 flex flex-col">
              <div className="flex flex-row items-end justify-between space-x-5 mb-4">
                <div>
                  <h3 className="text-gray-400 text-sm">
                    {activeProject?.title}
                  </h3>
                  <input
                    className="text-2xl font-[500] focus:ring-0 focus:outline-none focus:ring-gray-200 "
                    type="text"
                    value={doc?.title}
                    onChange={(val) =>
                      setDoc({
                        ...doc!,
                        title: val.target.value,
                      })
                    }
                  />
                </div>
                <button
                  className="rounded-lg border bg-white flex flex-row gap-2 px-4 py-2 text-xs text-gray-600 hover:bg-gray-100 items-center"
                  onClick={updateDocContent}
                >
                  <TfiSave /> Save
                </button>
              </div>
              {content && (
                <ReactQuill
                  modules={modules}
                  theme="snow"
                  value={content}
                  onChange={(val, delta, s, editor) => {
                    setContent(editor.getContents());
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default DocDetail;
