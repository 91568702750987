import * as CryptoJS from 'crypto-js';
import { TOKEN, COMPANY_ID, PERMISSIONS, COMPANY, PROJECT, PROFILE } from "../objects/constants";

export const asyncLocalStorage = {
    async setItem(key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, encrypt(value));
        });
    },
    async getItem(key) {
        return Promise.resolve().then(function () {
            let data = localStorage.getItem(key) || "";
            return decrypt(data) || null;
        });
    },
    async removeItem(key) {
        return Promise.resolve().then(function () {
            return localStorage.removeItem(key);
        });
    }
};



export async function clearStorage() {
    await asyncLocalStorage.removeItem(TOKEN);
    await asyncLocalStorage.removeItem(PERMISSIONS);
    await asyncLocalStorage.removeItem(COMPANY);
    await asyncLocalStorage.removeItem(PROJECT);
    await asyncLocalStorage.removeItem(COMPANY_ID);
}
export async function getCompanyStorage() {
    let company = await asyncLocalStorage.getItem(COMPANY)
    if (company) return JSON.parse(company)
    return null;
}
export async function setCompanyStorage(company) {
    return await asyncLocalStorage.setItem(COMPANY, JSON.stringify(company));
}
export async function getProjectStorage() {
    let project = await asyncLocalStorage.getItem(PROJECT)
    if (project) return JSON.parse(project)
    return null;
}
export async function setProjectStorage(project) {
    return await asyncLocalStorage.setItem(PROJECT, JSON.stringify(project));
}
export async function getProfileStorage() {
    let profile = await asyncLocalStorage.getItem(PROFILE)
    if (profile) return JSON.parse(profile)
    return null;
}
export async function setProfileStorage(profile) {
    return await asyncLocalStorage.setItem(PROFILE, JSON.stringify(profile));
}
export async function setCompanyIDStorage(companyID) {
    return await asyncLocalStorage.setItem(COMPANY_ID, companyID);
}

function encrypt(txt) {
    return CryptoJS.AES.encrypt(txt, process.env.REACT_APP_SECRET_KEY).toString();
}

function decrypt(txtToDecrypt) {
    return CryptoJS.AES.decrypt(txtToDecrypt, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
}