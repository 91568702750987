import React from "react"
import { ReactNode } from "react"
import { Message, Tooltip, Whisper, toaster } from "rsuite"
import { TypeAttributes } from "rsuite/esm/internals/types"


export const successToast = (msg: string) => {
    toaster.push(<Message showIcon type={"success"} closable>
        {msg}
    </Message>, { placement: "bottomStart", duration: 3000 })
}
export const setError = (msg: string) => {
    toaster.push(<Message showIcon type={"error"} closable>
        {msg}
    </Message>, { placement: "bottomStart", duration: 3000 })
}
export const errorToast = (msg: string) => {
    if (msg.includes("Duplicate entry")) {
        msg = "Data sudah ada"
    }
    if (msg === "You don't have enough permission") return
    toaster.push(<Message showIcon type={"warning"} closable>
        {msg}
    </Message>, { placement: "bottomStart", duration: 3000 })
}
export const toolTip = (msg: string, children: ReactNode, placement?: TypeAttributes.Placement | "bottomStart") => (<Whisper placement={placement} followCursor speaker={<Tooltip>{msg}</Tooltip>}>
    <div className="flex justify-center">
        {children}
    </div>
</Whisper>)



const mentionPattern = /([@#])\[([^\]]+)\]\(([^)]+)\)/g;



export const parseMentions = (text: string, handleClick: (type: string, id: string) => void): JSX.Element[] => {
  text = text.replaceAll("\n", "<br />")
  const parts = text.split(mentionPattern);

  // Process the parts to create React elements
  const elements: JSX.Element[] = [];
  let i = 0;

  while (i < parts.length) {
    const before = parts[i];
    const prefix = parts[i + 1]; // @ or #
    const name = parts[i + 2];
    const id = parts[i + 3];

    // Add text before mention
    if (before)
    elements.push(<span key={`text-${i}`}>{before.split("<br />").map((t, i) => <React.Fragment key={i}>{t}<br /></React.Fragment>)}</span>);

    // Add mention element based on prefix
    if (prefix && name && id) {
      if (prefix === '@') {
        // Handle @mention for members
        elements.push(
          <a
            key={`${id}-${name}`}
            href="javascript:void(0);"
            onClick={() => handleClick("member", id)}
            className="mention-member"
          >
            @{name}
          </a>
        );
      } else if (prefix === '!') {
        // Handle #mention for clients
        elements.push(
          <a
            key={`${id}-${name}`}
            href="javascript:void(0);"
            onClick={() => handleClick("client", id)}
            className="mention-client"
          >
            #{name}
          </a>
        );
      } else if (prefix === '#') {
        // Handle #mention for clients
        elements.push(
          <a
            key={`${id}-${name}`}
            href="javascript:void(0);"
            onClick={() => handleClick("channel", id)}
            className="mention-client"
          >
            #{name}
          </a>
        );
      }
    }

    i += 4; // Move to the next group of parts
  }

  return elements;
};

