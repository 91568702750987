import React from "react";
import { useState, type FC } from "react";
import { Progress } from "rsuite";
import {
  getTextColorBasedOnBackground,
  hexColorFromProgress,
  invertHexColor,
} from "../utils/helper";
import { addActivityLogProject } from "../repositories/project";
import { Member } from "../model/member";
import { User } from "../model/user";

interface StatusSliderProps {
  percentage: number;
  onChange: (val: number) => void;
  companyId: string;
  boardId: string;
  projectId: string;
  taskId: string;
  profile: User;
  ws?: WebSocket | null
  readonly?: boolean
}

const StatusSlider: FC<StatusSliderProps> = ({
  percentage,
  onChange,
  companyId,
  projectId,
  boardId,
  profile,
  taskId,
  ws,
  readonly = false

}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [temp, setTemp] = useState(0);

  return (
    <>
      {showEdit ? (
        <input
          type="number"
          className="w-full p-4"
          value={percentage}
          onChange={(e) => {
            let val = parseInt(e.target.value);
            if (val > 100) val = 100;
            onChange(val);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onChange(percentage);
              addActivityLogProject({
                company_id: companyId,
                project_id: projectId,
                board_id: boardId!,
                task_id: taskId,
                activity_type: "UPDATE_PROGRESS",
                context_type: "TASK",
                pre_condition: JSON.stringify({
                  progress: temp,
                }),
                data: JSON.stringify({
                  progress: percentage,
                }),
              });
              if (ws) {
                ws.send(
                  JSON.stringify({
                    type: "UPDATE",
                    action: "UPDATE_PROGRESS",
                    targetId: taskId,
                    targetType: "TASK",
                    userId: profile?.id,
                  })
                );
              }
              setShowEdit(false);
            }
          }}
          style={{ WebkitAppearance: "none", textAlign: "right" }}
        />
      ) : (
        <div
          className="w-full relative"
          onClick={() => {
            if (readonly) return
            if (percentage == 100) return
            setShowEdit(true);
            setTemp(percentage);
          }}
        >
          <Progress.Line
            percent={percentage}
            strokeColor={hexColorFromProgress(percentage)}
            strokeWidth={20}
            showInfo={false}
          />
          <div className="absolute w-full top-0 left-0 right-0 bottom-0 flex justify-center items-center">
            <p
              className="text-xs"
              style={{
                color: invertHexColor(hexColorFromProgress(percentage)),
              }}
            >
              {percentage}%
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export default StatusSlider;
