import { Doc, DocReq } from "../model/doc";
import { customFetch } from "../utils/http";
import { PaginationReq } from "../objects/pagination";

export const createDoc = async (projectId: string, doc: DocReq) => {
  return await customFetch(`project/${projectId}/doc`, {
    method: "POST",
    body: JSON.stringify(doc),
  });
};

export const getDocs = async (projectId: string, pagination: PaginationReq) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };
  if (pagination.search && pagination.search != "") {
    params["search"] = pagination.search;
  }
  return await customFetch(
    `project/${projectId}/doc?${new URLSearchParams(params)}`
  );
};
export const getDoc = async (projectId: string, id: string) => {
  return await customFetch(`project/${projectId}/doc/${id}`);
};

export const updateDoc = async (projectId: string, id: string, doc: DocReq) => {
  return await customFetch(`project/${projectId}/doc/${id}`, {
    method: "PUT",
    body: JSON.stringify(doc),
  });
};
