import { useEffect, useState, type FC } from "react";
import { Form, FormField, FormFieldType, FormSection } from "../model/form";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  DateRangePicker,
  Input,
  Radio,
  RadioGroup,
  SelectPicker,
  Toggle,
} from "rsuite";
import {
  FormFieldResponseReq,
  FormResponseReq,
  FormSectionResponseReq,
} from "../model/form_response";
import moment from "moment";
import { convertFileBase64 } from "../utils/helper";
import { savePublicForm } from "../repositories/public";
import { errorToast, successToast } from "../utils/helper-ui";

interface FormPreviewProps {
  form: Form;
  showSubmit: boolean;
}

const FormPreview: FC<FormPreviewProps> = ({ form, showSubmit }) => {
  const [response, setResponse] = useState<FormResponseReq | null>(null);
  const [sections, setSections] = useState<FormSectionResponseReq[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (form) {
      setSections(
        form.sections.map((e) => {
          return {
            id: e.id,
            fields: e.fields.map((f) => {
              let value: any = f.is_multi ? [] : "";
              if (f.type == FormFieldType.ToggleSwitch) {
                value = false;
              }
              if (f.type == FormFieldType.Dropdown) {
                value = f.default_value;
              }
              return {
                id: f.id,
                isMulti: f.is_multi,
                value,
                type: f.type,
              };
            }),
          };
        })
      );
    }
  }, [form]);

  const updateValue = (
    sectionIndex: number,
    index: number,
    val: any,
    filename?: string | null
  ) => {
    setSections({
      ...sections,
      [sectionIndex]: {
        ...sections[sectionIndex],
        fields: [
          ...sections[sectionIndex].fields.slice(0, index),
          {
            ...sections[sectionIndex].fields[index],
            value: val,
            filename: filename,
          },
          ...sections[sectionIndex].fields.slice(index + 1),
        ],
      },
    });
  };
  const renderField = (f: FormField, sectionIndex: number, index: number) => {
    switch (f.type) {
      case FormFieldType.TextField:
        return (
          <div>
            <Input
              placeholder={f.placeholder}
              required={f.required}
              value={sections[sectionIndex].fields[index].value as string}
              onChange={(val) => {
                updateValue(sectionIndex, index, val);
              }}
            />
          </div>
        );
      case FormFieldType.TextArea:
        return (
          <div>
            <Input
              as="textarea"
              rows={3}
              placeholder={f.placeholder}
              required={f.required}
              value={sections[sectionIndex].fields[index].value as string}
              onChange={(val) => {
                updateValue(sectionIndex, index, val);
              }}
            />
          </div>
        );
      case FormFieldType.RadioButton:
        return (
          <div>
            <RadioGroup
              value={sections[sectionIndex].fields[index].value}
              name="radio-group"
              defaultValue="A"
            >
              {f.options.map((e) => (
                <Radio
                  key={e.value}
                  value={e.value}
                  onClick={(val) => {
                    updateValue(sectionIndex, index, e.value);
                  }}
                >
                  {e.label}
                </Radio>
              ))}
            </RadioGroup>
          </div>
        );
      case FormFieldType.Checkbox:
        return (
          <div>
            <CheckboxGroup
              value={sections[sectionIndex].fields[index].value as string[]}
              name="checkbox-group"
            >
              {f.options.map((e) => (
                <Checkbox
                  key={e.value}
                  value={e.value}
                  onChange={(val, checked) => {
                    let checkboxVal = sections[sectionIndex].fields[index]
                      .value as string[];
                    if (checkboxVal.includes(e.value)) {
                      checkboxVal = checkboxVal.filter((v) => v !== e.value);
                    } else {
                      checkboxVal = [...checkboxVal, e.value];
                    }
                    // console.log(checkboxVal, checked)
                    updateValue(sectionIndex, index, checkboxVal);
                  }}
                >
                  {e.label}
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>
        );
      case FormFieldType.DatePicker:
        return (
          <div>
            <DatePicker
              format="dd MMM yyyy"
              value={moment(
                sections[sectionIndex].fields[index].value as string
              ).toDate()}
              onChange={(val) => {
                updateValue(sectionIndex, index, val?.toISOString());
              }}
            />
          </div>
        );
      case FormFieldType.DateRangePicker:
        let dateValue: [Date, Date] = [moment().toDate(), moment().toDate()];
        if (
          (sections[sectionIndex].fields[index].value as string[]).length == 2
        ) {
          dateValue = (
            sections[sectionIndex].fields[index].value as string[]
          ).map((e) => (e ? moment(e).toDate() : null)) as [Date, Date];
        }
        return (
          <div>
            <DateRangePicker
              format="dd MMM yyyy"
              value={dateValue}
              onChange={(val) => {
                updateValue(
                  sectionIndex,
                  index,
                  val?.map((e) => e.toISOString())
                );
              }}
            />
          </div>
        );
      case FormFieldType.NumberField:
        return (
          <div>
            <Input
              required={f.required}
              placeholder={f.placeholder}
              type="number"
              value={sections[sectionIndex].fields[index].value as number}
              onChange={(val) => {
                updateValue(sectionIndex, index, val);
              }}
            />
          </div>
        );
      case FormFieldType.EmailField:
        return (
          <div>
            <Input
              required={f.required}
              placeholder={f.placeholder}
              type="email"
              value={sections[sectionIndex].fields[index].value as string}
              onChange={(val) => {
                updateValue(sectionIndex, index, val);
              }}
            />
          </div>
        );
      case FormFieldType.PasswordField:
        return (
          <div>
            <Input
              required={f.required}
              placeholder={f.placeholder}
              type="password"
              value={sections[sectionIndex].fields[index].value as string}
              onChange={(val) => {
                updateValue(sectionIndex, index, val);
              }}
            />
          </div>
        );
      case FormFieldType.FileUpload:
        return (
          <div>
            <input
              className="
              w-full
              border
              border-gray-300
              rounded-md
              shadow-sm
              p-2
            "
              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xlx,.xlsx,.ppt,.pptx"
              required={f.required}
              placeholder={f.placeholder}
              type="file"
              onChange={(ev) => {
                console.log(ev.target.files);
                if (ev.target.files) {
                  convertFileBase64(ev.target.files[0])
                    .then((val) => {
                      // console.log(val);
                      updateValue(
                        sectionIndex,
                        index,
                        val,
                        ev.target.files![0].name
                      );
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }}
            />
          </div>
        );
      case FormFieldType.ToggleSwitch:
        return (
          <div>
            <Toggle
              checked={sections[sectionIndex].fields[index].value as boolean}
              onChange={(val) => {
                updateValue(sectionIndex, index, val);
              }}
            >
              {f.label}
            </Toggle>
          </div>
        );
      case FormFieldType.Dropdown:
        return (
          <div>
            <SelectPicker
              value={sections[sectionIndex].fields[index].value as string}
              placeholder={f.placeholder ?? "Select"}
              style={{ minWidth: 300 }}
              searchable={false}
              data={f.options}
              onChange={(val) => {
                updateValue(sectionIndex, index, val);
              }}
            />
          </div>
        );
    }
    return null;
  };
  if (!form) return null;
  if (sections.length == 0) return null;

  return (
    <div className=" ">
      <div className="p-4 bg-gray-50 rounded-lg">
        {form.sections.map((s, sectionIndex) => (
          <div className="mb-4 border-b pb-4 last:border-b-0" key={s.id}>
            <div className="mb-2">
              <h1 className="text-2xl font-[500]">{s.section_title}</h1>
              <h3>{s.description}</h3>
            </div>
            <div>
              {s.fields.map((f, i) => (
                <div
                  key={f.id}
                  className="bg-white rounded-lg p-4 w-full h-min-[100px] mb-2 hover:border"
                >
                  <h3 className="text-lg mb-2">
                    {f.required && (
                      <span className="text-red-400 mr-2">{"*"}</span>
                    )}
                    {f.label}
                  </h3>
                  {renderField(f, sectionIndex, i)}
                  {f.help_text && (
                    <small className="italic">{f.help_text}</small>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {showSubmit && (
        <div className="flex flex-row justify-center items-center my-8">
          <Button
            appearance="ghost"
            className="w-32"
            onClick={async () => {
              const newSections = Object.keys(sections).map((key) => {
                const section = sections[key as any];
                return {
                  id: section.id,
                  fields: section.fields.map((field) => ({
                    id: field.id,
                    isMulti: field.isMulti,
                    value: field.value,
                    type: field.type,
                    filename: field.filename,
                  })),
                };
              });

              for (const s of newSections) {
                for (const f of s.fields) {
                  let field = form.sections.map((e) =>
                    e.fields.find((e) => e.id == f.id)
                  )![0];
                  if (field?.required) {
                    if (!f.value) {
                      errorToast(`${field.label} is required`);
                      return;
                    }
                  }
                }
              }

              setIsLoading(true);
              try {
                await savePublicForm(form!.code, {
                  sections: newSections,
                });
                successToast("Form Submitted");
                if (window.parent !== window) {
                  window.parent.postMessage(
                    JSON.stringify({
                      type: "FORM_SUBMITTED",
                      data: {
                        formCode: form!.code,
                      },
                    }),
                    "*"
                  );
                }
              } catch (error) {
                console.log(error);
                errorToast("Failed to submit form");
              } finally {
                setIsLoading(false);
              }
            }}
          >
            Submit Form
          </Button>
        </div>
      )}
    </div>
  );
};
export default FormPreview;
