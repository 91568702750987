import type { FC } from "react";
import React from "react";
import { Color, ColorResult, SwatchesPicker } from "react-color";
import { Modal } from "rsuite";

interface ModalColorProps {
  open: boolean;
  onClose: () => void;
  color: Color;
  onChange: (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const ModalColor: FC<ModalColorProps> = ({
  open,
  onClose,
  color,
  onChange,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Body>
        <SwatchesPicker color={color} onChange={onChange} />
      </Modal.Body>
    </Modal>
  );
};
export default ModalColor;
