import React from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
import { Toggle } from "rsuite";
type ViewSwitcherProps = {
  isChecked: boolean;
  viewMode: ViewMode;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
};
export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
  viewMode
}) => {
  return (
    <div className="flex flex-row justify-end mb-8 items-center">
      <button
        className={`px-4 py-2 min-w-[60px] ${viewMode == ViewMode.Hour ? 'bg-gray-500 text-white' : 'bg-gray-50'} mx-2 rounded-md text-xs`}

        onClick={() => onViewModeChange(ViewMode.Hour)}
      >
        Hour
      </button>
      <button
        className={`px-4 py-2 min-w-[60px] ${viewMode == ViewMode.QuarterDay ? 'bg-gray-500 text-white' : 'bg-gray-50'} mx-2 rounded-md text-xs`}
        onClick={() => onViewModeChange(ViewMode.QuarterDay)}
      >
        Quarter of Day
      </button>
      <button
        className={`px-4 py-2 min-w-[60px] ${viewMode == ViewMode.HalfDay ? 'bg-gray-500 text-white' : 'bg-gray-50'} mx-2 rounded-md text-xs`}
        onClick={() => onViewModeChange(ViewMode.HalfDay)}
      >
        Half of Day
      </button>
      <button className={`px-4 py-2 min-w-[60px] ${viewMode == ViewMode.Day ? 'bg-gray-500 text-white' : 'bg-gray-50'} mx-2 rounded-md text-xs`} onClick={() => onViewModeChange(ViewMode.Day)}>
        Day
      </button>
      <button
        className={`px-4 py-2 min-w-[60px] ${viewMode == ViewMode.Week ? 'bg-gray-500 text-white' : 'bg-gray-50'} mx-2 rounded-md text-xs`}
        onClick={() => onViewModeChange(ViewMode.Week)}
      >
        Week
      </button>
      <button
        className={`px-4 py-2 min-w-[60px] ${viewMode == ViewMode.Month ? 'bg-gray-500 text-white' : 'bg-gray-50'} mx-2 rounded-md text-xs`}
        onClick={() => onViewModeChange(ViewMode.Month)}
      >
        Month
      </button>
      <button
        className={`px-4 py-2 min-w-[60px] ${viewMode == ViewMode.Year ? 'bg-gray-500 text-white' : 'bg-gray-50'} mx-2 rounded-md text-xs`}
        onClick={() => onViewModeChange(ViewMode.Year)}
      >
        Year
      </button>
      
      <div className="Switch">
        <Toggle
        unCheckedChildren="Show Task List"
         checked={isChecked} onChange={(checked, _) => {
            onViewListChange(!isChecked)
        
        }}
        />
        
      </div>
    </div>
  );
};