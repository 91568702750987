import { DropBox, DropBoxReq, DropBoxItemReq } from "../model/drop_box";
import { customFetch } from "../utils/http";
import { PaginationReq } from "../objects/pagination";
import { CommentReq } from "../model/comment";

export const createDropBox = async (projectId: string, dropBox: DropBoxReq) => {
  return await customFetch(`project/${projectId}/dropBox`, {
    method: "POST",
    body: JSON.stringify(dropBox),
  });
};

export const getDropBoxs = async (projectId: string, pagination: PaginationReq) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };
  if (pagination.search && pagination.search != "") {
    params["search"] = pagination.search;
  }
  return await customFetch(
    `project/${projectId}/dropBox?${new URLSearchParams(params)}`
  );
};
export const getDropBox = async (projectId: string, id: string) => {
  return await customFetch(`project/${projectId}/dropBox/${id}`);
};

export const updateDropBox = async (projectId: string, id: string, dropBox: DropBoxReq) => {
  return await customFetch(`project/${projectId}/dropBox/${id}`, {
    method: "PUT",
    body: JSON.stringify(dropBox),
  });
};
export const addItemDropBox = async (projectId: string, id: string, dropBoxItem: DropBoxItemReq) => {
  return await customFetch(`project/${projectId}/dropBox/${id}/addItem`, {
    method: "PUT",
    body: JSON.stringify(dropBoxItem),
  });
};
export const addCommentItemDropBox = async (projectId: string, id: string, dropBoxItemId: string, comment: CommentReq) => {
  return await customFetch(`project/${projectId}/dropBox/${id}/item/${dropBoxItemId}/addComment`, {
    method: "PUT",
    body: JSON.stringify(comment),
  });
};
export const getDropBoxItem = async (projectId: string, id: string, dropBoxItemId: string) => {
  return await customFetch(`project/${projectId}/dropBox/${id}/item/${dropBoxItemId}`, {
    method: "GET",
  });
};
