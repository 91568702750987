import { ActivityLogReq, Project, ProjectReq } from "../model/project";
import { customFetch } from "../utils/http";
import { PaginationReq } from "../objects/pagination";

export const createProject = async (project: ProjectReq) => {
    return await customFetch(`project`, {
        method: "POST",
        body: JSON.stringify(project),
    });
    
};

export const getProjects = async (pagination: PaginationReq) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }
    if (pagination.project_id ) {
        params["project_id"] = pagination.project_id
    }
    return await customFetch(`project?${new URLSearchParams(params)}`);
    
};
export const getProject = async (id: string) => {
    return await customFetch(`project/${id}`);
    
};
export const addActivityLogProject = async (req: ActivityLogReq) => {
    return await customFetch(`project/activity`, {
        method: "PUT",
        body: JSON.stringify(req),
    });
    
};

export const updateProject = async (id: string, project: ProjectReq) => {
    return await customFetch(`project/${id}`, {
        method: "PUT",
        body: JSON.stringify(project),
    });
    
};
export const addTeam = async (id: string, team_id: string) => {
    return await customFetch(`project/${id}/addTeam`, {
        method: "PUT",
        body: JSON.stringify({team_id}),
    });
    
};
export const addMember = async (id: string, member_ids: string[]) => {
    return await customFetch(`project/${id}/addMember`, {
        method: "PUT",
        body: JSON.stringify({member_ids}),
    });
    
};
export const addClient = async (id: string, client_ids: string[]) => {
    return await customFetch(`project/${id}/addClient`, {
        method: "PUT",
        body: JSON.stringify({client_ids}),
    });
    
};

export const deleteProject = async (id: string): Promise<void> => {
    await customFetch(`project/${id}`, {
        method: "DELETE",
    });
};
