import type { FC } from "react";
import { useContext, useEffect, useRef, useState } from "react";
import MainLayout from "../components/MainLayout";
import ProjectBoard from "../components/ProjectBoard";
import { ProfileReq, User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { ProjectContext } from "../objects/project_object";
import { Avatar } from "rsuite";
import { initials } from "../utils/helper";
import { uploadFile } from "../repositories/file";
import { LoadingContext } from "../objects/loading";
import { FileResp } from "../model/file";
import { errorToast, successToast } from "../utils/helper-ui";
import {
  getProfile,
  updateAvatar,
  updateCover,
  updatePassword,
  updateProfile,
} from "../repositories/my";
import { setProfileStorage } from "../utils/storage";

interface ProfilePageProps {}

const ProfilePage: FC<ProfilePageProps> = ({}) => {
  const { project: activeProject, setProject: setActiveProject } =
    useContext(ProjectContext);
  const [profile, setProfile] = useState<User>();
  const { color, setColor } = useContext(BgColorContext);
  const [password, setPassword] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const fileRef2 = useRef<HTMLInputElement>(null);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [profilePic, setProfilePic] = useState("");
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [coverPic, setCoverPic] = useState("");
  const [coverPicUrl, setCoverPicUrl] = useState("");
  const [mounted, setMounted] = useState(false);

  const handleUpdateProfile = (e: ProfileReq) => {
    setIsLoading(true);
    updateProfile(e)
      .then(() => {
        successToast("Profile Updated");
        getProfile()
          .then((res) => res.json())
          .then((res) => {
            setProfileStorage(res.data);
          });
      })
      .catch(errorToast)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getProfile()
      .then((res) => res.json())
      .then((res) => {
        setProfile(res.data);
      });
  }, [mounted]);

  useEffect(() => {
    setProfilePic(profile?.picture ?? "");
    setProfilePicUrl(profile?.picture_url ?? "");
    setCoverPic(profile?.cover ?? "");
    setCoverPicUrl(profile?.cover_url ?? "");
  }, [profile]);

  const renderProfile = () => (
    <div className="w-full mx-auto ">
      {/* Banner */}
      <div className="h-[200px] relative">
        <div className=" bg-gray-100 rounded-t-lg overflow-hidden ">
          <img
            src={
              coverPicUrl != ""
                ? coverPicUrl
                : "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
            alt="Store Banner"
            className="w-full h-40 object-cover object-center cursor-pointer"
            onClick={() => fileRef2?.current?.click()}
          />
          <div className="absolute bottom-0 left-6">
            <Avatar
              size="xl"
              src={profilePicUrl}
              alt={initials(profile?.full_name)}
              color="violet"
              circle
              className="w-24 h-24 cursor-pointer border-4 shadow-md border-white"
              onClick={() => fileRef.current?.click()}
            />
          </div>
        </div>
      </div>

      {/* Form Section */}
      <div className="bg-white  p-8 space-y-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          Account Setting
        </h2>
        <p className="text-gray-500">Personalize Your Preferences</p>

        {/* Store Name */}
        <div>
          <label htmlFor="storeName" className="block text-gray-700">
            Full Name
          </label>
          <input
            id="storeName"
            name="storeName"
            type="text"
            className="mt-2 w-full border border-gray-300 rounded-lg p-2"
            value={profile?.full_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setProfile({ ...profile!, full_name: e.target.value })
            }
            onBlur={(e) => {
              setIsLoading(true);
              handleUpdateProfile({ full_name: e.currentTarget.value });
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleUpdateProfile({ full_name: e.currentTarget.value });
              }
            }}
          />
        </div>

        {/* Email */}
        <div>
          <label htmlFor="email" className="block text-gray-700">
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            className="mt-2 w-full border border-gray-300 rounded-lg p-2"
            value={profile?.email}
            readOnly
          />
        </div>

        {/* Password */}
        <div>
          <label htmlFor="password" className="block text-gray-700">
            Password
          </label>
          <input
            id="password"
            name="password"
            type="password"
            className="mt-2 w-full border border-gray-300 rounded-lg p-2"
            value={password}
            onChange={(val) => setPassword(val.target.value)}
            placeholder="* * * * * * *"
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-gray-100 text-white px-6 py-2 rounded-lg hover:bg-yellow-700"
            style={{ backgroundColor: color }}
            onClick={() => {
              setIsLoading(true);
              updatePassword(password)
                .then(() => {
                  successToast("Password Updated");
                })
                .catch(errorToast)
                .finally(() => setIsLoading(false));
            }}
          >
            Update Password
          </button>
        </div>
      </div>
      <input
        type="file"
        accept="image/*"
        ref={fileRef}
        className="hidden"
        onChange={async (val) => {
          try {
            setIsLoading(true);
            var resp = await handleUpload(val);
            setProfilePicUrl(resp.data.url);
            await updateAvatar(resp.data.path);
            getProfile()
              .then((res) => res.json())
              .then((res) => {
                setProfileStorage(res.data);
              });
          } catch (error) {
            errorToast(`${error}`);
          } finally {
            setIsLoading(false);
          }
        }}
      />
      <input
        type="file"
        accept="image/*"
        ref={fileRef2}
        className="hidden"
        onChange={async (val) => {
          try {
            setIsLoading(true);
            var resp = await handleUpload(val);
            setCoverPicUrl(resp.data.url);
            await updateCover(resp.data.path);
            getProfile()
              .then((res) => res.json())
              .then((res) => {
                setProfileStorage(res.data);
              });
          } catch (error) {
            errorToast(`${error}`);
          } finally {
            setIsLoading(false);
          }
        }}
      />
    </div>
  );

  const handleUpload = async (val: any) => {
    if (!val?.target) return null;
    const input = val.target as HTMLInputElement;

    setIsLoading(true);
    try {
      const resp = await uploadFile(input?.files![0], (progress) => {
        console.log(progress);
      });
      return await resp.json();
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <MainLayout>
      <div
        className="flex flex-col flex-1 overflow-x-auto  h-full"
        style={{ backgroundColor: color }}
      >
        <div
          className="  h-full bg-white ml-4 rounded-tl-[15px]  flex flex-col"
          style={{ height: "calc(100vh - 50px)" }}
        >
          {renderProfile()}
        </div>
      </div>
    </MainLayout>
  );
};
export default ProfilePage;
