import { InboxReq } from "../model/inbox";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/http";

export const createInbox = async (inbox: InboxReq) => {
  return await customFetch("inbox", {
    method: "POST",
    body: JSON.stringify(inbox),
  });
};

export const getInboxes = async (pagination: PaginationReq) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };
  if (pagination.search && pagination.search != "") {
    params["search"] = pagination.search;
  }
  params["type"] = pagination.type ?? "";
  return await customFetch(`inbox?${new URLSearchParams(params)}`);
};

export const countInbox = async () => {
  return await customFetch(`inbox/count`);
};
export const getInbox = async (id: string) => {
  return await customFetch(`inbox/${id}`);
};
export const inboxFavorite = async (id: string) => {
  return await customFetch(`inbox/${id}/favorite`, {
    method: "PUT",
  });
};
export const inboxUnFavorite = async (id: string) => {
  return await customFetch(`inbox/${id}/unfavorite`, {
    method: "PUT",
  });
};

export const deleteInbox = async (id: string) => {
  return await customFetch(`inbox/${id}`, {
    method: "DELETE",
  });
};
