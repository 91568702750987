import { useState, type FC } from "react";
import CustomAvatar from "./CustomAvatar";
import { Mention, MentionsInput } from "react-mentions";
import { initials } from "../utils/helper";
import { User } from "../model/user";
import { CommentReq, MentionReq } from "../model/comment";
import { Button } from "rsuite";
import { Member } from "../model/member";
import { Client } from "../model/client";
import { Channel } from "../model/channel";

interface CommentBoxProps {
  profile?: User | null;
  members: Member[];
  clients: Client[];
  channels: Channel[];
  onSend: (val: CommentReq) => void;
}

const CommentBox: FC<CommentBoxProps> = ({
  profile,
  members,
  clients,
  channels,
  onSend,
}) => {
  const [comment, setComment] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const [mentions, setMentions] = useState<MentionReq[]>([]);
  const [CommentReq, setCommentReq] = useState<CommentReq | null>(null);
  const styles: any = {
    control: {
      backgroundColor: "#fff",
      fontSize: 14,
      fontWeight: "normal",
      height: isFocus ? 60 : 40,
    },

    "&multiLine": {
      control: {},
      highlighter: {
        padding: 9,
      },
      input: {
        padding: 9,
        outline: "none",
      },
    },

    "&singleLine": {
      display: "inline-block",
      width: 180,

      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 1,
        border: "2px inset",
      },
    },

    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        textAlign: "left",
        "&focused": {
          backgroundColor: "#ccc0e1",
        },
      },
    },
  };

  return (
    <div
      className={`h-12 -mx-4  mt-4 border-t ${
        isFocus ? "comment-wrapper-active" : "comment-wrapper"
      }`}
    >
      <div className="flex  p-4  rounded-md gap-2">
        <div className="py-2">
          <CustomAvatar
            src={profile?.picture_url}
            alt={(profile?.full_name)}
          />
        </div>
        <div className=" w-full space-y-4 border rounded-lg focus:ring-0 focus:outline-none focus:ring-gray-200 text-center p-2">
          <MentionsInput
            placeholder="Type a comment, mention members with @ or # for clients ... "
            onFocus={() => setIsFocus(true)}
            // onBlur={() => setIsFocus(false)}
            value={comment}
            onChange={(val, _, __, mentions) => {
              setComment(val.target.value);

              let mentionReqs: MentionReq[] = [];
              for (let index = 0; index < mentions.length; index++) {
                const m = mentions[index];
                let mention: MentionReq = {
                  data: JSON.stringify(m),
                };
                // console.log(comment[m.index])
                if (comment[m.index] == "@") {
                  mention.member_id = m.id;
                }
                if (comment[m.index] == "!") {
                  mention.client_id = m.id;
                }
                if (comment[m.index] == "#") {
                  mention.channel_id = m.id;
                }
                mentionReqs.push(mention);
              }
              // console.log(mentionReqs);
              setMentions(mentionReqs);
            }}
            style={styles}
          >
            <Mention
              trigger="@"
              data={members.map((e) => ({
                id: e.id,
                display: e.user?.full_name,
              }))}
              style={{
                backgroundColor: "#ccc0e1",
              }}
            />
            <Mention
              trigger="!"
              data={clients.map((e) => ({
                id: e.id,
                display: e.user?.full_name,
              }))}
              style={{
                backgroundColor: "#ffff00",
              }}
            />
            <Mention
              trigger="#"
              data={channels.map((e) => ({
                id: e.id,
                display: e.name,
              }))}
              style={{
                backgroundColor: "#32cd32",
              }}
            />
          </MentionsInput>
          {isFocus && (
            <div className="flex flex-row justify-end px-2 pb-2 mt-2">
              <div>
                <Button
                  onClick={() => {
                    setIsFocus(false);
                  }}
                  className="text-sm mr-2"
                  appearance="subtle"
                >
                  Batal
                </Button>
                <Button
                  onClick={() => {
                    let commentReq: CommentReq = {
                      title: "",
                      message: comment,
                      member_id: profile?.member?.id,
                      client_id: profile?.client?.id,
                      mentions: mentions,
                    };
                    //   console.log(commentReq)
                    onSend(commentReq);
                    setIsFocus(false);
                    setTimeout(() => {
                        setComment("")
                        setMentions([])
                    }, 300);
                  }}
                  className="text-sm"
                  appearance="primary"
                  color="violet"
                >
                  Kirim Komentar
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default CommentBox;
