import { useContext, useEffect, useState, type FC } from "react";
import ProjectBoard from "../components/ProjectBoard";
import { BgColorContext } from "../objects/color_object";
import MainLayout from "../components/MainLayout";
import { PermissionsContext } from "../objects/permissions_object";
import moment from "moment";
import { getTextColorBasedOnBackground, initials } from "../utils/helper";
import { getProjects } from "../repositories/project";
import { Project } from "../model/project";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, AvatarGroup, Button, Divider, Modal, Tag } from "rsuite";
import { User } from "../model/user";
import { getMyTaskDashboard } from "../repositories/dashboard";
import { Task } from "../model/board";
import { getCompany } from "../repositories/company";
import { setCompanyStorage } from "../utils/storage";
import { CompanyContext } from "../objects/company_context";
import { ProjectContext } from "../objects/project_object";
import { getInboxes } from "../repositories/inbox";
import { Inbox } from "../model/inbox";
import { HiOutlineInbox, HiPaperClip } from "react-icons/hi2";
import { VscSend } from "react-icons/vsc";
import ComposeForm from "../components/ComposeForm";
import { Company } from "../model/company";
import { getCompanies } from "../repositories/my";
import ModalCompany from "../components/ModalCompany";

interface HomeProps {}

const Home: FC<HomeProps> = ({}) => {
  const { company, setCompany } = useContext(CompanyContext);
  const { project, setProject } = useContext(ProjectContext);
  const { color, setColor } = useContext(BgColorContext);
  const { permissions, setPermissions } = useContext(PermissionsContext);
  const [projects, setProjects] = useState<Project[]>([]);
  const [mounted, setMounted] = useState(false);
  const nav = useNavigate();
  const [profile, setProfile] = useState<User>();
  const [taskMode, setTaskMode] = useState("upcoming");
  const [myTasks, setMyTasks] = useState<Task[]>([]);
  const [myTaskGivens, setMyTaskGivens] = useState<Task[]>([]);
  const [inboxes, setInboxes] = useState<Inbox[]>([]);
  const [modalCompose, setModalCompose] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [modalCompanyOpen, setModalCompanyOpen] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getAllProjects();

    getMyTaskDashboard("0", taskMode, 9)
      .then((res) => res.json())
      .then((v) => {
        setMyTaskGivens(v.data);
      });

    getAllInboxes();
    getCompanies()
      .then((res) => res.json())
      .then((v) => {
        setCompanies(v.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [mounted]);

  const getAllInboxes = () => {
    getInboxes({ page: 1, limit: 20, search: "", type: "inbox" })
      .then((res) => res.json())
      .then((v) => {
        setInboxes(v.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getMyTaskDashboard("1", taskMode)
      .then((res) => res.json())
      .then((v) => {
        setMyTasks(v.data);
      });
  }, [taskMode]);

  const getAllProjects = () => {
    getProjects({ page: 1, limit: 5, search: "" })
      .then((res) => res.json())
      .then((v) => {
        setProjects(v.data);
      });
  };

  return (
    <MainLayout onLoadProfile={setProfile} onLoadPermissions={setPermissions}>
      <div
        className="flex flex-col flex-1 overflow-x-auto  h-full"
        style={{
          backgroundColor: color,
          height: "calc(100vh - 50px)",
        }}
      >
        <div className="p-6 flex flex-col h-full flex-1">
          {/* Top Section */}
          <div className="flex justify-center items-center mb-6 flex-col">
            <h1
              className="text-4xl font-bold"
              style={{
                color: getTextColorBasedOnBackground(color),
              }}
            >
              Hello, Rahmat
            </h1>
            <h4
              style={{
                color: getTextColorBasedOnBackground(color),
              }}
            >
              {moment().format("DD MMMM YYYY")}
            </h4>
          </div>

          {/* Grid Layout */}
          <div className="grid grid-cols-4 grid-rows-2 gap-6 auto-rows-min h-full  flex-1 pb-10">
            {/* Task Section */}
            <div className="col-span-2 row-span-1 bg-white rounded-lg shadow-black hover:shadow-xl  overflow-y-auto">
              <div className="flex flex-row gap-2 items-center px-4 pt-4 justify-start">
                <div className="w-[36px] mr-2 ">
                  <Avatar
                    src={profile?.picture_url}
                    circle
                    alt={initials(profile?.full_name)}
                  />
                </div>
                <div className="flex-1">
                  <h2 className="text-lg font-semibold mb-4">My Tasks</h2>
                  <div className="flex flex-row">
                    <div
                      onClick={() => {
                        setTaskMode("upcoming");
                      }}
                      style={{
                        borderBottomColor: taskMode == "upcoming" ? color : "",
                      }}
                      className="cursor-pointer pr-6 text-sm pb-2 border-b-2"
                    >
                      Upcoming
                    </div>
                    <div
                      onClick={() => {
                        setTaskMode("overdue");
                      }}
                      style={{
                        borderBottomColor: taskMode == "overdue" ? color : "",
                      }}
                      className="cursor-pointer pr-6 text-sm pb-2 border-b-2"
                    >
                      Overdue
                    </div>
                    <div
                      onClick={() => {
                        setTaskMode("done");
                      }}
                      style={{
                        borderBottomColor: taskMode == "done" ? color : "",
                      }}
                      className="cursor-pointer pr-6 text-sm pb-2 border-b-2"
                    >
                      Done
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-4 border-t min-h-[240px]">
                <ul className="">
                  {myTasks.map((e) => (
                    <li
                      className="py-2 flex justify-between border-b last:border-b-0 hover:font-[500] cursor-pointer"
                      key={e.id}
                      onClick={async () => {
                        let company = await getCompany(
                          e.column?.board?.project?.company_id!
                        );
                        let companyJson = await company.json();
                        setCompany(companyJson.data);
                        setProject(e?.column?.board?.project ?? null);

                        let url = `/project/${e.column?.board?.project?.id}/board/${e.column?.board_id}?task_id=${e.id}`;
                        nav(url);
                      }}
                    >
                      <span className="">{e.title}</span>
                      {e.due_date && (
                        <span
                          className="bg-gray-200 text-xs text-gray-600 px-2 py-1 rounded-full "
                          style={{
                            backgroundColor:
                              taskMode == "overdue" ? "#e53869" : color,
                            color: getTextColorBasedOnBackground(
                              taskMode == "overdue" ? "#e53869" : color
                            ),
                          }}
                        >
                          {moment(e.due_date).format("DD MMM")}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Project Section */}
            <div className="xl:col-span-1 col-span-2 row-span-1 bg-white rounded-lg shadow-black hover:shadow-xl p-4 flex flex-col overflow-y-auto">
              <h2 className="text-lg font-semibold">Projects</h2>
              <div className="mt-4 flex-1 ">
                <ul className="grid grid-cols-2 gap-2">
                  {projects.map((e) => (
                    <li
                      className="py-2 flex flex-row gap-2 hover:bg-gray-100 cursor-pointer p-2 rounded-lg"
                      key={e.id}
                      onClick={() => nav(`/project/${e.id}`)}
                    >
                      <div
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      >
                        <Avatar
                          src={e.picture_url}
                          alt={initials(e.title)}
                          style={{
                            backgroundColor: color,
                          }}
                        />
                      </div>
                      <div className="flex flex-col flex-1">
                        <h3>{e.title}</h3>
                        <small>{e.description}</small>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              {permissions.includes("create_project") && (
                <div className=" text-right">
                  <Button onClick={() => nav("/projects")} appearance="ghost">
                    All Projects
                  </Button>
                </div>
              )}
            </div>
            {/* Goals Section */}
            <div className="xl:col-span-1 col-span-2 row-span-2 bg-white rounded-lg shadow-black hover:shadow-xl  overflow-y-auto">
              <div className="flex  items-center gap-2 text-gray-900 p-4 border-b flex-row justify-between">
                <div className="flex flex-row gap-4 items-center">
                  <HiOutlineInbox />
                  <div className="">
                    <h1 className="text-lg font-[500]">Inbox</h1>
                  </div>
                </div>
                <button
                  className="rounded-lg border bg-white flex flex-row gap-2 px-4 py-2 text-xs text-gray-600 hover:bg-gray-100 items-center "
                  onClick={() => setModalCompose(true)}
                >
                  <VscSend /> Compose
                </button>
              </div>
              <div className="p-4">
                <ul>
                  {inboxes.map((e) => (
                    <li
                      key={e.id}
                      className="hover:bg-gray-50 border-b last:border-b-0 cursor-pointer"
                      onClick={() => {
                        if (e.parent_id) {
                          nav(`/inbox/inbox/${e.parent_id}`);
                          return;
                        }
                        nav(`/inbox/inbox/${e.id}`);
                      }}
                    >
                      <div className="p-2">
                        <div className="flex flex-row justify-between">
                          <div>
                            <div className="flex flex-row justify-between items-center gap-4">
                              <h3 className="font-[500] text-sm">{e.title}</h3>
                            </div>
                            <p className="text-gray-600 text-sm">
                              <strong>to: </strong>
                              <small>
                                {e.recipients
                                  .map(
                                    (e) => `${e.to.full_name} (${e.to.email})`
                                  )
                                  .join(", ")}
                              </small>
                            </p>
                            {e.files.length > 0 && (
                              <Tag>
                                <div className="flex flex-row items-center justify-center gap-2">
                                  <HiPaperClip /> {e.files.length}
                                </div>
                              </Tag>
                            )}
                          </div>
                          <small className="italic text-right text-[8pt] flex flex-col items-end gap-2 justify-center ">
                            {moment(e.created_at).fromNow()}

                            {e.recipients
                              .filter((e) => !e.is_read)
                              .map((e) => e.to_id)
                              .includes(profile!.id) && (
                              <div className="w-2 h-2 rounded-full bg-green-500"></div>
                            )}
                          </small>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Tasks Given Section */}
            <div className="col-span-2 row-span-1 bg-white rounded-lg shadow-black hover:shadow-xl p-4 overflow-y-auto">
              <h2 className="text-lg font-semibold">Tasks Given</h2>
              <ul className="">
                {myTaskGivens.map((e) => (
                  <li
                    className="py-2 flex justify-between border-b last:border-b-0 hover:font-[500] cursor-pointer"
                    key={e.id}
                    onClick={async () => {
                      let company = await getCompany(
                        e.column?.board?.project?.company_id!
                      );
                      let companyJson = await company.json();
                      setCompany(companyJson.data);
                      setProject(e?.column?.board?.project ?? null);

                      let url = `/project/${e.column?.board?.project?.id}/board/${e.column?.board_id}?task_id=${e.id}`;
                      nav(url);
                    }}
                  >
                    <span className="">{e.title}</span>
                    <AvatarGroup stack>
                      {e.contributors
                        .filter((member, i) => i < 4)
                        .map((member) => (
                          <Avatar
                            size="xs"
                            bordered
                            circle
                            key={member.user.full_name}
                            src={member.user.picture_url}
                            alt={initials(member.user.full_name)}
                            color="violet"
                          />
                        ))}
                      {e.contributors.length - 4 > 0 && (
                        <Avatar
                          size="xs"
                          bordered
                          circle
                          style={{ background: "#111" }}
                        >
                          +{e.contributors.length - 4}
                        </Avatar>
                      )}
                    </AvatarGroup>
                  </li>
                ))}
              </ul>
            </div>

            {/* Goals Section */}
            <div className="xl:col-span-1 col-span-2 row-span-1 bg-white rounded-lg shadow-black hover:shadow-xl p-4 flex flex-col overflow-y-auto">
              <h2 className="text-lg font-semibold">Companies</h2>
              <div className="mt-4 flex-1 ">
                <ul className="flex flex-col space-y-2 ">
                  {companies.map((e) => (
                    <li
                      className="py-2 flex flex-row gap-2 hover:bg-gray-100 cursor-pointer p-2 rounded-lg"
                      key={e.id}
                      onClick={() => nav(`/project/${e.id}`)}
                    >
                      <div
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      >
                        <Avatar
                          src={e.logo_url}
                          alt={initials(e.name)}
                          style={{
                            backgroundColor: color,
                          }}
                        />
                      </div>
                      <div className="flex flex-col">
                        <h3>{e.name}</h3>
                        <small>{e.address}</small>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
                <div className=" text-right">
                  <Button onClick={() =>{
                    setModalCompanyOpen(true)
                  }} appearance="ghost">
                   + Company
                  </Button>
                </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size={"lg"}
        open={modalCompose}
        onClick={() => setModalCompose(false)}
      >
        <Modal.Header>New Message</Modal.Header>
        <Modal.Body>
          {profile && (
            <ComposeForm
              profile={profile}
              onSuccess={() => {
                getAllInboxes();
                setModalCompose(false);
              }}
            />
          )}
        </Modal.Body>
      </Modal>
      <ModalCompany
        open={modalCompanyOpen}
        onClose={() => setModalCompanyOpen(false)}
      />
    </MainLayout>
  );
};
export default Home;
