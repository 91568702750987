import type { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import InvitationAccept from '../pages/InvitationAccept'
import PublicForm from "../pages/PublicForm";
import AuthHandler from "../pages/AuthHandler";
interface AppRouteProps {}

const AppRoute: FC<AppRouteProps> = ({}) => {
  const token = localStorage.getItem("token");
  return (
    <BrowserRouter>
      <Routes>
        
        <Route path="/auth/handler" element={<AuthHandler />} />
        <Route path="/invitation/:invitationId/accept" element={<InvitationAccept />} />
        <Route path="/form/:formId" element={<PublicForm />} />
        {token ? (
          <Route path="/*" element={<PrivateRoutes />} />
        ) : (
          <Route path="/*" element={<PublicRoutes />} />
        )}
      </Routes>
    </BrowserRouter>
  );
};
export default AppRoute;
