import type { FC } from "react";
import { Avatar } from "rsuite";
import { Comment } from "../model/comment";
import { initials } from "../utils/helper";
import moment from "moment";
import CustomAvatar from "./CustomAvatar";
import { parseMentions } from "../utils/helper-ui";

interface CommentListProps {
  comments: Comment[];
}

const CommentList: FC<CommentListProps> = ({ comments }) => {
  return (
    <div className="comment-section">
      {comments.map((comment) => (
        <div className="comment" key={comment.id}>
          <div>
            <CustomAvatar
              color="violet"
              src={
                comment.member?.user?.picture_url ??
                comment.client?.user?.picture_url
              }
              alt={
                comment.member?.user?.full_name ??
                comment.client?.user?.full_name
              }
            />
          </div>
          <div className="comment-content">
            <div className="comment-header">
              <span className="commenter-name">
                {comment.member?.user?.full_name ??
                  comment.client?.user?.full_name}{" "}
              </span>
              <span className="comment-time">
                {" "}
                {moment(comment.created_at).fromNow()}
              </span>
            </div>
            <CommentParse text={comment.message} />
          </div>
        </div>
      ))}
    </div>
  );
};
export default CommentList;

type CommentParseProps = {
  text: string;
};

export const CommentParse: React.FC<CommentParseProps> = ({ text }) => {
  return (
    <div>
      {parseMentions(text, (type: string, id: string) => {
        if (type == "member") handleMemberClick(id);
        if (type == "client") handleClientClick(id);
        if (type == "channel") handleChannelClick(id);
      })}
    </div>
  );
};

const handleMemberClick = (memberId: string) => {
  console.log("Member clicked, memberId:", memberId);
  // Example: Navigate to the member's profile or trigger another action
};

const handleClientClick = (clientId: string) => {
  console.log("Client clicked, clientId:", clientId);
  // Example: Navigate to the client's profile or trigger another action
};
const handleChannelClick = (clientId: string) => {
  console.log("Channel clicked, clientId:", clientId);
  // Example: Navigate to the client's profile or trigger another action
};
