import { createContext } from "react";
import { Company } from "../model/company";

type CounterContextType = {
  counter?: CounterNotif;
  setCounter: (counter: CounterNotif) => void;
};

export const CounterContext = createContext<CounterContextType>({
  counter: {
    inbox_unread: 0,
  },
  setCounter: (val) => false,
});

export interface CounterNotif {
  inbox_unread: number;
}
