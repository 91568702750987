import { customFetch } from "../utils/http";

import { CompanyReq } from "../model/company";
import { PaginationReq } from "../objects/pagination";
import { ProfileReq } from "../model/user";
export const getProfile = async () => {
  return await customFetch("my");
};
export const createCompany = async (req: CompanyReq) => {
  return await customFetch("my/createCompany", {
    method: "POST",
    body: JSON.stringify(req),
  });
};
export const getCompanies = async () => {
  return await customFetch("my/companies", {});
};
// export const myProject = async () => {
//   return await customFetch("my/project", {});
// };
export const myTask = async (pagination: PaginationReq) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };
  if (pagination.search && pagination.search != "") {
    params["search"] = pagination.search;
  }
  return await customFetch(`my/task?${new URLSearchParams(params)}`);
};

export const getAssignedCompanies = async () => {
  return await customFetch("my/assignedCompanies", {});
};
export const updateAvatar = async (avatar: string) => {
  return await customFetch("my/updateAvatar", {
    method: "PUT",
    body: JSON.stringify({ avatar }),
  });
};
export const updateCover = async (cover: string) => {
  return await customFetch("my/updateCover", {
    method: "PUT",
    body: JSON.stringify({ cover }),
  });
};
export const updatePassword = async (password: string) => {
  return await customFetch("my/updatePassword", {
    method: "PUT",
    body: JSON.stringify({ password }),
  });
};
export const updateProfile = async (req: ProfileReq) => {
  return await customFetch("my", {
    method: "PUT",
    body: JSON.stringify(req),
  });
};


export let updateWebPushToken = async ( token: string, device_id: string) => {
  return await customFetch(
    `my/updateWebPushToken`,
    {
      method: "POST",
      body: JSON.stringify({ token, device_id, device_type: "web" }),
    }
  );
};