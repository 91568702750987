import { createContext } from "react";
import { Company } from "../model/company";

type CompanyContextType = {
  company?: Company | null;
  setCompany: (company: Company | null) => void;
};

export const CompanyContext = createContext<CompanyContextType>({
  company: null,
  setCompany: (val) => false,
});
