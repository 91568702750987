import { Message } from "@rsuite/icons";
import moment from "moment";
import { useContext, useEffect, useState, type FC } from "react";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { BsPlusCircle } from "react-icons/bs";
import {
  PiCalendar,
  PiCalendarDots,
  PiChat,
  PiCheckCircleFill,
  PiPassword,
  PiPath,
  PiRadioButtonFill,
} from "react-icons/pi";
import { VscTrash } from "react-icons/vsc";
import { Avatar, Button, Tag } from "rsuite";
import Swal from "sweetalert2";
import { Column, Task, TaskField, TaskReq } from "../model/board";
import { Member } from "../model/member";
import { ActivityLogReq, Project } from "../model/project";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { LoadingContext } from "../objects/loading";
import { deleteColumn } from "../repositories/column";
import { createTask } from "../repositories/task";
import {
  getPriorityColor,
  getTextColorBasedOnBackground,
  initials,
  money,
  randomStr,
} from "../utils/helper";
import { errorToast, toolTip } from "../utils/helper-ui";
import CircleStatus from "./CircleStatus";
import ModalColor from "./ModalColor";
import { StrictModeDroppable } from "./StrictModeDroppable";
import { TbCheckbox, TbNumber123, TbSubtask } from "react-icons/tb";
import CustomAvatar from "./CustomAvatar";
import { FormFieldType } from "../model/form";
import {
  RiDropdownList,
  RiFileUploadLine,
  RiText,
  RiTextBlock,
} from "react-icons/ri";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RxSwitch } from "react-icons/rx";
import { BiMoneyWithdraw } from "react-icons/bi";

interface KanbanBoardProps {
  project: Project | null;
  data: Column[];
  onDragEnd: (result: DropResult) => void;
  onColumnsChange: (cols: Column[]) => void;
  onColumnChange: (column: Column) => void;
  onTaskChange: (task: Task) => void;
  ws?: WebSocket | null;
  profile?: User;
  onSelectTask: (task: Task) => void;
  onClickContributor: (task: Task, val: Member[]) => void;
  onUpdateActivity: (act: ActivityLogReq) => void;
}

const KanbanBoard: FC<KanbanBoardProps> = ({
  data,
  onDragEnd,
  onColumnChange,
  onColumnsChange,
  onTaskChange,
  onSelectTask,
  onClickContributor,
  profile,
  onUpdateActivity,
  ws,
}) => {
  const { color, setColor } = useContext(BgColorContext);
  const [modalColor, setModalColor] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState<Column | null>(null);
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const renderIcon = (e: FormFieldType, size: number) => {
    switch (e) {
      case FormFieldType.TextField:
        return <RiText size={size} />;
      case FormFieldType.TextArea:
        return <RiTextBlock size={size} />;
      case FormFieldType.RadioButton:
        return <PiRadioButtonFill size={size} />;
      case FormFieldType.Checkbox:
        return <TbCheckbox size={size} />;
      case FormFieldType.DatePicker:
        return <PiCalendar size={size} />;
      case FormFieldType.DateRangePicker:
        return <PiCalendarDots size={size} />;
      case FormFieldType.NumberField:
        return <TbNumber123 size={size} />;
      case FormFieldType.EmailField:
        return <MdOutlineAlternateEmail size={size} />;
      case FormFieldType.PasswordField:
        return <PiPassword size={size} />;
      case FormFieldType.FileUpload:
        return <RiFileUploadLine size={size} />;
      case FormFieldType.ToggleSwitch:
        return <RxSwitch size={size} />;
      case FormFieldType.Dropdown:
        return <RiDropdownList size={size} />;
      case FormFieldType.Currency:
        return <BiMoneyWithdraw size={size} />;
    }

    return null;
  };
  const renderValue = (e: TaskField) => {
    switch (e.type) {
      case FormFieldType.TextField:
        return e.value.value;
      case FormFieldType.TextArea:
        return e.value.value;
      case FormFieldType.Checkbox:
        return (e.value.value as string[] ?? []).map(e => <span className="px-1 bg-gray-200 mx-1 rounded-lg py-0.5" key={randomStr(2)}>{e}</span>);
      case FormFieldType.DatePicker:
        return moment(e.value.value).format("DD MMM YYYY");
      case FormFieldType.DateRangePicker:
        return `${moment(e.value.value[0]).format("DD MMM YYYY")} ~ ${moment(e.value.value[1]).format("DD MMM YYYY")}`;
      case FormFieldType.Currency:
      case FormFieldType.NumberField:
        return money(e.value.value);
      case FormFieldType.EmailField:
        return e.value.value;
      case FormFieldType.PasswordField:
        return e.value.value;
      case FormFieldType.FileUpload:
        return e.value.value;
      case FormFieldType.ToggleSwitch:
        return e.value.value && <PiCheckCircleFill className="text-green-400" size={16} />;
        case FormFieldType.RadioButton:
      case FormFieldType.Dropdown:
        return e.options.find(s => s.value == e.value.value)?.label;
    }

    return null;
  };

  // Separate component for Task
  const TaskItem: React.FC<{
    task: Task;
    index: number;
  }> = ({ task, index }) => (
    <Draggable draggableId={task.id} index={index} key={task.id}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`task-item ${snapshot.isDragging ? "dragging" : ""}`}
        >
          <div
            className="p-3 bg-white my-4 rounded-lg border"
            onClick={() => onSelectTask(task)}
          >
            <div className="flex flex-row justify-between items-start mb-8">
              <div>
                <h3 className="font-medium mb-2">{task.title}</h3>
                {(task.fields ?? []).length > 0 && (
                  <ul>
                    {task.fields.map((e) => (
                      <li className="flex flex-row gap-1 text-xs" key={e.id}>
                        {toolTip(
                          e.label,
                          <div className="flex flex-row gap-1 text-xs">
                            {renderIcon(e.type, 16)}{" "}
                            <span>{renderValue(e)}</span>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div>
                <CustomAvatar
                  size={32}
                  key={task.reported.user.full_name}
                  src={task.reported.user.picture_url}
                  alt={task.reported.user.full_name}
                />
              </div>
            </div>
            <div className="flex justify-between items-center ">
              <div
                className={`text-white px-2 py-1 rounded text-xs`}
                style={{
                  backgroundColor: getPriorityColor(task.priority),
                  color: getTextColorBasedOnBackground(
                    getPriorityColor(task.priority)
                  ),
                }}
              >
                {task.priority}
              </div>
              <div
                className="flex items-center space-x-2"
                style={{ marginRight: -5 }}
              >
                {task.sub_task_count > 0 && (
                  <div className="flex flex-row gap-2 bg-gray-50 rounded-lg p-1 items-center">
                    <TbSubtask size={12} className="text-gray-600" />{" "}
                    {task.sub_task_count}
                  </div>
                )}
                {task.comment_count > 0 && (
                  <div className="flex flex-row gap-2 bg-gray-50 rounded-lg p-1 items-center">
                    <PiChat size={12} className="text-gray-600" />{" "}
                    {task.comment_count}
                  </div>
                )}
                {task.is_completed ? (
                  <PiCheckCircleFill className="text-green-500 " size={40} />
                ) : (
                  <CircleStatus
                    percentage={task.progress}
                    onChange={(val) => {
                      onTaskChange({
                        ...task,
                        progress: val,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );

  // const ColumnComponent: React.FC<{
  //   column: Column;
  // }> = ({ column }) => (
  //   <div
  //     key={column.id}
  //     className=" rounded-b-lg bg-red-50 "
  //     style={{
  //       width: "300px",
  //       minHeight: 300,
  //     }}
  //   >
  //     <StrictModeDroppable droppableId={column.id} type="ITEM">
  //       {(provided) => (
  //         <div
  //           ref={provided.innerRef}
  //           {...provided.droppableProps}
  //           className="task-list min-h-[300px] px-4 overflow-y-auto"
  //         >
  //           {column.tasks.map((task, index) => (
  //             <TaskItem key={task.id} task={task} index={index} />
  //           ))}
  //           {column.tasks.length == 0 && (
  //             <div className="flex justify-center py-2 min-h-32 h-full items-center">
  //               <Button
  //                 appearance="ghost"
  //                 color="red"
  //                 className="text-center h-8"
  //                 onClick={() => {
  //                   Swal.fire({
  //                     title: "Are you sure?",
  //                     text: "You won't be able to revert this!",
  //                     icon: "warning",
  //                     showCancelButton: true,
  //                     confirmButtonColor: "#3085d6",
  //                     cancelButtonColor: "#d33",
  //                     confirmButtonText: "Yes, delete it!",
  //                   }).then(async (result) => {
  //                     if (result.isConfirmed) {
  //                       try {
  //                         setIsLoading(true);
  //                         await deleteColumn(column.id);
  //                         if (ws) {
  //                           ws.send(
  //                             JSON.stringify({
  //                               type: "UPDATE",
  //                               action: "DELETE_COLUMN",
  //                               targetId: column.board_id,
  //                               targetType: "BOARD",
  //                               userId: profile?.id,
  //                             })
  //                           );
  //                         }
  //                       } catch (err) {
  //                         errorToast(`${err}`);
  //                       } finally {
  //                         setIsLoading(false);
  //                       }
  //                     }
  //                   });
  //                 }}
  //               >
  //                 <VscTrash />
  //                 Delete Column
  //               </Button>
  //             </div>
  //           )}
  //           {provided.placeholder}
  //         </div>
  //       )}
  //     </StrictModeDroppable>
  //   </div>
  // );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable
        droppableId="all-columns"
        type="CATEGORY"
        direction="horizontal"
      >
        {(provided) => (
          <div
            className=" p-4 "
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <div className="h-full  ml-4  overflow-x-auto flex flex-1">
              {data.map((column, index) => (
                <Draggable
                  key={column.id}
                  draggableId={column.id}
                  index={index}
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <div
                        key={column.id}
                        style={{ width: 300 }}
                        className="mr-4 "
                      >
                        <div
                          className="pt-1 pb-2 px-4 text-white justify-between items-center flex flex-row"
                          style={{
                            backgroundColor: `${column.color ?? color}`,
                            borderTop: `7px solid ${column.color}`,
                            borderRadius: "7px 7px 0 0",
                          }}
                        >
                          <div
                            className="flex flex-row items-center justify-center gap-2"
                            {...provided.dragHandleProps}
                          >
                            <div
                              className=" aspect-square w-4 border-[0.5px] cursor-pointer rounded-full"
                              style={{
                                backgroundColor: column.color ?? color,
                                borderColor: getTextColorBasedOnBackground(
                                  column.color ?? color
                                ),
                              }}
                              onClick={() => {
                                setSelectedColumn(column);
                                setModalColor(true);
                              }}
                            />
                            <div>
                              <span
                                style={{
                                  color: `${getTextColorBasedOnBackground(
                                    column.color ?? color
                                  )}`,
                                }}
                              >
                                ({column.tasks.length}){" "}
                                <InputColumn
                                  column={column}
                                  updateColumn={(val) => {
                                    onColumnChange(val);
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <BsPlusCircle
                            className="cursor-pointer"
                            onClick={() => {
                              let req: TaskReq = {
                                title: "NEW TASK",
                                priority: "Low",
                                type: "Other",
                                description: "{}",
                                date: moment().toISOString(),
                                progress: 0,
                                dependencies: [],
                                contributors: [],
                                column_id: column.id,
                                position: column.tasks.length - 1,
                              };
                              // console.log(req);
                              createTask(req).then((res) => {
                                if (ws) {
                                  ws.send(
                                    JSON.stringify({
                                      type: "UPDATE",
                                      action: "ADD_TASK",
                                      targetId: column.board_id,
                                      targetType: "BOARD",
                                      userId: profile?.id,
                                    })
                                  );
                                }
                              });
                            }}
                          />
                        </div>
                        <div
                          key={column.id}
                          className=" rounded-b-lg bg-gray-50  overflow-y"
                          style={{
                            width: "300px",
                          }}
                        >
                          <StrictModeDroppable
                            droppableId={column.id}
                            type="ITEM"
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="task-list min-h-[160px] h-full] px-4 overflow-y-auto"
                                style={{
                                  maxHeight: "calc(100vh - 240px)",
                                }}
                              >
                                {column.tasks.map((task, index) => (
                                  <TaskItem
                                    key={task.id}
                                    task={task}
                                    index={index}
                                  />
                                ))}
                                {column.tasks.length == 0 && (
                                  <div className="flex justify-center py-2 min-h-32 h-full items-center">
                                    <Button
                                      appearance="ghost"
                                      color="red"
                                      className="text-center h-8"
                                      onClick={() => {
                                        Swal.fire({
                                          title: "Are you sure?",
                                          text: "You won't be able to revert this!",
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          confirmButtonText: "Yes, delete it!",
                                        }).then(async (result) => {
                                          if (result.isConfirmed) {
                                            try {
                                              setIsLoading(true);
                                              await deleteColumn(column.id);
                                              if (ws) {
                                                ws.send(
                                                  JSON.stringify({
                                                    type: "UPDATE",
                                                    action: "DELETE_COLUMN",
                                                    targetId: column.board_id,
                                                    targetType: "BOARD",
                                                    userId: profile?.id,
                                                  })
                                                );
                                              }
                                            } catch (err) {
                                              errorToast(`${err}`);
                                            } finally {
                                              setIsLoading(false);
                                            }
                                          }
                                        });
                                      }}
                                    >
                                      <VscTrash />
                                      Delete Column
                                    </Button>
                                  </div>
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </StrictModeDroppable>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>

      <ModalColor
        open={modalColor}
        onClose={() => setModalColor(false)}
        color={color}
        onChange={(val, e) => {
          if (selectedColumn) {
            onColumnChange({
              ...selectedColumn,
              color: val.hex,
            });
            setModalColor(false);
            setSelectedColumn(null);
          }
        }}
      />
    </DragDropContext>
  );
};
export default KanbanBoard;

interface InputColumnProps {
  column: Column;
  updateColumn: (val: Column) => void;
}

const InputColumn: FC<InputColumnProps> = ({ column, updateColumn }) => {
  const [tempColumn, setTempColumn] = useState<Column>();
  useEffect(() => {
    setTempColumn(column);
  }, [column]);
  if (!column) return <div></div>;
  return (
    <input
      style={{
        color: `${getTextColorBasedOnBackground(
          tempColumn?.color ?? "#000000"
        )}`,
        fontWeight: 500,
      }}
      className="bg-transparent focus:ring-0 focus:outline-none focus:ring-gray-200 "
      value={tempColumn?.title}
      onChange={(e) => {
        setTempColumn({
          ...tempColumn!,
          title: e.target.value,
        });
      }}
      onBlur={(e) => {
        if (tempColumn) {
          updateColumn(tempColumn);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          if (tempColumn) {
            updateColumn(tempColumn);
          }
        }
      }}
    />
  );
};
