import type { FC } from 'react';
import { BsReply } from "react-icons/bs";
interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = ({}) => {
        return (
            <div className="flex flex-col justify-center items-center min-h-screen">
                <h1 className="text-6xl font-bold">404</h1>
                <p className="text-2xl">Page Not Found</p>
                <button
                    type="button"
                    className="mt-4 gap-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => window.history.back()}
                >
                    <BsReply />
                    Back
                </button>
                
            </div>
        );
}
export default NotFound;