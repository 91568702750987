import { useContext, useEffect, useState, type FC } from "react";
import { getPublicForm } from "../repositories/public";
import { useParams } from "react-router-dom";
import { LoadingContext } from "../objects/loading";
import { Form } from "../model/form";
import { errorToast } from "../utils/helper-ui";
import { TbTriangleFilled } from "react-icons/tb";
import { FaTriangleExclamation } from "react-icons/fa6";
import { Divider } from "rsuite";
import FormPreview from "../components/FormPreview";

interface PublicFormProps {}

const PublicForm: FC<PublicFormProps> = ({}) => {
  const [mounted, setMounted] = useState(false);
  const { formId } = useParams();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [isAvailable, setIsAvailable] = useState(false);
  const [reason, setReason] = useState("");

  const [form, setForm] = useState<Form>();
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;

    getDetail();
  }, [mounted]);

  const getDetail = async () => {
    try {
      setIsLoading(true);
      let resp = await getPublicForm(formId!);
      let respJson = await resp.json();
      setForm(respJson.data);
      setIsAvailable(respJson.is_available);
      setReason(respJson.reason);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="flex flex-col bg-gray-100 h-screen  items-center p-8">
        <div className="w-full max-w-[800px] rounded-lg hover:shadow min-h-[100px] bg-white p-8 overflow-y-auto">
          {isAvailable && (
            <div >
              <h1 className="text-2xl font-[500]">{form?.title}</h1>
              <p>{form?.description}</p>
              <Divider />
              <FormPreview form={form!} showSubmit />
            </div>
          )}
          {!isAvailable && reason != "" && (
            <div className="flex flex-col justify-center items-center min-h-[300px]">
              <FaTriangleExclamation className="text-6xl text-orange-500" />
              <h3 className="text-center">This form is not available :</h3>

              <h3 className="text-center text-2xl">{reason}</h3>
            </div>
          )}
        </div>
      </div>
      
    </div>
  );
};
export default PublicForm;
