import type { FC } from "react";
import React, { useContext, useEffect, useRef, useState } from "react";
import MainLayout from "../components/MainLayout";
import { BgColorContext } from "../objects/color_object";
import {
  addClient,
  addMember,
  addTeam,
  getProject,
  getProjects,
  updateProject,
} from "../repositories/project";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Project } from "../model/project";
import { LoadingContext } from "../objects/loading";
import { errorToast } from "../utils/helper-ui";
import { Avatar, Button, Input, Modal, SelectPicker, Uploader } from "rsuite";
import { initials } from "../utils/helper";
import { TiDocumentText } from "react-icons/ti";
import {
  PiCamera,
  PiDropboxLogoLight,
  PiPlus,
  PiPlusBold,
} from "react-icons/pi";
import {
  asyncLocalStorage,
  getProjectStorage,
  setProfileStorage,
  setProjectStorage,
} from "../utils/storage";
import { ProjectContext } from "../objects/project_object";
import { COMPANY_ID, TOKEN } from "../objects/constants";
import { uploadFile } from "../repositories/file";
import CustomAvatar from "../components/CustomAvatar";
import ModalTeam from "../components/ModalTeam";
import { Team } from "../model/team";
import ModalMember from "../components/ModalMember";
import { getClients } from "../repositories/client";
import { Client } from "../model/client";
import ModalClient from "../components/ModalClient";
import { Member } from "../model/member";
import { getMembers } from "../repositories/member";
import { CompanyContext } from "../objects/company_context";
import { createDoc } from "../repositories/doc";
import { createDropBox } from "../repositories/drop_box";
import { profile } from "console";
import { createBoard } from "../repositories/board";
import InlineForm from "../components/InlineForm";
import ModalColor from "../components/ModalColor";
import { ColorResult } from "react-color";
import ModalForm from "../components/ModalForm";
import { RiInputField } from "react-icons/ri";

interface ProjectDetailProps {}

const ProjectDetail: FC<ProjectDetailProps> = ({}) => {
  const { project: activeProject, setProject: setActiveProject } =
    useContext(ProjectContext);

  const { color, setColor } = useContext(BgColorContext);
  const { company, setCompany } = useContext(CompanyContext);
  const [mounted, setMounted] = useState(false);
  const { projectId } = useParams();
  const [project, setProject] = useState<Project | null>(null);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [token, setToken] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const [modalTeamOpen, setModalTeamOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [modalTeamInfo, setModalTeamInfo] = useState(false);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [modalClientOpen, setModalClientOpen] = useState(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [members, setMembers] = useState<Member[]>([]);
  const [existingMembers, setExistingMembers] = useState<Member[]>([]);
  const [modalMemberOpen, setModalMemberOpen] = useState(false);
  const [teamMemberIds, setTeamMemberIds] = useState<string[]>([]);
  const nav = useNavigate();
  const location = useLocation();
  const [modalBoardOpen, setModalBoardOpen] = useState(false);
  const [boardName, setBoardName] = useState("");
  const [boardDesc, setBoardDesc] = useState("");
  const [boardColor, setBoardColor] = useState("");
  const [modalColorOpen, setModalColorOpen] = useState(false);
  const [boardColumn, setBoardColumn] = useState(`TODO
IN PROGRESS
TESTING
DONE`);
  const [modalFormOpen, setModalFormOpen] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    asyncLocalStorage.getItem(TOKEN).then((v) => {
      setToken(v);
    });
    asyncLocalStorage.getItem(COMPANY_ID).then((v) => {
      setCompanyID(v);
    });

    return () => setMounted(false);
  }, [mounted, projectId, company]);

  useEffect(() => {
    if (!mounted) return;
    if (!company) return;
    if (!company?.is_client) {
      getMembers({ page: 1, limit: 0 })
        .then((res) => res.json())
        .then((v) => {
          setMembers(v.data);
        })
        .catch((err) => {
          errorToast(err);
        });
    }
  }, [company, mounted]);

  useEffect(() => {
    if (projectId) {
      setMounted(true);
      getDetail();
    }
  }, [projectId]);

  const getDetail = async () => {
    try {
      setIsLoading(true);
      let resp = await getProject(projectId!);
      let respJson = await resp.json();
      setProject(respJson.data);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (project) {
      if (project.id == activeProject?.id) {
        setActiveProject(project);
        setProjectStorage(project);
      }
      setFile(project?.picture);

      let objMember = new Map<string, Member>();
      for (const member of project?.members ?? []) {
        objMember.set(member.id, member);
      }
      let _teamMemberIds = [];
      for (const team of project?.teams ?? []) {
        for (const member of team.members) {
          objMember.set(member.id, member);
          _teamMemberIds.push(member.id);
        }
      }
      setTeamMemberIds(_teamMemberIds);
      setExistingMembers(Array.from(objMember.values()));
    }
  }, [project]);

  const update = () => {
    setIsLoading(true);
    updateProject(projectId!, {
      title: project?.title!,
      picture: project?.picture ?? "",
      description: project?.description ?? "",
    })
      .then(() => {
        getDetail();
      })
      .catch((error) => {
        errorToast(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const info = () => (
    <div
      style={{ width: 360 }}
      className=" h-full bg-white ml-4 rounded-tl-[15px]  flex flex-col "
    >
      <input
        type="file"
        accept="image/*"
        ref={fileRef}
        className="hidden"
        onChange={(val) => {
          console.log();
          setProject({
            ...project!,
            picture_url: URL.createObjectURL(val.target.files![0]),
          });
          uploadFile(val.target.files![0], (progress) => {
            console.log(progress);
          })
            .then((resp) => resp.json())
            .then((resp) => {
              // console.log(resp.data.path);
              setActiveProject({
                ...project!,
                picture: resp.data.path,
                picture_url: resp.data.url,
              });
              updateProject(projectId!, {
                title: project?.title!,
                picture: resp.data.path,
                description: project?.description ?? "",
              })
                .then(() => {
                  getDetail();
                })
                .catch((error) => {
                  errorToast(error);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }}
      />
      {project?.picture_url ? (
        <img
          src={project?.picture_url}
          className="w-full h-[200px] rounded-tl-[15px] cursor-pointer object-cover"
          onClick={() => {
            if (company?.is_client) return;
            fileRef?.current?.click();
          }}
        />
      ) : (
        <div
          className="w-full h-[200px] rounded-tl-[15px] cursor-pointer object-cover bg-violet-800 flex flex-col justify-center items-center"
          onClick={() => {
            if (company?.is_client) return;
            fileRef?.current?.click();
          }}
        >
          <PiCamera className="text-white " size={64} />
        </div>
      )}
      <div className="p-4">
        {project && (
          <>
            <textarea
              disabled={company?.is_client}
              value={project?.title}
              onChange={(val) =>
                setProject({
                  ...project!,
                  title: val.target.value,
                })
              }
              rows={2}
              className=" text-gray-600 sm:text-sm rounded-lg focus:ring-1 focus:outline-none focus:ring-gray-50  block w-full  font-[500] resize-none"
              style={{ fontSize: 24 }}
              onBlur={update}
            />
            <textarea
              disabled={company?.is_client}
              value={project?.description}
              onChange={(val) =>
                setProject({
                  ...project!,
                  description: val.target.value,
                })
              }
              onBlur={update}
              className=" text-gray-600 sm:text-sm rounded-lg focus:ring-1 focus:outline-none focus:ring-gray-50  block w-full text-sm"
            />
            <p className="font-bold text-sm mt-8 mb-2">Creator</p>
            <div className="flex flex-row items-center gap-2">
              <Avatar
                alt={initials(project?.creator?.user.full_name)}
                src={project?.creator?.user.picture_url}
                color="violet"
                size="sm"
                circle
              />
              <div className="flex flex-col">
                {project?.creator?.user.full_name}
                <small>{project?.creator?.role?.name}</small>
              </div>
            </div>
            <p className="font-bold text-sm mt-8 mb-2">Members</p>
            {(project?.members ?? []).map((e) => (
              <div className="flex flex-row items-center gap-2 py-1" key={e.id}>
                <Avatar
                  alt={initials(e.user.full_name)}
                  src={e.user.picture_url}
                  color="violet"
                  size="sm"
                  circle
                />
                <div className="flex flex-col">
                  {e.user.full_name}
                  <small>{e.role?.name}</small>
                </div>
              </div>
            ))}
            {(project?.members ?? []).length > 0 && (
              <div className="my-4"></div>
            )}
            {permissions.includes("create_member") && (
              <Button
                appearance="ghost"
                size="xs"
                block
                onClick={() => setModalMemberOpen(true)}
              >
                + Member
              </Button>
            )}

            <div className="flex flex-row justify-between items-center mt-8">
              <p className="font-bold text-sm  mb-2">Teams</p>
            </div>
            {(project?.teams ?? []).map((e) => (
              <div
                className="flex flex-row items-center gap-2 py-1 cursor-pointer"
                key={e.id}
                onClick={() => {
                  setSelectedTeam(e);
                  setModalTeamInfo(true);
                }}
              >
                <CustomAvatar
                  key={e.id}
                  alt={e.title}
                  color={e.color}
                  src={e.picture_url}
                />
                <div className="flex flex-col">
                  <h3>{e.title}</h3>
                  {e.description && (
                    <p className="text-xs text-gray-600">{e.description}</p>
                  )}
                </div>
              </div>
            ))}
            {(project?.teams ?? []).length > 0 && <div className="my-4"></div>}
            {permissions.includes("create_team") && (
              <Button
                appearance="ghost"
                size="xs"
                block
                onClick={() => setModalTeamOpen(true)}
              >
                + Team
              </Button>
            )}

            <div className="flex flex-row justify-between items-center mt-8">
              <p className="font-bold text-sm  mb-2">Clients</p>
            </div>
            {(project?.clients ?? []).map((e) => (
              <div
                className="flex flex-row items-center gap-2 py-1 cursor-pointer"
                key={e.id}
                onClick={() => {}}
              >
                <CustomAvatar
                  key={e.id}
                  alt={e.user?.full_name}
                  src={e.user?.picture_url}
                />
                <div className="flex flex-col">
                  <h3>{e.user?.full_name}</h3>
                  {e.client_company && (
                    <p className="text-xs text-gray-600">
                      {e.client_company?.name}
                    </p>
                  )}
                  {e.user && (
                    <p className="text-xs text-gray-600">{e.user?.email}</p>
                  )}
                </div>
              </div>
            ))}
            {(project?.clients ?? []).length > 0 && (
              <div className="my-4"></div>
            )}
            {permissions.includes("create_client") && (
              <Button
                appearance="ghost"
                size="xs"
                block
                onClick={() => setModalClientOpen(true)}
              >
                + Client
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );

  return (
    <MainLayout onLoadPermissions={setPermissions}>
      <div
        className="flex flex-col flex-1 overflow-x-auto"
        style={{ backgroundColor: color }}
      >
        <div className="flex flex-row h-full  ">
          <div
            className="  h-full bg-white ml-4 rounded-t-[15px]  flex flex-col w-full overflow-y-auto"
            style={{ height: "calc(100vh - 50px)" }}
          >
            <div className="p-4">
              <div className="flex flex-row justify-between">
                <h1 className="text-xl font-bold">Boards</h1>
                <Button
                  appearance="primary"
                  style={{
                    backgroundColor: color,
                  }}
                  onClick={async () => {
                    setModalBoardOpen(true);
                  }}
                >
                  <PiPlus />
                </Button>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-2">
                {(project?.boards ?? []).map((e) => (
                  <Link key={e.id} to={`/project/${project?.id}/board/${e.id}`}>
                    <div
                      className="p-4 border rounded-lg flex flex-col justify-end  h-[100px] text-white "
                      style={{
                        backgroundColor: e.color != "" ? e.color : color,
                      }}
                    >
                      <h1 className="text-shadow-sm">
                        <span className="text-lg font-bold">{e.title}</span>
                      </h1>
                      <div className="">
                        <p className="text-sm text-shadow-sm">
                          {e.description}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="border-b-[0.5px] border-b-gray-300 my-6"></div>
              <div className="flex flex-row justify-between">
                <h1 className="text-xl font-bold">Documents</h1>
                <Button
                  appearance="primary"
                  style={{
                    backgroundColor: color,
                  }}
                  onClick={async () => {
                    try {
                      setIsLoading(true);
                      const res = await createDoc(projectId!, {
                        title:
                          "New Document #" +
                          ((project?.documents?.length ?? 0) + 1),
                        body: "{}",
                        project_id: projectId!,
                      });
                      const data = await res.json();
                      nav(`/project/${projectId}/doc/${data.data.last_id}`);
                    } catch (error) {
                      errorToast(`${error}`);
                    } finally {
                      setIsLoading(false);
                    }
                  }}
                >
                  <PiPlus />
                </Button>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-2">
                {(project?.documents ?? []).map((e) => (
                  <Link key={e.id} to={`/project/${project?.id}/doc/${e.id}`}>
                    <div
                      className="p-4 border rounded-lg flex flex-col justify-start text-white "
                      style={{ backgroundColor: color }}
                    >
                      <h1 className="text-shadow-sm mb-2">
                        <span className="text-lg font-bold">{e.title}</span>
                      </h1>
                      <div className="p-4 rounded-lg aspect-square w-full bg-white justify-center items-center flex flex-col">
                        <TiDocumentText
                          className="text-gray-300 hover:text-gray-800"
                          size={64}
                        />
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="border-b-[0.5px] border-b-gray-300 my-6"></div>
              <div className="flex flex-row justify-between">
                <h1 className="text-xl font-bold">DropBox</h1>
                <Button
                  appearance="primary"
                  style={{
                    backgroundColor: color,
                  }}
                  onClick={async () => {
                    try {
                      setIsLoading(true);
                      const res = await createDropBox(projectId!, {
                        title:
                          "New DropBox #" +
                          ((project?.drop_boxes?.length ?? 0) + 1),
                        project_id: projectId!,
                      });
                      const data = await res.json();
                      nav(
                        `/project/${projectId}/drop_box/${data.data.last_id}`
                      );
                    } catch (error) {
                      errorToast(`${error}`);
                    } finally {
                      setIsLoading(false);
                    }
                  }}
                >
                  <PiPlus />
                </Button>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-2">
                {(project?.drop_boxes ?? []).map((e) => (
                  <Link
                    key={e.id}
                    to={`/project/${project?.id}/drop_box/${e.id}`}
                  >
                    <div className="p-4 border rounded-lg flex flex-col justify-start text-white bg-gray-400 ">
                      <h1 className="text-shadow-sm mb-2">
                        <span className="text-lg font-bold">{e.title}</span>
                      </h1>
                      <div className="p-4 rounded-lg aspect-square w-full bg-white justify-center items-center flex flex-col">
                        <PiDropboxLogoLight
                          className="text-gray-300 hover:text-gray-800"
                          size={64}
                        />
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="border-b-[0.5px] border-b-gray-300 my-6"></div>
              <div className="flex flex-row justify-between">
                <h1 className="text-xl font-bold">Form</h1>
                <Button
                  appearance="primary"
                  style={{
                    backgroundColor: color,
                  }}
                  onClick={async () => {
                    setModalFormOpen(true)
                  }}
                >
                  <PiPlus />
                </Button>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-2">
                {(project?.forms ?? []).map((e) => (
                  <Link
                    key={e.id}
                    to={`/project/${project?.id}/form/${e.id}`}
                  >
                    <div className="p-4 border rounded-lg flex flex-col justify-start  bg-gray-400 ">
                     
                      <div className="rounded-lg aspect-square w-full bg-white justify-start items-center flex flex-col relative" >
                        <div className="h-2/3 w-full rounded-lg" style={{ 
                          backgroundColor: color,
                          backgroundImage: e.cover_url && `url(${e.cover_url})` ,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                         }}></div>
                         {e.picture_url && <img src={e.picture_url} className={`w-[25%] h-[25%] border-2 border-white rounded-full object-cover absolute top-[50%] left-[16%] transform -translate-x-1/2 -translate-y-1/2`} />}
                         <div className="h-1/3 w-full p-2">
                          <h1 className="font-bold text-xl">{e.title}</h1>
                          <p>{e.description}</p>
                         </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          {info()}
        </div>
      </div>
      <ModalTeam
        open={modalTeamOpen}
        onClose={() => setModalTeamOpen(false)}
        onSave={(val) => {
          setIsLoading(true);
          addTeam(projectId!, val.id)
            .then((res) => res.json())
            .then((resp) => {
              getDetail();
              setModalTeamOpen(false);
            })
            .catch(errorToast)
            .finally(() => setIsLoading(false));
        }}
      />
      {selectedTeam && (
        <ModalMember
          open={modalTeamInfo}
          onClose={() => setModalTeamInfo(false)}
          onSelectMember={() => {}}
          title={`${selectedTeam?.title}'s Members`}
          members={selectedTeam?.members ?? []}
        />
      )}

      <ModalClient
        open={modalClientOpen}
        onClose={() => setModalClientOpen(false)}
        existingClients={project?.clients ?? []}
        onSave={(val) => {
          setIsLoading(true);
          addClient(
            projectId!,
            val.map((e) => e.id)
          )
            .then((res) => res.json())
            .then((resp) => {
              getDetail();
              setModalClientOpen(false);
            })
            .catch(errorToast)
            .finally(() => setIsLoading(false));
        }}
      />
      <ModalMember
        open={modalMemberOpen}
        onClose={() => setModalMemberOpen(false)}
        existingMembers={existingMembers}
        onSave={(val) => {
          setIsLoading(true);
          addMember(
            projectId!,
            val.filter((e) => !teamMemberIds.includes(e.id)).map((e) => e.id)
          )
            .then((res) => res.json())
            .then((resp) => {
              getDetail();
              setModalClientOpen(false);
            })
            .catch(errorToast)
            .finally(() => setIsLoading(false));
        }}
        members={members}
      />
      <Modal
        size={"md"}
        open={modalBoardOpen}
        onClose={() => setModalBoardOpen(false)}
      >
        <Modal.Header>Create new board</Modal.Header>
        <Modal.Body>
          <div className="space-y-4">
            <InlineForm label="Board Name">
              <Input
                placeholder="Board name"
                value={boardName}
                onChange={(v) => setBoardName(v)}
              />
            </InlineForm>
            <InlineForm label="Board Description" alignStart>
              <textarea
                className="w-full mt-2 p-2 border rounded-lg"
                rows={9}
                placeholder="Description"
                value={boardDesc}
                onChange={(e) => setBoardDesc(e.target.value)}
              ></textarea>
            </InlineForm>
            <InlineForm label="Column" alignStart>
              <textarea
                className="w-full mt-2 p-2 border rounded-lg"
                rows={5}
                placeholder="Column"
                value={boardColumn}
                onChange={(e) => setBoardColumn(e.target.value)}
              ></textarea>
            </InlineForm>
            <InlineForm label="Color">
              <div
                className="w-16 h-16 border"
                style={{ backgroundColor: boardColor ?? color }}
                onClick={() => setModalColorOpen(true)}
              ></div>
            </InlineForm>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={async () => {
              setIsLoading(true);
              try {
                setIsLoading(true);
                const res = await createBoard({
                  title: boardName,
                  color: boardColor,
                  description: boardDesc,
                  project_id: projectId!,
                  columns: boardColumn.split("\n").map((e, i) => ({
                    title: e,
                    color: boardColor,
                    position: i,
                  })),
                  custom_fields: "[]"
                });
                const data = await res.json();
                nav(`/project/${projectId}/board/${data.data.last_id}`);
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                setIsLoading(false);
              }
            }}
          >
            Create
          </Button>
          <Button onClick={() => setModalBoardOpen(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <ModalColor
        open={modalColorOpen}
        onClose={() => setModalColorOpen(false)}
        color={boardColor}
        onChange={(val) => {
          setBoardColor(val.hex);
          setModalColorOpen(false);
        }}
      />
      <ModalForm
        isOpen={modalFormOpen}
        onClose={() => setModalFormOpen(false)}
      />
    </MainLayout>
  );
};
export default ProjectDetail;
