import type { FC } from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { BiHash } from "react-icons/bi";
import { CiCamera } from "react-icons/ci";
import { useNavigate, useParams } from "react-router-dom";
import { MultiValue } from "react-select";
import { Button, Input, Loader, Modal, Toggle, Uploader } from "rsuite";
import ChannelList from "../components/ChannelList";
import CustomAvatar from "../components/CustomAvatar";
import InlineForm from "../components/InlineForm";
import MainLayout from "../components/MainLayout";
import { Channel } from "../model/channel";
import { FileReq } from "../model/file";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { COMPANY_ID, TOKEN } from "../objects/constants";
import { CounterContext } from "../objects/counter_provider";
import { LoadingContext } from "../objects/loading";
import { ProjectContext } from "../objects/project_object";
import {
  createChannel,
  getChannel,
  getChannels,
} from "../repositories/channel";
import { errorToast, successToast } from "../utils/helper-ui";
import { asyncLocalStorage } from "../utils/storage";

interface ChannelPageProps {}

const ChannelPage: FC<ChannelPageProps> = ({}) => {
  const { counter, setCounter } = useContext(CounterContext);
  const { project: activeProject, setProject: setActiveProject } =
    useContext(ProjectContext);
  const [profile, setProfile] = useState<User>();
  const { color, setColor } = useContext(BgColorContext);
  const { channelId } = useParams();
  const [channels, setChannels] = useState<Channel[]>([]);
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState("");
  const [modalCreate, setModalCreate] = useState(false);
  const [channel, setChannel] = useState<Channel | null>(null);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState<any>();
  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FileReq[]>([]);
  const [originalFiles, setOriginalFiles] = useState<File[]>([]);
  const [recipients, setRecipients] = useState<
    MultiValue<{ value: string; label: string; email: string }>
  >([]);
  const nav = useNavigate();
  const [modalPreview, setModalPreview] = useState(false);
  const [preview, setPreview] = useState("");
  const [selectedChannel, setSelectedChannel] = useState<Channel | null>(null);
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [token, setToken] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [videoMode, setVideoMode] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = `https://8x8.vc/${process.env.REACT_APP_JITSI_KEY}/external_api.js`;
    scriptTag.addEventListener("load", () => setLoaded(true));
    document.body.appendChild(scriptTag);
  }, []);
  
  useEffect(() => {
    setMounted(true);
    asyncLocalStorage.getItem(TOKEN).then((v) => {
      setToken(v);
    });
    asyncLocalStorage.getItem(COMPANY_ID).then((v) => {
      setCompanyID(v);
    });
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getAllInboxes();
  }, [mounted]);

  const getAllInboxes = async () => {
    try {
      setIsLoading(true);
      const res = await getChannels({ page, limit, search });
      const json = await res.json();
      setChannels(json.data);
    } catch (err) {
      errorToast(`${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!mounted) return;
    if (channelId) {
      getChannel(channelId!)
        .then((res) => res.json())
        .then((v) => {
          setChannel(v.data);
          getAllInboxes();
        });
    }
  }, [channelId, mounted]);

  const renderDetail = () => (
    <div
      className=" col-span-10 h-full border-l p-4 "
      style={{ height: "calc(100vh - 50px)" }}
    >
      {channelId && <ChannelList />}
    </div>
  );
  const renderList = () => (
    <div className=" grid grid-cols-12  h-full">
      <div className=" col-span-2 h-full flex-col flex overflow-y-auto">
        <div className="p-4 flex  flex-row justify-between items-center  w-full ">
          <h1 className="font-[500]">#CHANNELS</h1>
          <button
            className="rounded-lg border bg-white flex flex-row gap-2 px-4 py-2 text-xs text-gray-600 hover:bg-gray-100 items-center "
            onClick={() => setModalCreate(true)}
          >
            <BiHash /> Create
          </button>
        </div>
        <div className="border-t"></div>
        <div className="flex-1 ">
          {channels.length == 0 && (
            <div className="flex justify-center items-center h-full">
              <div className=" rounded-full p-8 bg-gray-50">
                <img src="/images/empty.png" alt="" className=" opacity-20" />
              </div>
            </div>
          )}
          <ul>
            {channels.map((e) => (
              <li
                key={e.id}
                className="hover:bg-gray-50 border-b last:border-b-0 cursor-pointer p-2"
                onClick={() => {
                  nav(`/channel/${e.id}`);
                }}
                style={{
                  backgroundColor: e.id == channelId ? "#dedede" : "white",
                }}
              >
                <div className="p-2">
                  <div className="flex flex-row justify-between">
                    <div>
                      <div className="flex flex-row justify-between items-center gap-2">
                        <CustomAvatar
                          src={e.picture_url}
                          alt={e.name}
                          size={24}
                        />
                        <div>
                          <h3 className="font-[500] text-sm m-0 -mb-2">
                            {e.name}
                          </h3>
                          <small className="">{e.description}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {renderDetail()}
    </div>
  );
  return (
    <MainLayout
      onLoadProfile={setProfile}
      onLoadPermissions={(val) => {
        // console.log(val)
      }}
    >
      <div
        className="flex flex-col flex-1  h-full"
        style={{ backgroundColor: color }}
      >
        <div
          className="  h-full bg-white ml-4 rounded-tl-[15px]  flex flex-col"
          style={{ height: "calc(100vh - 50px)" }}
        >
          {renderList()}
        </div>
      </div>

      <Modal
        size={"lg"}
        open={modalPreview}
        onClose={() => setModalPreview(false)}
      >
        <Modal.Body>
          {modalPreview && <img src={preview} className="w-full rounded" />}
        </Modal.Body>
      </Modal>
      <Modal
        size={"lg"}
        open={modalCreate}
        onClose={() => setModalCreate(false)}
      >
        <Modal.Header>
          <Modal.Title>Create Channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-3">
              <InlineForm paddingY={20} label="Name">
                <Input value={name} onChange={(val) => setName(val)} />
              </InlineForm>
              <InlineForm paddingY={20} alignStart label="Description">
                <Input
                  as="textarea"
                  rows={5}
                  value={description}
                  onChange={(val) => setDescription(val)}
                />
              </InlineForm>

              <InlineForm paddingY={20} label="Public Channel">
                <Toggle
                  checked={isPublic}
                  onChange={(val) => setIsPublic(val)}
                />
              </InlineForm>
            </div>
            <div className="col-span-1 flex flex-row justify-center ">
              <Uploader
                fileListVisible={false}
                listType="picture"
                onUpload={(file) => {
                  setIsLoading(true);
                }}
                accept="image/*"
                action={`${process.env.REACT_APP_API_URL}/file/upload`}
                onSuccess={async (resp) => {
                  setFile(resp.data.path);
                  setFileUrl(resp.data.url);
                  setIsLoading(false);
                }}
                headers={{
                  authorization: `Bearer ${token}`,
                  "ID-Company": companyID,
                }}
                onError={() => {
                  errorToast("Upload Failed");
                }}
              >
                <button style={{ width: 160, height: 160 }}>
                  {isLoading && <Loader backdrop center />}
                  {fileUrl ? (
                    <img src={fileUrl} width="100%" height="100%" />
                  ) : (
                    <CiCamera style={{ fontSize: 80 }} />
                  )}
                </button>
              </Uploader>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={async () => {
              try {
                setIsLoading(true);
                const res = await createChannel({
                  name,
                  description,
                  is_public: isPublic,
                  picture: file,
                });
                const json = await res.json();
                getAllInboxes();
                successToast("Channel created successfully");
                setModalCreate(false);
                setName("");
                setDescription("");
                setFile("");
                setFileUrl("");
                setIsPublic(false);
                nav(`/channel/${json.data.last_id}`);
              } catch (err) {
                errorToast(`${err}`);
              } finally {
                setIsLoading(false);
              }
            }}
            appearance="ghost"
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default ChannelPage;
