import type { FC, ReactNode } from "react";
import React from "react";

interface InlineFormProps {
  label: ReactNode;
  children: React.ReactNode;
  alignStart?: boolean;
  width?: number;
  paddingY?: number;
}

const InlineForm: FC<InlineFormProps> = ({
  label,
  children,
  alignStart = false,
  width,
  paddingY,
}) => {
  return (
    <div
      className={`flex flex-row gap-4 ${
        alignStart ? "items-start" : "items-center"
      }`}
    >
      <div
        className={`w-32 font-[500] ${alignStart && "pt-1"} `}
        style={{
          width,
          paddingTop: paddingY,
          paddingBottom: paddingY,
        }}
      >
        {label}
      </div>
      <div className="flex-1">{children}</div>
    </div>
  );
};
export default InlineForm;
