import { useEffect, useState, type FC } from "react";
import { Button, Modal } from "rsuite";
import CustomAvatar from "./CustomAvatar";
import { Client } from "../model/client";
import { getClients } from "../repositories/client";
import { PiCheckBold } from "react-icons/pi";

interface ModalClientProps {
  open: boolean;
  onClose: () => void;
  existingClients: Client[];
  onSelectClient?: () => void;
  onSave?: (val: Client[]) => void;
}

const ModalClient: FC<ModalClientProps> = ({
  open,
  onClose,
  existingClients,
  onSelectClient,
  onSave,
}) => {
  const [clients, setClients] = useState<Client[]>([]);
  const [search, setSearch] = useState("");

  const [selectedClients, setSelectedClients] = useState<Client[]>([]);
  useEffect(() => {
    setSelectedClients(existingClients);
  }, [existingClients]);

  useEffect(() => {
    getClients({ page: 1, limit: 0, search })
      .then((res) => res.json())
      .then((val) => {
        setClients(val.data);
      });
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Body>
        <div className="flex flex-wrap gap-4 gap-y-4">
          {clients.map((e) => {
            let checked = false;
            if (selectedClients.map((ex) => ex.id).includes(e.id))
              checked = true;
            return (
              <div
                key={e.id}
                className={`flex flex-row gap-4 items-center py-4  px-8 rounded-3xl ${
                  checked
                    ? "bg-green-100 hover:bg-green-200"
                    : "bg-gray-100 hover:bg-gray-200"
                } cursor-pointer`}
                onClick={() => {
                  if (onSelectClient) {
                    onSelectClient();
                    return;
                  }
                  if (checked) {
                    setSelectedClients(
                      selectedClients.filter((ex) => ex.id != e.id)
                    );
                  } else {
                    setSelectedClients([...selectedClients, e]);
                  }
                }}
              >
                <CustomAvatar
                  alt={e.user?.full_name}
                  src={e.user?.picture_url}
                />
                <div className="flex flex-col">
                  <p>{e.user?.full_name}</p>
                  <small>{e.user?.email}</small>
                  {e.client_company && <small>{e.client_company?.name}</small>}
                </div>
                {checked && <PiCheckBold className="text-green-500" />}
              </div>
            );
          })}
        </div>
      </Modal.Body>
      {onSave && (
        <Modal.Footer>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
          <Button
            color="green"
            onClick={() => {
              onSave?.(selectedClients);
              onClose();
              setTimeout(() => {
                setSelectedClients([]);
              }, 300);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default ModalClient;
