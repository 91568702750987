import type { FC } from "react";
import { useContext, useEffect, useRef, useState } from "react";
import MainLayout from "../components/MainLayout";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { ProjectContext } from "../objects/project_object";
import { Company } from "../model/company";
import { useParams } from "react-router-dom";
import { LoadingContext } from "../objects/loading";
import { errorToast } from "../utils/helper-ui";
import { getCompany, updateCompany } from "../repositories/company";
import { CiCamera } from "react-icons/ci";
import { uploadFile } from "../repositories/file";
import { Avatar } from "rsuite";
import { initials } from "../utils/helper";

interface CompanyDetailProps {}

const CompanyDetail: FC<CompanyDetailProps> = ({}) => {
  const { project: activeProject, setProject: setActiveProject } =
    useContext(ProjectContext);
  const [profile, setProfile] = useState<User>();
  const { color, setColor } = useContext(BgColorContext);
  const { companyId } = useParams();
  const [company, setCompany] = useState<Company>();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const [permissions, setPermissions] = useState<string[]>([]);
  const fileRef = useRef<HTMLInputElement>(null);
  const fileRef2 = useRef<HTMLInputElement>(null);
  const [logoPic, setLogoPic] = useState("");
  const [logoPicUrl, setLogoPicUrl] = useState("");
  const [coverPic, setCoverPic] = useState("");
  const [coverPicUrl, setCoverPicUrl] = useState("");

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    getCompanyDetail();
  }, [companyId, mounted]);

  const getCompanyDetail = async () => {
    if (!mounted) return;
    try {
      setIsLoading(true);
      const res = await getCompany(companyId!);
      const resJson = await res.json();
      setCompany(resJson.data);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setLogoPic(company?.logo ?? "");
    setLogoPicUrl(company?.logo_url ?? "");
    setCoverPic(company?.cover ?? "");
    setCoverPicUrl(company?.cover_url ?? "");
  }, [company]);

  const update = async ({
    logo,
    cover,
  }: {
    logo?: string | null;
    cover?: string | null;
  }) => {
    setIsLoading(true);
    try {
      let update = await updateCompany(companyId!, {
        name: company!.name,
        address: company!.address,
        phone: company!.phone,
        logo: logo ?? company!.logo,
        cover: cover ?? company!.cover,
      });

      getCompanyDetail();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpload = async (val: any) => {
    if (!val?.target) return null;
    const input = val.target as HTMLInputElement;

    setIsLoading(true);
    try {
      const resp = await uploadFile(input?.files![0], (progress) => {
        console.log(progress);
      });
      return await resp.json();
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderCompany = () => (
    <div className="w-full mx-auto ">
      {/* Banner */}
      <div className="h-[200px] relative">
        <div className=" bg-gray-100 rounded-t-lg overflow-hidden ">
          <img
            src={
              coverPicUrl != ""
                ? coverPicUrl
                : "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
            alt="Store Banner"
            className="w-full h-40 object-cover object-center cursor-pointer"
            onClick={() => fileRef2?.current?.click()}
          />
          <div className="absolute bottom-0 left-6">
            <Avatar
              size="xl"
              src={logoPicUrl}
              alt={initials(company?.name)}
              color="violet"
              circle
              className="w-24 h-24 cursor-pointer border-4 shadow-md border-white"
              onClick={() => fileRef.current?.click()}
            />
          </div>
        </div>
      </div>

      {/* Form Section */}
      <div className="bg-white  p-8 space-y-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          Company Setting
        </h2>
        <p className="text-gray-500">Personalize Your Company Preferences</p>

        {/* Store Name */}
        <div>
          <label htmlFor="companyName" className="block text-gray-700">
            Name
          </label>
          <input
            id="companyName"
            name="companyName"
            type="text"
            className="mt-2 w-full border border-gray-300 rounded-lg p-2"
            value={company?.name}
            onChange={(e) => setCompany({ ...company!, name: e.target.value })}
          />
        </div>

        <div>
          <label htmlFor="phone" className="block text-gray-700">
            Telp
          </label>
          <input
            id="phone"
            name="phone"
            type="text"
            className="mt-2 w-full border border-gray-300 rounded-lg p-2"
            value={company?.phone}
            onChange={(e) => setCompany({ ...company!, phone: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="address" className="block text-gray-700">
            Address
          </label>
          <textarea
            id="address"
            name="address"
            rows={2}
            className="mt-2 w-full border border-gray-300 rounded-lg p-2"
            value={company?.address}
            onChange={(e) =>
              setCompany({ ...company!, address: e.target.value })
            }
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-gray-100 text-white px-6 py-2 rounded-lg hover:bg-yellow-700"
            style={{ backgroundColor: color }}
            onClick={() => update({})}
          >
            Update Company
          </button>
        </div>
      </div>
      <input
        type="file"
        accept="image/*"
        ref={fileRef}
        className="hidden"
        onChange={async (val) => {
          try {
            setIsLoading(true);
            var resp = await handleUpload(val);
            setLogoPicUrl(resp.data.url);
            await update({ logo: resp.data.path });
            getCompanyDetail();
          } catch (error) {
            errorToast(`${error}`);
          } finally {
            setIsLoading(false);
          }
        }}
      />
      <input
        type="file"
        accept="image/*"
        ref={fileRef2}
        className="hidden"
        onChange={async (val) => {
          try {
            setIsLoading(true);
            var resp = await handleUpload(val);
            setCoverPicUrl(resp.data.url);
            await update({ cover: resp.data.path });
            getCompanyDetail();
          } catch (error) {
            errorToast(`${error}`);
          } finally {
            setIsLoading(false);
          }
        }}
      />
    </div>
  );

  return (
    <MainLayout
      onLoadProfile={setProfile}
      onLoadPermissions={(val) => {
        setPermissions(val);
      }}
    >
      <div
        className="flex flex-col flex-1 overflow-x-auto  h-full"
        style={{ backgroundColor: color }}
      >
        <div
          className="  h-full bg-white ml-4 rounded-tl-[15px]  flex flex-col"
          style={{ height: "calc(100vh - 50px)" }}
        >
          {renderCompany()}
        </div>
      </div>
    </MainLayout>
  );
};
export default CompanyDetail;
