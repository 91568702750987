import { Attachment } from "@rsuite/icons";
import moment from "moment";
import type { FC } from "react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { HiOutlineArrowRight, HiPaperClip } from "react-icons/hi2";
import {
  PiCalendar,
  PiCalendarBlank,
  PiCalendarDots,
  PiChat,
  PiCheckCircle,
  PiCheckCircleFill,
  PiPassword,
  PiRadioButtonFill,
  PiUserCircle,
} from "react-icons/pi";
import {
  TbActivityHeartbeat,
  TbCheckbox,
  TbNumber123,
  TbTemplate,
} from "react-icons/tb";
import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  DateRangePicker,
  Dropdown,
  Input,
  MaskedInput,
  Modal,
  Progress,
  Radio,
  RadioGroup,
  SelectPicker,
  Tag,
  Toggle,
} from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { ActivityLog } from "../model/activity";
import { Board, Task, TaskField, TaskReq } from "../model/board";
import { FileReq } from "../model/file";
import { Member } from "../model/member";
import { ActivityLogReq } from "../model/project";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { CompanyContext } from "../objects/company_context";
import { priorityList } from "../objects/constants";
import { LoadingContext } from "../objects/loading";
import { ProjectContext } from "../objects/project_object";
import {
  addFieldTask,
  createTask,
  getTask,
  taskAddComment,
  taskAddFiles,
  updateFieldTask,
  updateTask,
} from "../repositories/task";
import {
  convertFileBase64,
  extractNumber,
  getPriorityColor,
  getTextColorBasedOnBackground,
  handleOnPaste,
  hexColorFromProgress,
  initials,
  randomStr,
} from "../utils/helper";
import { errorToast, toolTip } from "../utils/helper-ui";
import { getProfileStorage } from "../utils/storage";
import CommentBox from "./CommentBox";
import CommentList from "./CommentList";
import CustomAvatar from "./CustomAvatar";
import InlineForm from "./InlineForm";
import { getTaskDescTemplate } from "../repositories/general";
import { TemplateDesc } from "../model/task_desc";
import { LuFormInput } from "react-icons/lu";
import { FormField, FormFieldType } from "../model/form";
import { BsCheckCircleFill } from "react-icons/bs";
import { Field } from "../model/form_response";
import {
  RiDropdownList,
  RiFileUploadLine,
  RiText,
  RiTextBlock,
} from "react-icons/ri";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RxSwitch } from "react-icons/rx";
import { BiMoneyWithdraw } from "react-icons/bi";
import { createNumberMask } from "text-mask-addons";

const numberMask = createNumberMask({
  prefix: "Rp. ",
  suffix: "",
  thousandsSeparatorSymbol: ".",
  decimalSymbol: ",",
});
// import { Mention } from "quill-mention";
interface TaskDetailProps {
  board?: Board | null;
  task: Task;
  onTaskChange: (val: Task) => void;
  onForceTaskChange: (val: Task) => Promise<void>;
  onTempTaskChange: (val: Task) => void;
  onClickContributor: (task: Task, val: Member[]) => void;
  onCommentSucced: () => void;
  profile: User | undefined;
  ws?: WebSocket | null;
  onUpdateActivity: (act: ActivityLogReq) => Promise<void>;
}

const TaskDetail: FC<TaskDetailProps> = ({
  board,
  task,
  onForceTaskChange,
  onTaskChange,
  onTempTaskChange,
  onClickContributor,
  profile,
  ws,
  onCommentSucced,
  onUpdateActivity,
}) => {
  const { color, setColor } = useContext(BgColorContext);
  const { boardId, projectId } = useParams();
  const { project, setProject } = useContext(ProjectContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [selectedTask, setSelectedTask] = React.useState<Task | null>(task);
  const [isEditing, setIsEditing] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange | null>(null);
  const [modalShow, setModalShow] = useState(false);
  const { company, setCompany } = useContext(CompanyContext);
  const [selectMode, setSelectMode] = useState("comment");
  const [modalPreview, setModalPreview] = useState(false);
  const [preview, setPreview] = useState("");
  const [selectedContributors, setselectedContributors] = useState<Member[]>(
    []
  );
  const [descTemplates, setDescTemplates] = useState<TemplateDesc[]>([]);

  const [prevProgress, setPrevProgress] = useState(0);
  const [modalDesc, setModalDesc] = useState(false);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],

          [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          ["link", "image", "video"],
          ["clean"],
        ],

        history: {
          delay: 500,
          maxStack: 100,
          userOnly: true,
        },
      },
    }),
    []
  );
  // const modules = {
  //   toolbar: [
  //     [{ header: "1" }, { header: "2" }],
  //     [{ size: [] }],
  //     ["bold", "italic", "underline", "strike", "blockquote"],
  //     [
  //       { list: "ordered" },
  //       { list: "bullet" },
  //       { indent: "-1" },
  //       { indent: "+1" },
  //     ],
  //     ["link", "image", "video"],
  //     ["clean"],

  //   ],
  //   clipboard: {
  //     // toggle to add extra line breaks when pasting HTML:
  //     matchVisual: false,
  //   },
  // };

  useEffect(() => {
    if (!task) return;
    getDetail();
    getTaskDescTemplate()
      .then((res) => res.json())
      .then((v) => {
        setDescTemplates(v.data);
      });
    // console.log(task.comments)
  }, [task]);

  useEffect(() => {
    if (!ws) return;
    // ws.onmessage = async (msg: any) => {
    //   let profile = await getProfileStorage();
    //   // console.log("profile", profile);
    //   const data = JSON.parse(msg.data);
    //   // console.log(data.targetType)
    //   if (
    //     (data.targetType == "TASK" && profile?.id !== data.userId) ||
    //     data.action == "UPDATE_PROGRESS" ||
    //     data.action == "ADD_FILES"
    //   ) {
    //     // console.log("RECEIVE MESSAGE WS", data, profile?.id,data.userId, (data.action == "MOVE" && profile?.id !== data.userId) && "MASUK" );
    //     getDetail();
    //   }
    // };
  }, [ws]);

  const getDetail = () => {
    getTask(selectedTask!.id)
      .then((res) => res.json())
      .then((v) => {
        setSelectedTask(v.data);
        setPrevProgress(v.data.progress);
        if (v.data.date) {
          setDateRange([
            moment(v.data.date).toDate(),
            moment(v.data.date).toDate(),
          ]);
        }
        if (v.data.due_date) {
          setDateRange([
            moment(v.data.date).toDate(),
            moment(v.data.due_date).toDate(),
          ]);
        }
      });
  };

  const renderActivityDesc = (act: ActivityLog) => {
    const extension =
      act.activity_type == "UPLOAD_IMAGE" ? act.data.url.split(".").pop() : "";
    let is_image =
      ["jpg", "png", "jpeg", "gif", "webp"].includes(extension.toLowerCase()) ||
      act.data.is_image;
    if (act.activity_type == "UPLOAD_IMAGE")
      return is_image ? (
        <img
          src={act.data.url}
          className=" aspect-square object-cover rounded-lg border w-32 cursor-pointer"
          onClick={() => {
            setPreview(act.data.url);
            setModalPreview(true);
          }}
        />
      ) : (
        <div
          onClick={() => {
            window.open(act.data.url, "_blank");
          }}
          className=" aspect-square rounded-lg bg-gray-100 justify-center items-center flex flex-col hover:bg-gray-200 w-32 cursor-pointer"
        >
          <HiPaperClip />
        </div>
      );
    if (act.activity_type == "EDIT_CONTRIBUTOR")
      return (
        <p>
          edit contributor to:{" "}
          {act.data.map((e: Member) => (
            <span className="mr-1 italic text-sm hover:font-[500] cursor-pointer">
              {e.user?.full_name}
            </span>
          ))}
        </p>
      );
    if (act.activity_type == "UPDATE_PRIORITY")
      return (
        <div className="flex flex-row gap-2 items-center">
          <Tag
            style={{
              backgroundColor: getPriorityColor(act.pre_condition.priority),
              color: getTextColorBasedOnBackground(
                getPriorityColor(act.pre_condition.priority)
              ),
            }}
          >
            {act.pre_condition.priority}
          </Tag>
          <HiOutlineArrowRight />
          <Tag
            style={{
              backgroundColor: getPriorityColor(act.data.priority),
              color: getTextColorBasedOnBackground(
                getPriorityColor(act.data.priority)
              ),
            }}
          >
            {act.data.priority}
          </Tag>
        </div>
      );
    if (act.activity_type == "MOVE_TASK")
      return (
        <div className="flex flex-row gap-2 items-center">
          <Tag
            style={{
              backgroundColor: act.pre_condition.color,
              color: getTextColorBasedOnBackground(act.pre_condition.color),
            }}
          >
            {act.pre_condition.title}
          </Tag>
          <HiOutlineArrowRight />
          <Tag
            style={{
              backgroundColor: act.data.color,
              color: getTextColorBasedOnBackground(act.data.color),
            }}
          >
            {act.data.title}
          </Tag>
        </div>
      );
    if (act.activity_type == "MOVE_BOARD_TASK")
      return (
        <div className="flex flex-row gap-2 items-center">
          <Tag
            style={{
              backgroundColor: act.pre_condition.color,
              color: getTextColorBasedOnBackground(act.pre_condition.color),
            }}
          >
            {act.pre_condition.column_name}
          </Tag>
          <HiOutlineArrowRight />
          <Tag
            style={{
              backgroundColor: act.data.color,
              color: getTextColorBasedOnBackground(act.data.color),
            }}
          >
            {act.data.column_name}
          </Tag>
        </div>
      );
    if (act.activity_type == "UPDATE_PROGRESS") {
      if (act.data.progress == 100) {
        return (
          <Tag
            style={{
              backgroundColor: hexColorFromProgress(act.data.progress),
              color: getTextColorBasedOnBackground(
                hexColorFromProgress(act.data.progress)
              ),
            }}
          >
            Completed
          </Tag>
        );
      }
      return (
        <div className="flex flex-row gap-2 items-center">
          <Tag
            style={{
              backgroundColor: hexColorFromProgress(act.pre_condition.progress),
              color: getTextColorBasedOnBackground(
                hexColorFromProgress(act.pre_condition.progress)
              ),
            }}
          >
            {act.pre_condition.progress}%
          </Tag>
          <HiOutlineArrowRight />
          <Tag
            style={{
              backgroundColor: hexColorFromProgress(act.data.progress),
              color: getTextColorBasedOnBackground(
                hexColorFromProgress(act.data.progress)
              ),
            }}
          >
            {act.data.progress}%
          </Tag>
        </div>
      );
    }

    return <div></div>;
  };

  const renderIcon = (e: FormFieldType, size: number) => {
    switch (e) {
      case FormFieldType.TextField:
        return <RiText size={size} />;
      case FormFieldType.TextArea:
        return <RiTextBlock size={size} />;
      case FormFieldType.RadioButton:
        return <PiRadioButtonFill size={size} />;
      case FormFieldType.Checkbox:
        return <TbCheckbox size={size} />;
      case FormFieldType.DatePicker:
        return <PiCalendar size={size} />;
      case FormFieldType.DateRangePicker:
        return <PiCalendarDots size={size} />;
      case FormFieldType.NumberField:
        return <TbNumber123 size={size} />;
      case FormFieldType.EmailField:
        return <MdOutlineAlternateEmail size={size} />;
      case FormFieldType.PasswordField:
        return <PiPassword size={size} />;
      case FormFieldType.FileUpload:
        return <RiFileUploadLine size={size} />;
      case FormFieldType.ToggleSwitch:
        return <RxSwitch size={size} />;
      case FormFieldType.Dropdown:
        return <RiDropdownList size={size} />;
      case FormFieldType.Currency:
        return <BiMoneyWithdraw size={size} />;
    }

    return null;
  };

  const renderFormResponse = () => {
    let fields: FormField[] = [];
    let values: Field[] = [];
    for (const s of selectedTask?.form_response?.form.sections ?? []) {
      for (const f of s.fields) {
        fields.push(f);
      }
    }
    for (const s of selectedTask?.form_response?.sections ?? []) {
      for (const f of s.fields) {
        values.push(f);
      }
    }
    return (
      <div>
        {values.map((f, i) => {
          let value;
          value = f.value;

          if (f.type == FormFieldType.FileUpload) {
            const ext = f.url.split(".").pop();
            let is_image = ["jpg", "png", "jpeg", "gif", "webp"].includes(
              ext!.toLowerCase()
            );
            value = (
              <div
                className=" aspect-square rounded-lg bg-gray-100 justify-center items-center flex flex-col hover:bg-gray-200 w-16 cursor-pointer p-2"
                key={f.id}
                onClick={() => {
                  if (is_image) {
                    setPreview(f.url);
                    setModalPreview(true);
                  } else {
                    window.open(f.url, "_blank");
                  }
                }}
              >
                {is_image ? <img src={f.url} /> : <HiPaperClip size={24} />}
              </div>
            );
          }
          if (
            f.type == FormFieldType.RadioButton ||
            f.type == FormFieldType.Dropdown
          ) {
            value = (fields[i]?.options ?? []).find(
              (o) => o.value == f.value
            )?.label;
          }
          if (f.type == FormFieldType.ToggleSwitch) {
            value = f.value && <BsCheckCircleFill className="text-green-400" />;
          }
          if (f.type == FormFieldType.DatePicker) {
            value = (
              <small>{moment(f.value).format("DD MMM YYYY, HH:mm:ss")}</small>
            );
          }
          if (f.type == FormFieldType.DateRangePicker) {
            value = (
              <small>
                {moment(f.value[0]).format("DD MMM YYYY, HH:mm:ss")}
                {" ~ "}
                {moment(f.value[1]).format("DD MMM YYYY, HH:mm:ss")}
              </small>
            );
          }
          if (f.type == FormFieldType.Checkbox) {
            value = (
              <ul className=" list-disc ml-4">
                {(f.value ?? []).map((e: any) => (
                  <li key={e}>
                    {
                      (fields[i]?.options ?? []).find((o) => o.value == e)
                        ?.label
                    }
                  </li>
                ))}
              </ul>
            );
          }
          return (
            <InlineForm paddingY={10} key={f.id} label={fields[i].label}>
              {value}
            </InlineForm>
          );
        })}
      </div>
    );
  };
  const renderActivities = () => (
    <ul className="px-2">
      {(selectedTask?.activity_logs ?? []).map((e) => {
        return (
          <li key={e.id}>
            <div className="flex flex-row space-x-2   py-2 w-full gap-2">
              <div className="py-2">
                <CustomAvatar
                  key={e.creator.user.full_name}
                  src={e.creator.user.picture_url}
                  alt={e.creator.user.full_name}
                />
              </div>
              <div className="flex flex-col gap-y-2 flex-1  justify-center">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row space-x-2">
                    <span>{e.creator.user.full_name}</span>
                    <span className="font-[500]">
                      {e.activity_type.replaceAll("_", " ")}
                    </span>
                  </div>
                  <span className="text-xs italic">
                    {moment(e.created_at).fromNow()}
                  </span>
                </div>
                <div>{renderActivityDesc(e)}</div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );

  useEffect(() => {
    if (!dateRange) return;

    setSelectedTask({
      ...selectedTask!,
      date: moment(dateRange![0]).toISOString(),
      due_date: moment(dateRange![1]).toISOString(),
    });
  }, [dateRange]);

  const renderField = (f: TaskField) => {
    const update = (f1?: TaskField | null) => {
      if (f1) {
        f = { ...f1 };
      }
      updateFieldTask(selectedTask!.id, f.id, {
        task_id: selectedTask!.id,
        label: f.label,
        type: f.type,
        is_multi: f.is_multi,
        value: {
          ...f.value,
          isMulti: f.is_multi,
        },
        options: f.options,
      }).then(() => {
        onUpdateActivity({
          company_id: company!.id,
          project_id: project!.id,
          board_id: boardId!,
          task_id: selectedTask!.id,
          activity_type: "UPDATE_CUSTOM_FIELDS",
          context_type: "TASK",
          pre_condition: "{}",
          data: JSON.stringify({
            task_id: selectedTask!.id,
            label: f.label,
            type: f.type,
            is_multi: f.is_multi,
            value: {
              ...f.value,
              isMulti: f.is_multi,
            },
            options: f.options,
          }),
        }).then(() => getDetail());
      });
    };
    switch (f.type) {
      case FormFieldType.TextField:
        return (
          <div>
            <input
              className="focus:ring-0 focus:outline-none focus:ring-gray-200 border rounded-lg py-1 px-2"
              value={(f.value.value as string) ?? ""}
              onChange={(val) => {
                setSelectedTask({
                  ...selectedTask!,
                  fields: [
                    ...selectedTask!.fields.map((f1) => {
                      if (f1.id == f.id) {
                        return {
                          ...f1,
                          value: { ...f1.value, value: val.target.value },
                        };
                      }
                      return f1;
                    }),
                  ],
                });
              }}
              onBlur={() => {
                update();
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  update();
                }
              }}
            />
          </div>
        );

      case FormFieldType.RadioButton:
        return (
          <div>
            <RadioGroup
              value={f.value.value}
              name="radio-group"
              defaultValue="A"
            >
              {f.options.map((e) => (
                <Radio
                  key={e.value}
                  value={e.value}
                  onClick={(val) => {
                    setSelectedTask({
                      ...selectedTask!,
                      fields: [
                        ...selectedTask!.fields.map((f1) => {
                          if (f1.id == f.id) {
                            return {
                              ...f1,
                              value: { ...f1.value, value: e.value },
                            };
                          }
                          return f1;
                        }),
                      ],
                    });
                  }}
                  onBlur={() => {
                    update();
                  }}
                >
                  {e.label}
                </Radio>
              ))}
            </RadioGroup>
          </div>
        );
      case FormFieldType.Checkbox:
        return (
          <div>
            <CheckboxGroup
              value={f.value.value as string[]}
              name="checkbox-group"
            >
              {f.options.map((e) => (
                <Checkbox
                  key={e.value}
                  value={e.value}
                  onChange={(val, checked) => {
                    let checkboxVal = (f.value.value as string[]) ?? [];
                    if ((checkboxVal ?? []).includes(e.value)) {
                      checkboxVal = checkboxVal.filter((v) => v !== e.value);
                    } else {
                      checkboxVal = [...checkboxVal, e.value];
                    }

                    setSelectedTask({
                      ...selectedTask!,
                      fields: [
                        ...selectedTask!.fields.map((f1) => {
                          if (f1.id == f.id) {
                            return {
                              ...f1,
                              value: { ...f1.value, value: checkboxVal },
                            };
                          }
                          return f1;
                        }),
                      ],
                    });
                  }}
                  onBlur={() => {
                    update();
                  }}
                >
                  {e.label}
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>
        );
      case FormFieldType.DatePicker:
        return (
          <div>
            <DatePicker
              block
              format="dd MMM yyyy"
              value={moment(f.value.value as string).toDate()}
              onChange={(val) => {
                setSelectedTask({
                  ...selectedTask!,
                  fields: [
                    ...selectedTask!.fields.map((f1) => {
                      if (f1.id == f.id) {
                        return {
                          ...f1,
                          value: { ...f1.value, value: val?.toISOString() },
                        };
                      }
                      return f1;
                    }),
                  ],
                });
                update({
                  ...f,
                  value: { ...f.value, value: val?.toISOString() },
                });
              }}
            />
          </div>
        );
      case FormFieldType.DateRangePicker:
        let dateValue: [Date, Date] = [moment().toDate(), moment().toDate()];
        if (((f.value.value as string[]) ?? []).length == 2) {
          dateValue = (f.value.value as string[]).map((e) =>
            e ? moment(e).toDate() : null
          ) as [Date, Date];
        }
        return (
          <div>
            <DateRangePicker
              format="dd MMM yyyy"
              placement="bottomEnd"
              value={dateValue}
              onChange={(val) => {
                setSelectedTask({
                  ...selectedTask!,
                  fields: [
                    ...selectedTask!.fields.map((f1) => {
                      if (f1.id == f.id) {
                        return {
                          ...f1,
                          value: { ...f1.value, value: [val![0].toISOString(), val![1].toISOString()] },
                        };
                      }
                      return f1;
                    }),
                  ],
                });
                update({
                  ...f,
                  value: { ...f.value, value: [val![0].toISOString(), val![1].toISOString()] },
                });
              }}
            />
          </div>
        );
      case FormFieldType.Currency:
        return (
          <MaskedInput
            mask={numberMask}
            value={f.value.value ?? 0}
            onChange={(val, e) => {
              setSelectedTask({
                ...selectedTask!,
                fields: [
                  ...selectedTask!.fields.map((f1) => {
                    if (f1.id == f.id) {
                      return {
                        ...f1,
                        value: { ...f1.value, value: extractNumber(val) },
                      };
                    }
                    return f1;
                  }),
                ],
              });
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                update();
              }
            }}
          />
        );
      case FormFieldType.NumberField:
        return (
          <div>
            <input
              className="focus:ring-0 focus:outline-none focus:ring-gray-200 border rounded-lg py-1 px-2"
              type="number"
              value={(f.value.value as number) ?? 0}
              onChange={(val) => {
                setSelectedTask({
                  ...selectedTask!,
                  fields: [
                    ...selectedTask!.fields.map((f1) => {
                      if (f1.id == f.id) {
                        return {
                          ...f1,
                          value: { ...f1.value, value: val.target.value },
                        };
                      }
                      return f1;
                    }),
                  ],
                });
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  update();
                }
              }}
            />
          </div>
        );
      case FormFieldType.ToggleSwitch:
        return (
          <div>
            <Toggle
              checked={f.value.value as boolean}
              onChange={(val) => {
                setSelectedTask({
                  ...selectedTask!,
                  fields: [
                    ...selectedTask!.fields.map((f1) => {
                      if (f1.id == f.id) {
                        return {
                          ...f1,
                          value: { ...f1.value, value: val },
                        };
                      }
                      return f1;
                    }),
                  ],
                });
                update({
                  ...f,
                  value: { ...f.value, value: val },
                });
              }}
              onBlur={() => update()}
            />
          </div>
        );
      case FormFieldType.Dropdown:
        return (
          <div>
            <SelectPicker
              value={f.value.value as string}
              style={{ minWidth: 300 }}
              searchable={false}
              data={f.options}
              onChange={(val) => {
                setSelectedTask({
                  ...selectedTask!,
                  fields: [
                    ...selectedTask!.fields.map((f1) => {
                      if (f1.id == f.id) {
                        return {
                          ...f1,
                          value: { ...f1.value, value: val },
                        };
                      }
                      return f1;
                    }),
                  ],
                });
              }}
              onBlur={() => update()}
            />
          </div>
        );
    }
    return null;
  };

  // const handleOnPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
  //   const items = (event.clipboardData || event.nativeEvent.clipboardData)
  //     .items;
  //   let blob: Blob | null = null;
  //   for (let i = 0; i < items.length; i++) {
  //     if (items[i].type.indexOf("image") === 0) {
  //       blob = items[i].getAsFile() as Blob;
  //     }
  //   }
  //   if (blob !== null) {
  //     uploadFile(new File([blob], "from paste"), (val) => {})
  //       .then((v) => v.json())
  //       .then((v) => {
  //         setIsLoading(true);
  //         v.data.is_image = true;
  //         v.data.caption = "from paste";
  //         taskAddFiles(selectedTask!.id, [v.data]).then((v) => {
  //           if (ws) {
  //             ws.send(
  //               JSON.stringify({
  //                 type: "UPDATE",
  //                 action: "ADD_FILES",
  //                 targetId: selectedTask?.id,
  //                 targetType: "TASK",
  //                 userId: profile?.id,
  //               })
  //             );
  //           }
  //         });
  //       });
  //   }
  // };
  return (
    <div
      onPaste={(event) =>
        handleOnPaste(
          event,
          (progress: number) => {},
          (v: { data: FileReq }) => {
            setIsLoading(true);
            let data: FileReq = v.data;
            data.is_image = true;
            data.caption = "from paste";
            taskAddFiles(selectedTask!.id, [data])
              .then((v) => {
                onUpdateActivity({
                  company_id: company!.id,
                  project_id: project!.id,
                  board_id: boardId!,
                  task_id: selectedTask!.id,
                  activity_type: "UPLOAD_IMAGE",
                  context_type: "TASK",
                  pre_condition: "{}",
                  data: JSON.stringify(data),
                }).then(() => getDetail());
              })
              .then(() => {
                setSelectMode("file");
              })
              .finally(() => setIsLoading(false));
          }
        )
      }
      className="h-full w-full flex flex-col"
    >
      <div className="flex flex-col space-y-4 flex-1 overflow-y-auto">
        <input
          type="text"
          value={selectedTask?.title}
          className="focus:ring-0 focus:outline-none focus:ring-gray-200 text-4xl font-[500] text-gray-700"
          disabled={company?.is_client}
          onChange={(e) =>
            setSelectedTask({ ...selectedTask!, title: e.target.value })
          }
          onBlur={() => {
            // onTaskChange(selectedTask!);
            onTempTaskChange(selectedTask!);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // onTaskChange(selectedTask!);
              onTempTaskChange(selectedTask!);
            }
          }}
        />
        <InlineForm label={"Tgl"}>
          <DateRangePicker
            classPrefix="custom-date"
            format="dd MMM yyyy"
            disabled={company?.is_client}
            value={dateRange}
            onChange={(val) => {
              // onTaskChange({
              //   ...selectedTask!,
              //   date: moment(val![0]).toISOString(),
              //   due_date: moment(val![1]).toISOString(),
              // });
              onTempTaskChange({
                ...selectedTask!,
                date: moment(val![0]).toISOString(),
                due_date: moment(val![1]).toISOString(),
              });
              setDateRange(val);
            }}
            onSubmit={() => {
              // onTaskChange(selectedTask!);
              onTempTaskChange(selectedTask!);
            }}
          />
        </InlineForm>
        <div className="grid grid-cols-2 gap-4 items-start">
          <InlineForm label={"Creator"}>
            <div className="px-2 flex flex-row items-center gap-4">
              <Avatar
                size="xs"
                bordered
                circle
                key={selectedTask?.reported.user.full_name}
                src={selectedTask?.reported.user.picture_url}
                alt={initials(selectedTask?.reported.user.full_name)}
                color="violet"
              />
              {selectedTask?.reported.user.full_name}
            </div>
          </InlineForm>
          <InlineForm label={"Contributors"} alignStart>
            <div className="px-2 flex flex-row items-center gap-4">
              <div className="flex flex-col items-start gap-4">
                {selectedTask?.contributors.map((e) => (
                  <div
                    className="px-2 flex flex-row items-center gap-4"
                    key={e.id}
                  >
                    <Avatar
                      size="xs"
                      bordered
                      circle
                      key={e.user.full_name}
                      src={e.user.picture_url}
                      alt={initials(e.user.full_name)}
                      color="violet"
                    />
                    {e.user.full_name}
                  </div>
                ))}
                {!company?.is_client && (
                  <>
                    {toolTip(
                      "Add Contributor",
                      <div
                        className="px-2 py-1 text-xs text-blue-400 hover:text-blue-700 hover:border-blue-700 cursor-pointer hover:bg-blue-50 rounded-full border-blue-400 border flex flex-row items-center"
                        onClick={() =>
                          onClickContributor(task, selectedTask!.contributors)
                        }
                      >
                        <PiUserCircle className="mr-1" /> Contributors
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </InlineForm>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <InlineForm label={"Priority"}>
            {company?.is_client ? (
              <div
                className="w-32 text-center py-1 px-2 text-sm cursor-pointer rounded-lg h-full"
                style={{
                  backgroundColor: getPriorityColor(selectedTask?.priority),
                  color: getTextColorBasedOnBackground(
                    getPriorityColor(selectedTask?.priority)
                  ),
                }}
              >
                {selectedTask?.priority}
              </div>
            ) : (
              <Dropdown
                className="custom-menu"
                title={
                  <div
                    className="w-32 text-center py-1 px-2 text-sm cursor-pointer rounded-lg h-full"
                    style={{
                      backgroundColor: getPriorityColor(selectedTask?.priority),
                      color: getTextColorBasedOnBackground(
                        getPriorityColor(selectedTask?.priority)
                      ),
                    }}
                  >
                    {selectedTask?.priority}
                  </div>
                }
                noCaret
                menuStyle={{ width: 240 }}
                placement="bottomStart"
                trigger={"click"}
              >
                {priorityList.map((e) => (
                  <Dropdown.Item
                    key={e}
                    onClick={() => {
                      let newData = {
                        ...selectedTask!,
                        priority: e as
                          | "Lowest"
                          | "Low"
                          | "Medium"
                          | "High"
                          | "Highest",
                      };
                      // onTempTaskChange(newData);
                      onForceTaskChange(newData);
                      onUpdateActivity({
                        company_id: company!.id,
                        project_id: project!.id,
                        board_id: boardId,
                        task_id: selectedTask?.id,
                        activity_type: "UPDATE_PRIORITY",
                        context_type: "TASK",
                        pre_condition: JSON.stringify({
                          priority: selectedTask?.priority ?? "Lowest",
                        }),
                        data: JSON.stringify({
                          priority: newData.priority,
                        }),
                      }).then((res) => {
                        getDetail();
                      });
                    }}
                  >
                    <div className="flex flex-row items-center gap-2">
                      <div
                        className="w-3 h-3 rounded-full"
                        style={{
                          backgroundColor: getPriorityColor(e),
                          color: getTextColorBasedOnBackground(
                            getPriorityColor(e)
                          ),
                        }}
                      ></div>
                      {e}
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown>
            )}
          </InlineForm>
          <InlineForm label={"Progress"}>
            <div
              className="relative"
              style={{
                width: 48,
              }}
            >
              <Progress.Circle
                percent={selectedTask?.progress}
                showInfo={false}
                strokeColor={hexColorFromProgress(selectedTask?.progress)}
                strokeWidth={10}
              />
              <div
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-row justify-center items-center "
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                }}
              >
                <input
                  className="focus:ring-0 focus:outline-none focus:ring-gray-200  max-w-6 text-right bg-transparent text-xs"
                  disabled={company?.is_client || selectedTask!.is_completed}
                  onChange={(val) => {
                    let progress = parseInt(val.target.value);
                    if (progress > 100) {
                      progress = 100;
                    }
                    setSelectedTask((prev) => {
                      let newData = {
                        ...selectedTask!,
                        progress: progress,
                      };

                      // setPrevProgress(prev?.progress ?? 0);

                      return newData;
                    });
                  }}
                  value={selectedTask?.progress ?? 0}
                  onBlur={() => {
                    onForceTaskChange({
                      ...selectedTask!,
                    });
                    onTempTaskChange({
                      ...selectedTask!,
                    });

                    onUpdateActivity({
                      company_id: company!.id,
                      project_id: project!.id,
                      board_id: boardId!,
                      task_id: selectedTask!.id,
                      activity_type: "UPDATE_PROGRESS",
                      context_type: "TASK",
                      pre_condition: JSON.stringify({
                        progress: prevProgress ?? 0,
                      }),
                      data: JSON.stringify({
                        progress: selectedTask!.progress,
                      }),
                    }).then((res) => {
                      getDetail();
                    });
                  }}
                />
                <span className="text-xs">%</span>
              </div>
            </div>
          </InlineForm>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <InlineForm label={"Group"}>
            {company?.is_client ? (
              <Button
                size="sm"
                className="w-32 truncate"
                title={selectedTask?.column?.title}
                style={{
                  backgroundColor: selectedTask?.column?.color,
                  color: getTextColorBasedOnBackground(
                    selectedTask?.column?.color
                  ),
                }}
              >
                {selectedTask?.column?.title}
              </Button>
            ) : (
              <Dropdown
                className="custom-menu"
                title={
                  <div
                    className="w-32 text-center py-1 px-2 text-sm cursor-pointer rounded-lg h-full truncate"
                    style={{
                      backgroundColor: selectedTask?.column?.color,
                      color: getTextColorBasedOnBackground(
                        selectedTask?.column?.color
                      ),
                    }}
                  >
                    {selectedTask?.column?.title}
                  </div>
                }
                noCaret
                menuStyle={{ width: 240 }}
                placement="bottomStart"
                trigger={"click"}
              >
                {(selectedTask?.column?.board?.columns ?? []).map((e) => (
                  <Dropdown.Item
                    key={e.id}
                    onClick={() => {}}
                    style={{ padding: 5 }}
                  >
                    <div
                      className="p-2 rounded-lg opacity-70 hover:opacity-100"
                      style={{
                        backgroundColor: e.color,
                        color: getTextColorBasedOnBackground(e.color),
                      }}
                      onClick={() => {
                        // let newData = {
                        //   ...selectedTask!,
                        //   column_id: e.id,
                        // };
                        // console.log(e.title)
                        updateTask(selectedTask!.id, {
                          title: selectedTask!.title,
                          priority: selectedTask!.priority,
                          type: selectedTask!.type,
                          progress: selectedTask!.progress,
                          dependencies: selectedTask!.dependencies,
                          contributors: selectedTask!.contributors,
                          column_id: e.id,
                          position: selectedTask!.position,
                          date: selectedTask!.date,
                          due_date: selectedTask!.due_date,
                          is_completed: selectedTask?.is_completed ?? false,
                          description: JSON.stringify(
                            selectedTask!.description
                          ),
                        }).then((res) => {
                          onUpdateActivity({
                            company_id: company!.id,
                            project_id: project!.id,
                            board_id: boardId,
                            task_id: selectedTask?.id,
                            activity_type: "MOVE_BOARD_TASK",
                            context_type: "TASK",
                            pre_condition: JSON.stringify({
                              column_id: selectedTask?.column_id,
                              column_name: selectedTask?.column?.title,
                              color: selectedTask?.column?.color,
                            }),
                            data: JSON.stringify({
                              column_id: e?.id,
                              column_name: e?.title,
                              color: e?.color,
                            }),
                          }).then(() => {
                            getDetail();
                          });
                        });
                      }}
                    >
                      {e.title}
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown>
            )}
          </InlineForm>
          <Dropdown
            className="custom-menu"
            title={
              <div className="px-2 py-1 text-xs text-blue-400 hover:text-blue-700 hover:border-blue-700 cursor-pointer hover:bg-blue-50 rounded-full border-blue-400 border flex flex-row items-center w-fit">
                <LuFormInput className="mr-1" /> Field
              </div>
            }
            noCaret
            menuStyle={{ width: 240 }}
            placement="bottomStart"
            trigger={"click"}
          >
            {(board?.custom_fields ?? []).map((e) => (
              <Dropdown.Item
                key={e.id}
                onClick={() => {
                  addFieldTask(selectedTask!.id, {
                    task_id: selectedTask!.id,
                    label: e.label,
                    type: e.type,
                    is_multi: e.is_multi,
                    value: {
                      id: randomStr(20),
                      value: null,
                      isMulti: e.is_multi,
                      type: e.type,
                    },
                    options: e.options,
                  }).then(() => {
                    onUpdateActivity({
                      company_id: company!.id,
                      project_id: project!.id,
                      board_id: boardId!,
                      task_id: selectedTask!.id,
                      activity_type: "ADD_CUSTOM_FIELDS",
                      context_type: "TASK",
                      pre_condition: "{}",
                      data: JSON.stringify({
                        task_id: selectedTask!.id,
                        label: e.label,
                        type: e.type,
                        is_multi: e.is_multi,
                        value: {
                          id: randomStr(20),
                          value: null,
                          isMulti: e.is_multi,
                          type: e.type,
                        },
                        options: e.options,
                      }),
                    }).then(() => getDetail());
                  });
                }}
              >
                <div className="flex flex-row gap-2">
                  {renderIcon(e.type, 16)} <span>{e.label}</span>
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {(selectedTask?.fields ?? []).map((e) => (
            <InlineForm
              alignStart
              label={
                <div className="flex flex-row gap-2 items-center">
                  {renderIcon(e.type, 16)} {e.label}
                </div>
              }
              key={e.id}
            >
              {renderField(e)}
            </InlineForm>
          ))}
        </div>
        <h3 className="font-[500] text-xl">Description</h3>
        <div className="mb-8 relative group/item">
          <ReactQuill
            modules={company?.is_client ? { toolbar: false } : modules}
            theme="snow"
            readOnly={company?.is_client}
            value={selectedTask?.description}
            onChange={(val, delta, s, editor) => {
              if (company?.is_client) {
                return;
              }
              // console.log();
              let newTask = {
                ...selectedTask!,
                description: editor.getContents(),
                description_html: val,
              };
              setSelectedTask(newTask);
              onTempTaskChange(newTask);
            }}
          />
          <div className=" absolute bottom-2 right-2">
            <Dropdown
              noCaret
              placement="topEnd"
              trigger={"click"}
              title={
                <div
                  className="group/edit invisible group-hover/item:visible px-4 py-1 text-xs rounded-lg bg-gray-50 border cursor-pointer hover:bg-green-500"
                  onClick={() => {}}
                >
                  <TbTemplate />
                </div>
              }
              className="custom-menu"
            >
              {descTemplates.map((e) => (
                <Dropdown.Item
                  key={e.id}
                  onClick={() => {
                    let newTask = {
                      ...selectedTask!,
                      description: descTemplates.find((val) => val.id == e.id)
                        ?.content,
                    };
                    setSelectedTask(newTask);
                    onTempTaskChange(newTask);
                  }}
                >
                  {e.name}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
        </div>
        <div className="mb-8 flex flex-col">
          {(selectedTask?.subtasks ?? []).length > 0 && (
            <h3 className="font-[500] text-lg mb-2">Sub Task</h3>
          )}
          <ul>
            {(selectedTask?.subtasks ?? []).map((e, i) => (
              <li
                key={e.id}
                className="flex flex-row items-center gap-2 py-2 px-2 hover:bg-gray-100 cursor-pointer border-t justify-between"
              >
                <div className="flex flex-row items-center gap-2">
                  {toolTip(
                    !(e?.is_completed ?? false)
                      ? "Complete Task"
                      : "Task Completed",
                    <div
                      className=""
                      onClick={() => {
                        let subtask = selectedTask!.subtasks.find(
                          (s) => s.id == e.id
                        );
                        setSelectedTask({
                          ...selectedTask!,
                          subtasks: [
                            ...selectedTask!.subtasks.map((s) => {
                              if (s.id == e.id) {
                                s.is_completed = !s.is_completed;
                              }
                              return s;
                            }),
                          ],
                        });
                        updateTask(subtask?.id!, {
                          title: subtask!.title,
                          priority: subtask!.priority,
                          type: subtask!.type,
                          progress: subtask!.progress,
                          dependencies: subtask!.dependencies,
                          contributors: subtask!.contributors,
                          column_id: subtask!.column_id,
                          position: subtask!.position,
                          date: subtask!.date,
                          due_date: subtask!.due_date,
                          is_completed: subtask?.is_completed ?? false,
                          description: JSON.stringify(subtask!.description),
                        }).then(() => getDetail());
                      }}
                    >
                      {!e?.is_completed ? (
                        <PiCheckCircle className=" hover:text-green-500" />
                      ) : (
                        <PiCheckCircleFill className=" text-green-500" />
                      )}
                    </div>,
                    "rightStart"
                  )}
                  <input
                    className="bg-transparent focus:ring-0 focus:outline-none focus:ring-gray-200"
                    type="text"
                    value={e.title}
                    onChange={(val) =>
                      setSelectedTask({
                        ...selectedTask!,
                        subtasks: [
                          ...selectedTask!.subtasks.map((s) => {
                            if (s.id == e.id) {
                              s.title = val.target.value;
                            }
                            return s;
                          }),
                        ],
                      })
                    }
                    onBlur={() => {
                      let subtask = selectedTask!.subtasks.find(
                        (s) => s.id == e.id
                      );
                      updateTask(subtask?.id!, {
                        title: subtask!.title,
                        priority: subtask!.priority,
                        type: subtask!.type,
                        progress: subtask!.progress,
                        dependencies: subtask!.dependencies,
                        contributors: subtask!.contributors,
                        column_id: subtask!.column_id,
                        position: subtask!.position,
                        date: subtask!.date,
                        due_date: subtask!.due_date,
                        is_completed: subtask?.is_completed ?? false,
                        description: JSON.stringify(subtask!.description),
                      }).then(() => getDetail());
                    }}
                  />
                </div>
                <DueDate
                  task={e}
                  onChange={(val) => {
                    let subtask = selectedTask!.subtasks.find(
                      (s) => s.id == e.id
                    );
                    setSelectedTask({
                      ...selectedTask!,
                      subtasks: [
                        ...selectedTask!.subtasks.map((s) => {
                          if (s.id == e.id) {
                            s.due_date = val.toISOString();
                          }
                          return s;
                        }),
                      ],
                    });
                    updateTask(subtask?.id!, {
                      title: subtask!.title,
                      priority: subtask!.priority,
                      type: subtask!.type,
                      progress: subtask!.progress,
                      dependencies: subtask!.dependencies,
                      contributors: subtask!.contributors,
                      column_id: subtask!.column_id,
                      position: subtask!.position,
                      date: subtask!.date,
                      due_date: val.toISOString(),
                      description: JSON.stringify(subtask!.description),
                      is_completed: subtask?.is_completed ?? false,
                    }).then(() => getDetail());
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
        <div
          className="px-4 py-1 text-center border rounded-lg hover:bg-gray-100 items-center w-32  justify-center gap-2 cursor-pointer text-xs"
          onClick={() => {
            let req: TaskReq = {
              title: "New Sub Task",
              priority: "Low",
              type: "Other",
              description: "{}",
              date: moment().toISOString(),
              progress: 0,
              dependencies: [],
              contributors: [],
              parent_task_id: selectedTask?.id,
              column_id: selectedTask?.column_id!,
              position: (selectedTask?.subtasks ?? []).length + 1,
            };
            createTask(req).then((res) => {
              onUpdateActivity({
                company_id: company!.id,
                project_id: project!.id,
                board_id: boardId!,
                task_id: selectedTask!.id,
                activity_type: "ADD_SUB_TASK",
                context_type: "TASK",
                pre_condition: "{}",
                data: "{}",
              });
              getDetail();
            });
          }}
        >
          Add Sub Task
        </div>
        <div className="flex flex-row px-4 border-b">
          <div
            className="flex flex-row gap-1 justify-center items-center px-8 cursor-pointer  border-b-2 py-2"
            style={{
              borderBottomColor: selectMode == "comment" ? color : undefined,
              borderBottomWidth: selectMode == "comment" ? 2 : 0.5,
            }}
            onClick={() => setSelectMode("comment")}
          >
            <PiChat />
            <span>Comment</span>
          </div>

          <div
            className="flex flex-row gap-1 justify-center items-center px-8 cursor-pointer  border-b-2 py-2"
            style={{
              borderBottomColor: selectMode == "file" ? color : undefined,
              borderBottomWidth: selectMode == "file" ? 2 : 0.5,
            }}
            onClick={() => setSelectMode("file")}
          >
            <Attachment />
            <span>File</span>
          </div>
          <div
            className="flex flex-row gap-1 justify-center items-center px-8 cursor-pointer  border-b-2 py-2"
            style={{
              borderBottomColor: selectMode == "activity" ? color : undefined,
              borderBottomWidth: selectMode == "activity" ? 2 : 0.5,
            }}
            onClick={() => setSelectMode("activity")}
          >
            <TbActivityHeartbeat />
            <span>Activities</span>
          </div>
          {selectedTask?.form_response && (
            <div
              className="flex flex-row gap-1 justify-center items-center px-8 cursor-pointer  border-b-2 py-2"
              style={{
                borderBottomColor:
                  selectMode == "form_response" ? color : undefined,
                borderBottomWidth: selectMode == "form_response" ? 2 : 0.5,
              }}
              onClick={() => setSelectMode("form_response")}
            >
              <LuFormInput />
              <span>Form Response</span>
            </div>
          )}
        </div>

        {selectedTask && selectMode == "comment" && (
          <>
            {selectedTask!.comments.length > 0 ? (
              <CommentList comments={selectedTask!.comments} />
            ) : (
              <div className="p-4">No Comments ...</div>
            )}
          </>
        )}
        {selectedTask && selectMode == "activity" && renderActivities()}
        {selectedTask && selectMode == "form_response" && renderFormResponse()}
        {selectedTask && selectMode == "file" && (
          <>
            {selectedTask!.files.length > 0 ? (
              <div>
                <div className="flex flex-wrap flex-row gap-2">
                  {selectedTask?.files.map((e) => {
                    const extension = e.url ? e.url.split(".").pop()! : "";
                    let is_image =
                      ["jpg", "png", "jpeg", "gif", "webp"].includes(
                        extension.toLowerCase()
                      ) || e.is_image;
                    return (
                      <div
                        className=" aspect-square rounded-lg bg-gray-100 justify-center items-center flex flex-col hover:bg-gray-200 w-16 cursor-pointer"
                        key={e.id}
                        onClick={() => {
                          if (is_image) {
                            setPreview(e.url);
                            setModalPreview(true);
                          } else {
                            window.open(e.url, "_blank");
                          }
                        }}
                      >
                        {is_image ? (
                          <img
                            className=" aspect-square rounded-lg bg-gray-100  object-cover border-2 w-16 cursor-pointer"
                            src={e.url}
                            alt="image"
                          />
                        ) : (
                          <HiPaperClip size={24} />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="p-4">No Files ...</div>
            )}
          </>
        )}
      </div>

      {profile && (
        <CommentBox
          profile={profile}
          channels={[]}
          members={selectedTask?.contributors ?? []}
          clients={project?.clients ?? []}
          onSend={async (val) => {
            setIsLoading(true);
            try {
              if (!company?.is_client) {
                await onForceTaskChange(selectedTask!);
              }
              await taskAddComment(selectedTask?.id!, val);
              onCommentSucced();
            } catch (e) {
              errorToast("${e}");
            } finally {
              setIsLoading(false);
            }
          }}
        />
      )}
      {modalPreview && (
        <Modal
          className="custom-modal"
          size={"lg"}
          open={modalPreview}
          onClose={() => setModalPreview(false)}
        >
          <Modal.Body>
            <img src={preview} className="w-full rounded" />
          </Modal.Body>
        </Modal>
      )}
      {/* <Modal
        className="custom-modal"
        open={modalDesc}
        onClose={() => setModalDesc(false)}
      >
        <Modal.Header>
          <Modal.Title>Choose Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectPicker
            block
            data={descTemplates}
            valueKey="id"
            labelKey="name"
            onChange={(val) => {
              let newTask = {
                ...selectedTask!,
                description: descTemplates.find((e) => e.id == val)?.content,
              };
              setSelectedTask(newTask);
              onTempTaskChange(newTask);
            }}
          />
        </Modal.Body>
      </Modal> */}
    </div>
  );
};
export default TaskDetail;

interface DueDateProps {
  task?: Task | null;
  onChange: (val: Date) => void;
}

const DueDate: FC<DueDateProps> = ({ task, onChange }) => {
  const [date, setDate] = useState<Date | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  if (isEdit) {
    return (
      <DatePicker
        className="text-right w-32"
        format="dd MMM yyyy"
        value={date}
        placement="topEnd"
        onChange={(val) => {
          setDate(val);
          onChange?.(val!);
          setIsEdit(false);
        }}
        onOk={(val) => {
          setDate(val);
        }}
      />
    );
  }
  return toolTip(
    "Due Date",
    <div
      className=""
      onClick={() => {
        setIsEdit(true);
      }}
    >
      {!task?.due_date ? (
        <PiCalendarBlank />
      ) : (
        <span className="text-xs">
          {moment(task?.due_date).format("DD MMM")}
        </span>
      )}
    </div>,
    "left"
  );
};
