import type { FC } from "react";
import React from "react";
import { getTextColorBasedOnBackground, initials } from "../utils/helper";

interface CustomAvatarProps {
  src?: string | null;
  alt?: string | null;
  color?: string | null;
  size?: number | null;
}

const CustomAvatar: FC<CustomAvatarProps> = ({
  src,
  alt,
  color = "#673ab7",
  size = 32,
}) => {
  return (
    <div
      className=" rounded-full"
      style={{
        width: size!,
        height: size!,
        backgroundColor: color ?? "violet",
      }}
    >
      {src === null || src == "" ? (
        <div className="flex  justify-center items-center flex-col w-full h-full font-[400]" style={{ color: getTextColorBasedOnBackground(color) }}>{initials(alt!)}</div>
      ) : (
        <img style={{
          width: size!,
          height: size!,
          backgroundColor: color ?? "violet",
        }} src={src} alt={initials(alt!)} className=" rounded-full object-cover" />
      )}
    </div>
  );
};
export default CustomAvatar;
