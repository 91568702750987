import { useEffect, useState, type FC } from "react";
import { useSearchParams } from "react-router-dom";
import { Company } from "../model/company";
import { errorToast } from "../utils/helper-ui";
import { authWithJarayaAuthCode } from "../repositories/auth";
import { TOKEN } from "../objects/constants";
import { asyncLocalStorage } from "../utils/storage";

interface AuthHandlerProps {}

const AuthHandler: FC<AuthHandlerProps> = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const provider = searchParams.get("provider");
  const authorizationCode = searchParams.get("authorization_code");
  const [companies, setCompanies] = useState<Company[]>([]);

  // const [token, setToken] = useState<string|null>(null);
  const [token, setToken] = useState("");

  useEffect(() => {
    if (provider == "jaraya") {
      authWithJarayaAuthCode(authorizationCode!)
        .then((v) => v.json())
        .then((v) => {
          // console.log(v);
          setToken(v.token);
          setCompanies(v.companies);
          asyncLocalStorage.setItem(TOKEN, v.token).then(() => {
            window.location.href = "/";
          });

          // processLogin(v.token)
        })
        .catch((error) => {
          errorToast(`${error}`);
          setTimeout(() => {
            window.location.href = "/login";
          }, 1000);
        });
    }
  }, [authorizationCode, provider]);

  return <div>Please Wait</div>;
};
export default AuthHandler;
