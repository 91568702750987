import React, { useContext, useEffect, useState } from "react";
import { User } from "../model/user";
import CustomAvatar from "./CustomAvatar";
import { handleOnPaste, initials } from "../utils/helper";
import { Uploader } from "rsuite";
import { asyncLocalStorage } from "../utils/storage";
import { COMPANY_ID, TOKEN } from "../objects/constants";
import { LoadingContext } from "../objects/loading";
import { FileReq, FileResp } from "../model/file";
import { errorToast } from "../utils/helper-ui";
import { addItemDropBox } from "../repositories/drop_box";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Mention, MentionsInput } from "react-mentions";
import { MentionReq } from "../model/comment";
import { Member } from "../model/member";
import { Client } from "../model/client";
import { Channel } from "../model/channel";

interface UpdateFeedBoxProps {
  isDropBox?: boolean;
  profile?: User | null;
  members: Member[];
  clients: Client[];
  channels: Channel[];
  onSubmit?: () => void;
}
const UpdateFeedBox: React.FC<UpdateFeedBoxProps> = ({
  isDropBox,
  profile,
  onSubmit,
  members,
  clients,
  channels,
}) => {
  const [showUploader, setShowUploader] = useState(false);
  const [token, setToken] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [files, setFiles] = useState<FileResp[]>([]);
  const { projectId, dropBoxId } = useParams();
  const [title, setTitle] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const [mentions, setMentions] = useState<MentionReq[]>([]);
  

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    asyncLocalStorage.getItem(TOKEN).then((v) => {
      setToken(v);
    });
    asyncLocalStorage.getItem(COMPANY_ID).then((v) => {
      setCompanyID(v);
    });
  }, [mounted]);
  const styles: any = {
    control: {
      backgroundColor: "transparent",
      fontSize: 14,
      fontWeight: "normal",
    },

    "&multiLine": {
      control: {},
      highlighter: {},
      input: {
        outline: "none",
      },
    },

    "&singleLine": {
      display: "inline-block",
      width: 180,

      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 1,
        border: "2px inset",
      },
    },

    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        textAlign: "left",
        "&focused": {
          backgroundColor: "#ccc0e1",
        },
      },
    },
  };

  const sendFiles = async () => {
    try {
      if (title == "") {
        throw "Title cannot be empty";
      }
      if (files.length == 0) {
        throw "Files cannot be empty";
      }
      setIsLoading(true);
      const res = await addItemDropBox(projectId!, dropBoxId!, {
        title,
        files: files.map((e) => ({
          path: e.path,
          caption: title,
          is_image: e.is_image,
        })),
        drop_box_id: dropBoxId!,
        creator_id: profile!.id,
      });
      if (!res.ok) {
        throw "Upload failed";
      }
      const resJson = await res.json();
      if (onSubmit) onSubmit();
      setFiles([]);
      setTitle("");
      setShowUploader(false);
    } catch (e) {
      console.log(e);
      errorToast(`${e}`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="bg-white shadow-md rounded-lg p-4 mb-6 max-w-[600px] w-full transition-all duration-300 ease-in-out">
      {/* Status Input Section */}
      <div className="flex items-start mb-4 gap-2 ">
        <CustomAvatar
          src={profile?.picture_url}
          alt={profile?.full_name}
          size={36}
        />
        <div className="w-full">
          <div className=" w-full space-y-4 border  focus:ring-0  focus:ring-gray-200 text-center p-2 flex-1 bg-gray-100 rounded-lg px-4 py-2 focus:outline-none">
            <MentionsInput
              placeholder={`What's on your mind, ${profile?.full_name}`}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
              onPaste={async (event) => {
                handleOnPaste(
                  event,
                  (progress: number) => {},
                  async (v: { data: FileReq }) => {
                    try {
                      let data: FileReq = v.data;
                      setIsLoading(true);
                      const res = await addItemDropBox(projectId!, dropBoxId!, {
                        title,
                        files: [
                          {
                            path: data.path,
                            caption:
                              title ??
                              `From Paste : ${moment("DD MMM YYYY, HH:mm")}`,
                            is_image: true,
                          },
                        ],
                        drop_box_id: dropBoxId!,
                        creator_id: profile!.id,
                      });
                      if (!res.ok) {
                        throw "Upload failed";
                      }
                      const resJson = await res.json();
                      if (onSubmit) onSubmit();
                      setFiles([]);
                      setTitle("");
                      setShowUploader(false);
                    } catch (error) {
                      errorToast(`${error}`);
                    } finally {
                      setIsLoading(false);
                    }
                  }
                );
              }}
              value={title}
              onChange={(val, _, __, mentions) => {
                setTitle(val.target.value);

                let mentionReqs: MentionReq[] = [];
                for (let index = 0; index < mentions.length; index++) {
                  const m = mentions[index];
                  let mention: MentionReq = {
                    data: JSON.stringify(m),
                  };
                  console.log(title[m.index]);
                  if (title[m.index] == "@") {
                    mention.member_id = m.id;
                  }
                  if (title[m.index] == "!") {
                    mention.client_id = m.id;
                  }
                  if (title[m.index] == "#") {
                    mention.channel_id = m.id;
                  }
                  mentionReqs.push(mention);
                }
                // console.log(mentionReqs);
                setMentions(mentionReqs);
              }}
              style={styles}
            >
              <Mention
                trigger="@"
                data={members.map((e) => ({
                  id: e.id,
                  display: e.user?.full_name,
                }))}
                style={{
                  backgroundColor: "#ccc0e1",
                }}
              />
              <Mention
                trigger="!"
                data={clients.map((e) => ({
                  id: e.id,
                  display: e.user?.full_name,
                }))}
                style={{
                  backgroundColor: "#ffff00",
                }}
              />
              <Mention
                trigger="#"
                data={channels.map((e) => ({
                  id: e.id,
                  display: e.name,
                }))}
                style={{
                  backgroundColor: "#32cd32",
                }}
              />
            </MentionsInput>
          </div>
          {isFocus && (
            <small className="italic text-gray-500 px-2">
              mention members with @ or # for clients ...
            </small>
          )}
        </div>

        {/* 
        <input
          type="text"
          placeholder={`Apa yang Anda pikirkan, ${profile?.full_name}`}
          className="flex-1 bg-gray-100 rounded-full px-4 py-2 focus:outline-none"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        /> */}
      </div>

      {/* Action Buttons */}

      {showUploader ? (
        <div className="py-4">
          <Uploader
            multiple
            action={`${process.env.REACT_APP_API_URL}/file/upload`}
            headers={{
              authorization: `Bearer ${token}`,
              "ID-Company": companyID,
            }}
            onSuccess={async (resp, file) => {
              const extension = resp.data.path.split(".").pop();
              // console.log("extension", extension);
              setFiles((prev) => [
                ...prev,
                {
                  ...resp.data,
                  key: file.fileKey,
                  is_image: ["jpg", "png", "jpeg", "gif", "webp"].includes(
                    extension.toLowerCase()
                  ),
                },
              ]);
              setIsLoading(false);
            }}
            onUpload={(file) => {
              setIsLoading(true);
            }}
            draggable
            onRemove={(file) => {
              setFiles([...files.filter((e) => e.key != file.fileKey)]);
            }}
          >
            <div
              style={{
                height: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>Click or Drag files to this area to upload</span>
            </div>
          </Uploader>
          <div className="flex justify-end mt-4">
            <button
              className="flex items-center text-sm text-gray-600 gap-2"
              onClick={() => setShowUploader(false)}
            >
              Batal
            </button>
            <button
              className="flex items-center text-sm text-white gap-2 bg-violet-700 rounded-full px-4 py-2 ml-2 w-32 text-center justify-center"
              onClick={sendFiles}
            >
              Kirim
            </button>
          </div>
        </div>
      ) : (
        <div className="flex justify-end mt-2 border-t pt-4">
          <button
            className="flex items-center text-sm text-violet-700 gap-2"
            onClick={() => setShowUploader(true)}
          >
            <img src="/images/folder.png" alt="" className="w-6" />
            Bagikan File
          </button>
        </div>
      )}
    </div>
  );
};

export default UpdateFeedBox;
