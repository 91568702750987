import { useContext, useEffect, useState, type FC } from "react";
import MainLayout from "./MainLayout";
import { ProjectContext } from "../objects/project_object";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { getProjects } from "../repositories/project";
import { Project } from "../model/project";
import Table from "rsuite/Table";
import { PiMagnifyingGlass } from "react-icons/pi";
import { Pagination } from "../objects/pagination";
import { Avatar, Pagination as RSPagination, Tag } from "rsuite";
import CustomAvatar from "./CustomAvatar";
import { initials } from "../utils/helper";
import { setProjectStorage } from "../utils/storage";
import { useNavigate } from "react-router-dom";

interface ProjectPageProps {}

const ProjectPage: FC<ProjectPageProps> = ({}) => {
  const { project: activeProject, setProject: setActiveProject } =
    useContext(ProjectContext);
  const [profile, setProfile] = useState<User>();
  const [projects, setProjects] = useState<Project[]>([]);
  const { color, setColor } = useContext(BgColorContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const nav = useNavigate();

  useEffect(() => {
    getProjects({ page, limit, search })
      .then((res) => res.json())
      .then((v) => {
        setProjects(v.data);
        setPagination(v.pagination);
      });
  }, [page, limit, search]);

  return (
    <MainLayout
      topBarWidget={
        <div className="flex items-center space-x-2 relative">
          <PiMagnifyingGlass className="top-2.5 right-2 absolute" />
          <input
            type="text"
            onChange={(e) => {
              setPage(1);
              setSearch(e.target.value);
            }}
            value={search}
            placeholder="Search ..."
            className="focus:ring-0 focus:outline-none focus:ring-gray-200 rounded-2xl py-2 px-4 text-xs w-[300px] bg-gray-100"
          />
        </div>
      }
    >
      <div
        className="flex flex-col flex-1 overflow-x-auto  h-full"
        style={{ backgroundColor: color }}
      >
        <div
          className="  h-full bg-white ml-4 rounded-tl-[15px]  flex flex-col p-4"
          style={{ height: "calc(100vh - 50px)" }}
        >
          <h3 className="text-2xl font-[500] focus:ring-0 focus:outline-none focus:ring-gray-200 mb-8">
            Projects{" "}
          </h3>
          <div className="flex-1 overflow-auto">
          <table className="table-auto w-full">
            <thead className="bg-gray-50">
              <tr className="h-16">
                <th className="border-r border-t text-left w-1/2 px-4 py-2">
                    Project Name
                </th>
                <th className="border-t text-left w-1/2 px-4 py-2">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr
                  className="hover:font-[500] cursor-pointer"
                  key={project.id}
                  onClick={() => {
                    setActiveProject(project);
                    setProjectStorage(project);
                    nav(`/project/${project.id}`);
                  }}
                >
                  <td className="border-r border-t px-4 py-2 flex flex-row items-center gap-2">
                    <Avatar
                      src={project.picture_url}
                      alt={initials(project.title)}
                      circle
                      size="sm"
                    />

                    {project.title}
                  </td>
                  <td className="border-t px-4 py-2">{project.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          {(pagination?.total_pages ?? 0) > 1 && (
            <div className="p-4">
              <RSPagination
                prev
                last
                next
                first
                size="xs"
                total={pagination?.total_records ?? 100}
                limit={limit ?? 10}
                activePage={page}
                onChangePage={(val) => setPage(val)}
                maxButtons={5}
              />
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};
export default ProjectPage;
