import { uploadFile } from "../repositories/file"
import { updateWebPushToken } from '../repositories/my'
import { decode as base64_decode, encode as base64_encode } from 'base-64';
export function randomStr(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function randomDate(startDate, endDate) {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const randomDate = new Date(
        start + Math.random() * (end - start)
    ).toLocaleDateString();
    return randomDate;
}


export function getTaskFromColumn(columns, id) {
    for (const column of columns) {
        for (const task of column.tasks) {
            if (task.id == id) return task
        }
    }
}

export function getRandomColor() {
    const h = Math.random();
    const s = 0.5 + Math.random() * 0.5;
    const l = 0.7 + Math.random() * 0.3;
    const color = hslToHex(h, s, l);
    return color;
}

function hslToHex(h, s, l) {
    let r, g, b;

    if (s === 0) {
        r = g = b = l;
    } else {
        const hue2rgb = (p, q, t) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * 6 * (2 / 3 - t);
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    const toHex = x => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
export function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter(t => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;

    for (let i = 0; i < projectTasks.length; i++) {
        const task = projectTasks[i];
        if (start.getTime() > task.start.getTime()) {
            start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
            end = task.end;
        }
    }
    return [start, end];
}

export const hexToRgbA = (hex, alpha) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + "," + alpha + ")";
    }
    throw new Error("Bad Hex");
};



export function initials(name) {
    if (!name) return ""
    return name.split(" ").map((n) => `${n[0]}`.toUpperCase()).join("");
}



function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
}

// Fungsi untuk menghitung luminance warna
function luminance(r, g, b) {
    const a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

// Fungsi untuk menentukan apakah teks harus berwarna terang atau gelap
export function getTextColorBasedOnBackground(hexColor) {
    if (!hexColor) return "#ffffff"
    const [r, g, b] = hexToRgb(hexColor);
    const lum = luminance(r, g, b);
    // Jika luminance lebih besar dari threshold (0.5), gunakan warna teks gelap, jika tidak gunakan warna terang
    return lum > 0.5 ? '#333333' : "#ffffff";
}


export function getPriorityColor(priority) {
    switch (priority) {
        case "Lowest":
            return "#FFCDD2";
        case "Low":
            return "#ff8a8a";
        case "Medium":
            return "#eecb02";
        case "High":
            return "#F57C00";
        case "Highest":
            return "#E53935";
        default:
            return "#000000";
    }
}


export function hexColorFromProgress(progress) {
    // Ensure progress is between 0 and 100
    progress = Math.max(0, Math.min(progress, 100));

    // Gray color (RGB: 128, 128, 128)
    const gray = { r: 128, g: 128, b: 128 };

    // Softer Green color (RGB: 102, 204, 102)
    const softGreen = { r: 102, g: 204, b: 102 };

    // Linear interpolation function
    const lerp = (start, end, t) => Math.round(start + (end - start) * t / 100);

    // Calculate the RGB values based on the progress
    const r = lerp(gray.r, softGreen.r, progress);
    const g = lerp(gray.g, softGreen.g, progress);
    const b = lerp(gray.b, softGreen.b, progress);

    // Convert RGB values to hex
    const toHex = (value) => value.toString(16).padStart(2, '0');

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}


export function invertHexColor(hex) {
    // Remove the '#' if present
    hex = hex.replace('#', '');

    // Parse the hex color into RGB values
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Invert each RGB component (255 - value)
    r = 255 - r;
    g = 255 - g;
    b = 255 - b;

    // Convert the inverted RGB values back to hex
    const toHex = (value) => value.toString(16).padStart(2, '0');

    // Return the inverted color in hex format
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const isValidEmail = (str) => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegex.test(str);
};

export const handleOnPaste = async (event, onProgress, onSuccess, onError) => {
    const items = (event.clipboardData || event.nativeEvent.clipboardData)
        .items;

    let blob = null;
    let filename = null;
    for (let i = 0; i < items.length; i++) {
        console.log(items[i].type)
        if (items[i].type.indexOf("image") === 0) {
            const extension = items[i].type.split("/").pop();
            blob = items[i].getAsFile();
            filename = `${randomStr(20)}.${extension}`
        }
        if (items[i].type.includes("application/pdf")) {
            blob = items[i].getAsFile();
            filename = `${randomStr(20)}.pdf`
        }

        if (blob !== null) {
            await uploadFile(new File([blob], filename ?? "from paste"), onProgress)
                .then((v) => v.json())
                .then(onSuccess)
                .catch(onError);
        }
    }

};


export const getPersistentBrowserId = () => {
    return base64_encode([
        navigator.userAgent,
        navigator.language,
        new Date().getTimezoneOffset(),
        navigator.platform,
        navigator.vendor,
    ].join('-')).substr(0, 32);
}



export const IsIOS = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
        'MacIntel',
    ].includes(navigator?.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document) || (navigator.userAgent.includes("MacIntel") && "ontouchend" in document)
}


const subscribeUser = async () => {
    try {
        if ("serviceWorker" in navigator) {
            let swRegistration = await navigator.serviceWorker.register("/service-worker.js");
            const registration = await navigator.serviceWorker.ready;
            if (registration) {
                const subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
                });
                // console.log("User is subscribed:", subscription);
                await updateWebPushToken(JSON.stringify(subscription), getPersistentBrowserId())
                // if (IsIOS()) {
                //   let pushManager = swRegistration.pushManager;
                //   let permissionState = await pushManager.permissionState({
                //     userVisibleOnly: true,
                //   });
                //   console.log(permissionState)

                //   let subscriptionOptions = {
                //     userVisibleOnly: true,
                //     applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY
                // };
                //     let subscription = await pushManager.subscribe(subscriptionOptions);
                //     console.log(subscription);
                // }
            }
        }
    } catch (error) {
        console.error("Failed to subscribe user:", error);
    }
};

export const checkPermission = async () => {
    return await Notification.requestPermission();
}
export const requestPermission = async () => {
    // console.log("requestPermission()")
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
        subscribeUser();
    } else {
        console.log("Notification permission denied");
    }
};



export const urlBase64ToUint8Array = () => {
    let base64String = process.env.REACT_APP_VAPID_PUBLIC_KEY
    console.log(base64String)
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};


export const convertFileBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};


export const extractNumber = (str) => {
    const cleanString = str.replace(/[^0-9,.]/g, '');
    const numericString = cleanString.replace(/\./g, '');
    return parseFloat(numericString);
}

export const money = (val, friction = 2) => {
    if (!val) return 0
    return val.toLocaleString('id-ID', { maximumFractionDigits: friction });
}

