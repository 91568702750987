import { Form, FormReq } from "../model/form";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/http";

export const createForm = async (form: FormReq) => {
  return await customFetch("form", {
    method: "POST",
    body: JSON.stringify(form),
  });
};

export const getForms = async () => {
  return await customFetch("form");
};

export const getForm = async (id: string) => {
  return await customFetch(`form/${id}`);
};
export const getFormResponse = async (
  id: string,
  pagination: PaginationReq
) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };
  if (pagination.search && pagination.search != "") {
    params["search"] = pagination.search;
  }

  return await customFetch(
    `form/${id}/responses?${new URLSearchParams(params)}`
  );
};

export const updateForm = async (id: string, form: any) => {
  return await customFetch(`form/${id}`, {
    method: "PUT",
    body: JSON.stringify(form),
  });
};

export const deleteForm = async (id: string) => {
  return await customFetch(`form/${id}`, {
    method: "DELETE",
  });
};
