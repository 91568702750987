

import React, { useEffect, useRef } from "react";

const JitsiConference = ({ roomName, email, displayName }: { 
  roomName: string, email: string, displayName: string }) => {
  const jitsiContainerRef = useRef(null);
  

  useEffect(() => {
    window.JitsiMeetExternalAPI = window.JitsiMeetExternalAPI || {};
    const domain = '8x8.vc'; // Jitsi server domain
    const options = {
      roomName: `${process.env.REACT_APP_JITSI_KEY}/${roomName}`, // Room name for the conference
      width: "100%",
      height: "100%",
      parentNode: jitsiContainerRef.current, // Target div for embedding Jitsi
      configOverwrite: {
        startWithAudioMuted: true, // Custom config, optional
        startWithVideoMuted: true,
      },
      interfaceConfigOverwrite: {
        DEFAULT_BACKGROUND: "#000000", // Custom interface, optional
        SHOW_JITSI_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
      },
      userInfo: {
        email,
        displayName
      }
    };

    const api = new window.JitsiMeetExternalAPI(domain, options);

    // Clean up Jitsi API on component unmount
    return () => {
      if (api) api.dispose();
    };
  }, [roomName]);

  return (
    <div ref={jitsiContainerRef} style={{ width: "100%", height: "100%" }} />
  );
};

export default JitsiConference;
