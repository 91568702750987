import { Invitation, InvitationReq } from "../model/invitation";
import { customFetch } from "../utils/http";
import { PaginationReq } from "../objects/pagination";

export const createInvitation = async (invitation: InvitationReq) => {
    return await customFetch(`invitation`, {
        method: "POST",
        body: JSON.stringify(invitation),
    });
    
};

export const getInvitations = async (pagination: PaginationReq) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }
    return await customFetch(`invitation?${new URLSearchParams(params)}`);
    
};
export const getInvitation = async (id: string) => {
    return await customFetch(`invitation/${id}`);
    
};

export const updateInvitation = async (id: string, invitation: InvitationReq) => {
    return await customFetch(`invitation/${id}`, {
        method: "PUT",
        body: JSON.stringify(invitation),
    });
    
};

export const deleteInvitation = async (id: string): Promise<void> => {
    await customFetch(`invitation/${id}`, {
        method: "DELETE",
    });
};
