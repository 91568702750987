import { createContext } from "react";

type PermissionsContextType = {
  permissions: string[];
  setPermissions: (string: string[]) => void;
};

export const PermissionsContext = createContext<PermissionsContextType>({
  permissions: [],
  setPermissions: (val) => false,
});
