import type { FC } from "react";
import React from "react";
import { Team } from "../model/team";
import { PiPlusLight } from "react-icons/pi";
import { getTextColorBasedOnBackground, initials } from "../utils/helper";
import { Avatar, AvatarGroup } from "rsuite";

interface TeamCardProps {
  team?: Team | null | undefined;
  onClick?: (team: Team | null) => void;
}

const TeamCard: FC<TeamCardProps> = ({ team, onClick }) => {
  return (
    <div
      className={`h-[160px]  rounded-lg shadow-md shadow-gray-200  hover:shadow-inner  flex flex-col bg-gray-100 cursor-pointer hover:bg-gray-200`}
      onClick={() => onClick?.(team ?? null)}
    >
      {team ? (
        <div
          className="flex flex-col justify-between items-start h-full w-full  p-4 rounded-lg"
          style={{
            backgroundColor: team.color,
            color: getTextColorBasedOnBackground(team.color),
            backgroundImage: team.picture_url && `url(${team.picture_url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="bg-black bg-opacity-30 rounded-lg py-1 px-2">
            <h4
              className="font-[500] text-2xl"
              style={{
                textShadow: "1px 2px 2px rgba(0,0,0,0.2)",
              }}
            >
              {team.title}
            </h4>
            <p
              className="text-sm"
              style={{
                textShadow: "1px 2px 2px rgba(0,0,0,0.2)",
              }}
            >
              {team.description}
            </p>
          </div>
          <div className="flex items-end justify-end mt-4 w-full ">
            <AvatarGroup stack>
              {team.members
                .filter((member, i) => i < 4)
                .map((member) => (
                  <Avatar
                    size="xs"
                    bordered
                    circle
                    key={member.user.full_name}
                    src={member.user.picture_url}
                    alt={initials(member.user.full_name)}
                    color="violet"
                  />
                ))}
              {team.members.length - 4 > 0 && (
                <Avatar
                  size="xs"
                  bordered
                  circle
                  style={{ background: "#111" }}
                >
                  +{team.members.length - 4}
                </Avatar>
              )}
            </AvatarGroup>
          </div>
        </div>
      ) : (
        <div className="flex flex-row justify-center items-center h-full w-full">
          <PiPlusLight size={32} className="text-gray-500" />
        </div>
      )}
    </div>
  );
};
export default TeamCard;
