import { Gantt, Task, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { TaskType } from "gantt-task-react/dist/types/public-types";
import moment from "moment";
import { useEffect, useState, type FC } from "react";
import { Column, Task as TaskProject } from "../model/board";
import { TimelineGroup } from "../model/timeline";
import {
  getStartEndDateForProject,
  getTaskFromColumn,
  hexToRgbA,
} from "../utils/helper";
import { ViewSwitcher } from "./ViewSwitcher";
import { ActivityLogReq, Project } from "../model/project";

interface TimeLineProps {
  project: Project | null;
  data: Column[];
  onColumnChange: (column: Column) => void;
  onTaskChange: (task: TaskProject) => void;
  onSelectTask: (task: TaskProject) => void;
  onUpdateActivity: (act: ActivityLogReq) => void
}

const TimeLine: FC<TimeLineProps> = ({
  data,
  onColumnChange,
  onTaskChange,
  onSelectTask,
  onUpdateActivity,
}) => {
  const [groups, setGroups] = useState<TimelineGroup[]>([]);
  const [tasks, setTasks] = useState<Task[]>([
    {
      type: "task",
      id: "Task 0",
      name: "1.1 Task",
      start: new Date(2024, 6, 1),
      end: new Date(2024, 6, 30),
      progress: 45,
    },
  ]);

  const [view, setView] = useState<ViewMode>(ViewMode.Day);
  const [isChecked, setIsChecked] = useState(true);
  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleTaskChange = (task: Task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
        console.log(project)
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    let orgTask = getTaskFromColumn(data, task.id);
    if (orgTask) {
      orgTask.date = task.start;
      orgTask.due_date = task.end;
      onTaskChange(orgTask);
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    let orgTask = getTaskFromColumn(data, task.id);
    if (orgTask) {
      orgTask.progress = task.progress;
      onTaskChange(orgTask);
    }
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    // console.log("On progress change Id:" + task.id, task.progress);
    // console.log(tasks.map((t) => (t.id === task.id ? task : t)))
  };

  const handleDblClick = (task: Task) => {
    let orgTask = getTaskFromColumn(data, task.id);
    if (task.type == "task") onSelectTask(orgTask);
    // alert("On Double Click event Id:" + task.id);
  };

  const handleClick = (task: Task) => {


    // console.log("On Click event Id:" + task.id);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    // console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task: Task) => {
    let orgTask = getTaskFromColumn(data, task.id);
    if (orgTask) {
      orgTask.date = task.start;
      orgTask.due_date = task.end;
      console.log("On expander click Id:" + task.id, orgTask);
      onTaskChange(orgTask);
    }
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  useEffect(() => {
    let items: Task[] = [];
    const getStartColumn = (column: Column): moment.Moment[] => {
      if (column.tasks.length == 0) return [];
      let start = moment(column.tasks[0].date);
      let end = moment();
      if (column.tasks[0].due_date) {
        end = moment(column.tasks[0].due_date);
      }

      for (const task of column.tasks) {
        if (task.date && task.due_date) {
          if (moment(task.date).isAfter(task.due_date)) {
            continue;
          }
          if (moment(task.date).isBefore(start)) {
            start = moment(task.date);
          }
          if (moment(task.due_date).isAfter(end)) {
            end = moment(task.due_date);
          }
        }
      }
      // console.log(
      //   column.title,
      //   start.format("DD MMM YYYY"),
      //   end.format("DD MMM YYYY")
      // );
      return [start, end];
    };
    for (const column of data) {
      let startEnd = getStartColumn(column);
      if (startEnd.length > 0) {
        let dataTask = {
          id: column.id,
          type: "project" as TaskType,
          name: column.title,
          start: startEnd[0].toDate(),
          end: startEnd[1].toDate(),
          progress: 100,
          project: column.id,
          dependencies: [],
          styles: {
            progressColor: hexToRgbA(column.color, 0.8),
            progressSelectedColor: column.color,
          },
        };
        items.push(dataTask);
      }
      for (const task of column.tasks) {
        if (task.date && task.due_date) {
          if (moment(task.date).isAfter(task.due_date)) {
            continue;
          }
          let dataTask = {
            id: task.id,
            type: "task" as TaskType,
            name: task.title,
            start: moment(task.date).toDate(),
            end: moment(task.due_date).toDate(),
            progress: task.progress,
            project: column.id,
            dependencies: task.dependencies.map((e) => e.id),
            styles: {
              progressColor: hexToRgbA(column.color, 0.8),
              progressSelectedColor: column.color,
            },
          };
          items.push(dataTask);
        }
      }
    }
    setTasks(items);
    // console.log(items);
    // setEvents(newEvents);
  }, [data]);

  return (
    <div className="p-4">
      <ViewSwitcher
        viewMode={view}
        onViewModeChange={(viewMode) => setView(viewMode)}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
      />
      {tasks.length > 0 && (
        <Gantt
          locale="ind"
          tasks={tasks}
          viewMode={view}
          fontSize="10pt"
          viewDate={moment().toDate()}
          listCellWidth={isChecked ? "155px" : ""}
          onDateChange={handleTaskChange}
          onDelete={handleTaskDelete}
          onProgressChange={handleProgressChange}
          onDoubleClick={handleDblClick}
          onClick={handleClick}
          onSelect={handleSelect}
          onExpanderClick={handleExpanderClick}
          columnWidth={columnWidth}
        />
      )}
    </div>
  );
};
export default TimeLine;
