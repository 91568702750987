import { useEffect, type FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { verification } from "../repositories/auth";
import { errorToast, successToast } from "../utils/helper-ui";

interface VerificationProps {}

const Verification: FC<VerificationProps> = ({}) => {
  const { token } = useParams();
  const nav = useNavigate()
  useEffect(() => {
    if (token) {
      verification(token)
        .then((res) => {
            successToast("Verifikasi Berhasil, Silakan Login")
          nav('/login')
        })
        .catch((err) => {
          errorToast(err)
        });
    }
  }, [token]);

  return <h1>Verification ......</h1>;
};
export default Verification;
