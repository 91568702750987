import { Client, ClientReq } from "../model/client";
import { customFetch } from "../utils/http";
import { PaginationReq } from "../objects/pagination";

export const createClient = async (client: ClientReq) => {
    return await customFetch(`client`, {
        method: "POST",
        body: JSON.stringify(client),
    });
    
};

export const getClients = async (pagination: PaginationReq) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }
    return await customFetch(`client?${new URLSearchParams(params)}`);
    
};
export const getClient = async (id: string) => {
    return await customFetch(`client/${id}`);
    
};

export const updateClient = async (id: string, client: ClientReq) => {
    return await customFetch(`client/${id}`, {
        method: "PUT",
        body: JSON.stringify(client),
    });
    
};


export const deleteClient = async (id: string): Promise<void> => {
    await customFetch(`client/${id}`, {
        method: "DELETE",
    });
};
