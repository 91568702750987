import moment from "moment";
import type { FC } from "react";
import { useContext, useEffect, useState } from "react";
import { PiEyeBold, PiMagnifyingGlass } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { Pagination as RSPagination, Tag } from "rsuite";
import MainLayout from "../components/MainLayout";
import StatusSlider from "../components/StatusSlider";
import { Task } from "../model/board";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { LoadingContext } from "../objects/loading";
import { Pagination } from "../objects/pagination";
import { ProjectContext } from "../objects/project_object";
import { myTask } from "../repositories/my";
import {
  getPriorityColor,
  getTextColorBasedOnBackground,
} from "../utils/helper";
import { errorToast } from "../utils/helper-ui";

interface MyWorkPageProps {}

const MyWorkPage: FC<MyWorkPageProps> = ({}) => {
  const { project: activeProject, setProject: setActiveProject } =
    useContext(ProjectContext);
  const [profile, setProfile] = useState<User>();
  const { color, setColor } = useContext(BgColorContext);
  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const nav = useNavigate();
  const [tasks, setTasks] = useState<Task[]>([]);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [pagination, setPagination] = useState<Pagination | null>(null);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    myAllTask();
  }, [mounted, page, limit, search]);

  const myAllTask = () => {
    setIsLoading(true);
    myTask({ page, limit, search })
      .then((res) => res.json())
      .then((val) => {
        setTasks(val.data);
        setPagination(val.pagination);
      })
      .catch((err) => {
        errorToast(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <MainLayout
      onLoadProfile={setProfile}
      onLoadPermissions={(val) => {
        // console.log(val)
      }}
      topBarWidget={
        <div className="flex items-center space-x-2 relative">
          <PiMagnifyingGlass className="top-2.5 right-2 absolute" />
          <input
            type="text"
            onChange={(e) => {
              setPage(1);
              setSearch(e.target.value);
            }}
            value={search}
            placeholder="Search ..."
            className="focus:ring-0 focus:outline-none focus:ring-gray-200 rounded-2xl py-2 px-4 text-xs w-[300px]  bg-gray-100"
          />
        </div>
      }
    >
      <div
        className="flex flex-col flex-1 overflow-x-auto  h-full"
        style={{ backgroundColor: color }}
      >
        <div
          className="  h-full bg-white ml-4 rounded-tl-[15px]  flex flex-col p-4"
          style={{ height: "calc(100vh - 50px)" }}
        >
          <h3 className="text-2xl font-[500] focus:ring-0 focus:outline-none focus:ring-gray-200 mb-8">
            My Work{" "}
          </h3>
          <div className="flex-1 h-full  overflow-x-auto">
          <table className="table-auto w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-left border-r border-t">Title</th>
                <th className="px-4 py-2 text-left border-r border-t">
                  Company
                </th>
                <th className="px-4 py-2 text-left border-r border-t">
                  Project
                </th>
                <th className="px-4 py-2 text-left border-r border-t">Board</th>
                <th className="px-4 py-2 text-left border-r border-t">Group</th>
                <th className="px-4 py-2 text-left border-r border-t">
                  Priority
                </th>
                <th className="px-4 py-2 text-left border-r border-t">
                  Progress
                </th>
                <th className="px-4 py-2 text-left border-t">Due</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((val) => (
                <tr key={val.id} className="hover:bg-gray-100 cursor-pointer">
                  <td className="px-4 py-2 border-t border-r relative">
                    {val.title}

                    <div
                      className="flex-1 h-10 group/item absolute right-2 top-2"
                      onClick={() => {
                        nav(
                          `/project/${val.column?.board?.project?.id}/board/${val.column?.board_id}?task_id=${val.id}`
                        );
                      }}
                    >
                      <div className="group/edit invisible h-full justify-center flex text-center text-xs items-center group-hover/item:visible text-gray-600 gap-2 ">
                        <PiEyeBold className="w-4 h-4" />
                        Open
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-2 border-t border-r">
                    {val.column?.board?.project?.company?.name}
                  </td>
                  <td className="px-4 py-2 border-t border-r">
                    {val.column?.board?.project?.title}
                  </td>
                  <td className="px-4 py-2 border-t border-r hover:font-[500]" onClick={() => {
                     nav(
                      `/project/${val.column?.board?.project?.id}/board/${val.column?.board_id}`
                    );
                  }}>
                    {val.column?.board?.title}
                  </td>
                  <td className="px-4 py-2 border-t border-r">
                    {val.column?.title}
                  </td>

                  <td className="px-4 py-2 border-t border-r">
                    <Tag
                      style={{
                        backgroundColor: getPriorityColor(val.priority),
                        color: getTextColorBasedOnBackground(
                          getPriorityColor(val.priority)
                        ),
                      }}
                    >
                      {val.priority}
                    </Tag>
                  </td>

                  <td className="px-4 py-2 border-t border-r">
                    <StatusSlider
                      percentage={val.progress}
                      onChange={function (val: number): void {}}
                      companyId={""}
                      boardId={""}
                      projectId={""}
                      taskId={""}
                      profile={profile!}
                    />
                  </td>

                  <td className="px-4 py-2 border-t ">
                    {val.due_date ? (
                      <div>{moment(val.due_date).format("DD MMM YYYY")}</div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          {(pagination?.total_pages ?? 0) > 1 && (
            <div className="p-4">
              <RSPagination
                prev
                last
                next
                first
                size="xs"
                total={pagination?.total_records ?? 100}
                limit={limit ?? 10}
                activePage={page}
                onChangePage={(val) => setPage(val)}
                maxButtons={5}
              />
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};
export default MyWorkPage;
