import { useContext, useEffect, useMemo, useRef, useState, type FC } from "react";
import InlineForm from "./InlineForm";
import { User } from "../model/user";
import AsyncSelect from "react-select/async";
import { Inbox } from "../model/inbox";
import { FileReq } from "../model/file";
import { components, MultiValue } from "react-select";
import { getTextColorBasedOnBackground } from "../utils/helper";
import { Input, Modal } from "rsuite";
import ReactQuill from "react-quill-new";
import { searchUser } from "../repositories/search";
import { GrAttachment } from "react-icons/gr";
import { HiXMark } from "react-icons/hi2";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { RiDraftLine, RiReplyLine } from "react-icons/ri";
import { VscSend } from "react-icons/vsc";
import { LoadingContext } from "../objects/loading";
import { uploadFile } from "../repositories/file";
import { createInbox, getInbox } from "../repositories/inbox";
import { errorToast, successToast } from "../utils/helper-ui";

interface ComposeFormProps {
  profile: User;
  selectedInbox?: Inbox | null;
  onSuccess: (val: Inbox | null) => void;
  inboxDraft?: Inbox | null;
}

const ComposeForm: FC<ComposeFormProps> = ({
  profile,
  selectedInbox,
  onSuccess,
  inboxDraft,
}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FileReq[]>([]);
  const [originalFiles, setOriginalFiles] = useState<File[]>([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState<any>();
  const [recipients, setRecipients] = useState<
    MultiValue<{ value: string; label: string; email: string }>
  >([]);

  useEffect(() => {
    if (inboxDraft) {
        setSubject(inboxDraft.title);
        setBody(inboxDraft.body);
    }
  }, [inboxDraft]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],

          [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          ["link", "image", "video"],
          ["clean"],
        ],

        history: {
          delay: 500,
          maxStack: 100,
          userOnly: true,
        },
      },
    }),
    []
  );

  const sendInbox = async (status: "SENT" | "DRAFT" | "TRASH") => {
    try {
      setIsLoading(true);
      let files: FileReq[] = [];
      for (const file of originalFiles) {
        let resp = await uploadFile(file, console.log);
        let respJson = await resp.json();
        files.push(respJson.data);
      }
      let resp = await createInbox({
        title: subject,
        body: JSON.stringify(body),
        status: status,
        files,
        parent_id: selectedInbox?.id ?? null,
        recipients: recipients.map((e) => ({
          to_id: e.value,
        })),
      });
      if (status == "DRAFT") {
        successToast(`Draft created successfully`);
      }
      if (status == "SENT") {
        successToast(`Message sent successfully`);
      }
      if (selectedInbox) {
        let inbox = await getInbox(selectedInbox.id!);
        let inboxJson = await inbox.json();
        onSuccess(inboxJson.data);
      } else {
        onSuccess(null);
      }
    } catch (err) {
      errorToast(`${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-full flex flex-col ">
      <input
        type="file"
        accept="image/*"
        ref={fileRef}
        multiple
        className="hidden"
        onChange={async (val) => {
          if (val.target.files) {
            let _files: File[] = [];
            for (let index = 0; index < val.target.files.length; index++) {
              const file = val.target.files[index];
              // let resp = await uploadFile(file, (progress) => {});
              // let respJson = await resp.json();
              // setFiles([...files, respJson.data]);
              console.log(file);
              _files.push(file);
            }
            setOriginalFiles(_files);
          }
        }}
      />
      <div className="flex-1 p-4 space-y-4 ">
        <InlineForm label={"From"}>
          {profile?.full_name} ({profile?.email})
        </InlineForm>
        {!selectedInbox && (
          <InlineForm label={"To"}>
            <AsyncSelect
              placeholder="To ..."
              isMulti
              defaultOptions
              value={recipients}
              onChange={(val) => {
                setRecipients(val);
              }}
              components={{
                MultiValueContainer: (props) => {
                  let containerColor = "#ddf2fb";
                  return (
                    <components.MultiValueContainer {...props}>
                      <div
                        className=" flex flex-row"
                        style={{
                          backgroundColor: containerColor,
                          color: getTextColorBasedOnBackground(containerColor),
                        }}
                      >
                        {props.children}
                      </div>
                    </components.MultiValueContainer>
                  );
                },
                MultiValue: (props) => (
                  <components.MultiValue {...props}>
                    <span className="font-[300]">
                      {" "}
                      {props.children}
                      {` <${props.data.email}>`}
                    </span>
                  </components.MultiValue>
                ),
                Option: (props) => (
                  <components.Option {...props}>
                    <div className="flex flex-col">
                      <span className="font-[500]"> {props.children}</span>
                      <small>{props.data.email}</small>
                    </div>
                  </components.Option>
                ),
              }}
              loadOptions={(inputValue: string) =>
                new Promise<{ value: string; label: string; email: string }[]>(
                  (resolve) => {
                    searchUser(inputValue)
                      .then((res) => res.json())
                      .then((res) => {
                        var data: User[] = res.data;
                        resolve(
                          data.map((e) => ({
                            value: e.id,
                            label: e.full_name,
                            email: e.email ?? "",
                          }))
                        );
                      });
                  }
                )
              }
            />
          </InlineForm>
        )}

        <InlineForm label={"Subject"}>
          <Input
            value={subject}
            onChange={(val) => setSubject(val)}
            placeholder="Subject ..."
          />
        </InlineForm>
        <InlineForm label={""}>
          <ReactQuill
            theme="snow"
            modules={modules}
            value={body}
            onChange={(val, delta, s, editor) => {
              setBody(editor.getContents());
            }}
          />
        </InlineForm>
        <InlineForm label={""}>
          {originalFiles.map((e) => (
            <div
              key={e.name}
              className="gap-2 flex flex-row items-center justify-between"
            >
              <div className="flex flex-row items-center gap-2">
                <GrAttachment />
                {e.name}
              </div>
              <HiXMark
                className="text-red-400 cursor-pointer"
                onClick={() => {
                  setOriginalFiles(
                    originalFiles.filter((f) => f.name !== e.name)
                  );
                }}
              />
            </div>
          ))}
        </InlineForm>
      </div>
      <div className="bg-white p-4 w-full border-t flex flex-row justify-between items-center">
        <IoDocumentAttachOutline
          size={24}
          className="text-gray-400 hover:text-gray-700 cursor-pointer"
          onClick={() => {
            fileRef?.current?.click();
          }}
        />
        <div className=" flex flex-row items-center gap-2">
          <button
            onClick={async () => sendInbox("DRAFT")}
            className="flex items-center text-sm text-gray-600 gap-2 rounded-lg bg-gray-50 hover:bg-gray-200 py-2 px-4"
          >
            <RiDraftLine />
            Save as Draft
          </button>
          <button
            onClick={() => sendInbox("SENT")}
            className="flex items-center text-sm  gap-2 rounded-lg bg-blue-400 hover:bg-blue-600 py-2 px-4 text-white"
          >
            <VscSend />
            Send
          </button>
        </div>
      </div>
      
    </div>
  );
};
export default ComposeForm;
