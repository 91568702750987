import React, {
  useContext,
  useEffect,
  useState,
  type FC,
  type ReactNode,
} from "react";
import { BgColorContext } from "../objects/color_object";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { getProfile } from "../repositories/my";
import { User } from "../model/user";
import { PermissionsContext } from "../objects/permissions_object";
import { LoadingContext } from "../objects/loading";
import { getCompanyStorage, setProfileStorage } from "../utils/storage";
import { Company } from "../model/company";
import { CompanyContext } from "../objects/company_context";
import { requestPermission } from "../utils/helper";
import { getInboxes } from "../repositories/inbox";
import { Inbox } from "../model/inbox";

interface MainLayoutProps {
  children: ReactNode;
  topBarWidget?: ReactNode;
  onLoadProfile?: (user?: User) => void;
  onLoadPermissions?: (val: string[]) => void;
}

const MainLayout: FC<MainLayoutProps> = ({
  children,
  onLoadProfile,
  topBarWidget,
  onLoadPermissions,
}) => {
  const { color, setColor } = useContext(BgColorContext);
  const [mounted, setMounted] = useState(false);
  const [profile, setProfile] = useState<User | null>(null);
  const { permissions, setPermissions } = useContext(PermissionsContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { company, setCompany } = useContext(CompanyContext);

  useEffect(() => {
    setMounted(true);
    requestPermission();
  }, []);

  useEffect(() => {
    if (!mounted) return;

    getProfile()
      .then((res) => res.json())
      .then((v) => {
        setProfile(v.data);
        setProfileStorage(v.data);
        if (onLoadProfile) onLoadProfile(v.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getCompanyStorage()
      .then((v) => {
        setCompany(v);
      })
      .catch((err) => {
        console.log(err);
      });

    
  }, [mounted]);

  useEffect(() => {
    if (profile) {
      if ((profile.companies ?? []).length == 0) {
        window.location.href = "/onboarding";
      }
      setPermissions(profile?.permissions ?? []);
      if (onLoadPermissions) onLoadPermissions(profile?.permissions ?? []);
    }
  }, [profile]);

  return (
    <div
      className="flex flex-col h-screen"
      style={{
        backgroundColor: color,
      }}
    >
      {isLoading && (
        <div className="fixed w-screen h-screen bg-white bg-opacity-70">
          <div className="flex items-center justify-center h-full">
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-orange-600"></div>
          </div>
        </div>
      )}
      <Topbar>{topBarWidget}</Topbar>
      <div
        className="flex flex-row flex-grow flex-shrink basis-0"
        style={{ backgroundColor: color, paddingTop: 10 }}
      >
        {profile && <Sidebar profile={profile} />}
        {children}
      </div>
    </div>
  );
};
export default MainLayout;
