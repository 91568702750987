import {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
  type FC,
} from "react";
import { Button, Form, Modal, SelectPicker, Toggle } from "rsuite";
import InlineForm from "./InlineForm";
import { LoadingContext } from "../objects/loading";
import { uploadFile } from "../repositories/file";
import { errorToast } from "../utils/helper-ui";
import { FormReq } from "../model/form";
import { PiCameraLight } from "react-icons/pi";
import { ProjectContext } from "../objects/project_object";
import { Project } from "../model/project";
import { Board, Column } from "../model/board";
import { getProjects } from "../repositories/project";
import { getBoard } from "../repositories/board";
import { createForm } from "../repositories/form";
import { useNavigate } from "react-router-dom";

interface ModalFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalForm: FC<ModalFormProps> = ({ isOpen, onClose }) => {
  const { project, setProject } = useContext(ProjectContext);
  const [projects, setProjects] = useState<Project[]>([]);
  const [mounted, setMounted] = useState(false);
  const [selectedProject, setSelectedProject] = useState<
    Project | null | undefined
  >(null);
  const [selectedBoard, setSelectedBoard] = useState<Board | null>(null);
  const [selectedColumn, setSelectedColumn] = useState<Column | null>(null);
  const [selectedProjectID, setSelectedProjectID] = useState("");
  const [selectedBoardID, setSelectedBoardID] = useState("");
  const [selectedColumnID, setSelectedColumnID] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const fileRef2 = useRef<HTMLInputElement>(null);
  const [pic, setPic] = useState("");
  const [picUrl, setPicUrl] = useState("");
  const [cover, setCover] = useState("");
  const [coverUrl, setCoverUrl] = useState("");
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [data, setData] = useState<FormReq>({
    is_public: false,
  });
  const [isExternatl, setIsExternatl] = useState(false);
  const nav = useNavigate()

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) {
      return;
    }
    getAllProjects("");
  }, [mounted, project]);

  const handleUpload = async (val: any) => {
    if (!val?.target) return null;
    const input = val.target as HTMLInputElement;

    setIsLoading(true);
    try {
      const resp = await uploadFile(input?.files![0], (progress) => {
        console.log(progress);
      });
      return await resp.json();
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedProject(project);
    setSelectedProjectID(project?.id ?? "");
  }, [project]);
  const getAllProjects = async (search: string) => {
    try {
      const res = await getProjects({
        page: 1,
        limit: 5,
        search,
        project_id: project?.id,
      });
      const resJson = await res.json();
      setProjects(resJson.data);
    } catch (error) {
      errorToast(`${error}`);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>Form</Modal.Header>
      <Modal.Body>
        <input
          type="file"
          accept="image/*"
          ref={fileRef}
          className="hidden"
          onChange={async (val) => {
            try {
              setIsLoading(true);
              var resp = await handleUpload(val);
              setPic(resp.data.path);
              setPicUrl(resp.data.url);
            } catch (error) {
              errorToast(`${error}`);
            } finally {
              setIsLoading(false);
            }
          }}
        />
        <input
          type="file"
          accept="image/*"
          ref={fileRef2}
          className="hidden"
          onChange={async (val) => {
            try {
              setIsLoading(true);
              var resp = await handleUpload(val);
              setCover(resp.data.path);
              setCoverUrl(resp.data.url);
            } catch (error) {
              errorToast(`${error}`);
            } finally {
              setIsLoading(false);
            }
          }}
        />

        <InlineForm paddingY={20} label="Title">
          <input
            type="text"
            name="title"
            placeholder="Title"
            style={{ width: "100%" }}
            className="mt-2 w-full border border-gray-300 rounded-lg p-2"
            value={data.title ?? ""}
            onChange={(val) => {
              setData({ ...data, title: val.target.value });
            }}
          />
        </InlineForm>

        <InlineForm alignStart paddingY={20} label="Description">
          <textarea
            name="description"
            placeholder="Description"
            style={{ width: "100%", minHeight: 100 }}
            className="mt-2 w-full border border-gray-300 rounded-lg p-2"
            value={data.description ?? ""}
            onChange={(val) => {
              setData({ ...data, description: val.target.value });
            }}
          />
        </InlineForm>
        <InlineForm alignStart paddingY={20} label="Picture">
          <div
            className="w-full h-32 flex justify-center mb-2 items-center bg-gray-50 hover:bg-gray-200 cursor-pointer rounded-lg flex-col"
            onClick={() => fileRef.current?.click()}
          >
            {picUrl ? (
              <img
                className=" opacity-80 hover:opacity-100 object-cover w-full h-32  rounded-lg"
                src={picUrl}
              />
            ) : (
              <PiCameraLight size={32} />
            )}
          </div>
        </InlineForm>
        <InlineForm alignStart paddingY={20} label="Cover">
          <div
            className="w-full h-32 flex justify-center mb-2 items-center bg-gray-50 hover:bg-gray-200 cursor-pointer rounded-lg flex-col"
            onClick={() => fileRef2.current?.click()}
          >
            {coverUrl ? (
              <img
                className=" opacity-80 hover:opacity-100 object-cover w-full h-32  rounded-lg"
                src={coverUrl}
              />
            ) : (
              <PiCameraLight size={32} />
            )}
          </div>
        </InlineForm>
        <InlineForm paddingY={20} label="Use Webhook">
          <Toggle
            checked={isExternatl}
            onChange={(val) => {
              setIsExternatl(val);
            }}
          />
        </InlineForm>
        {isExternatl && (
          <>
            <InlineForm paddingY={20} label="Submit Url">
              <input
                type="text"
                name="submit_url"
                placeholder="Submit Url"
                style={{ width: "100%" }}
                className="mt-2 w-full border border-gray-300 rounded-lg p-2"
                value={data.submit_url ?? ""}
                onChange={(val) => {
                  setData({ ...data, submit_url: val.target.value });
                }}
              />
            </InlineForm>
            <InlineForm paddingY={20} label="Method">
              <SelectPicker
                block
                data={[
                  { label: "GET", value: "GET" },
                  { label: "POST", value: "POST" },
                  { label: "PUT", value: "PUT" },
                ]}
                value={data.method ?? ""}
                onChange={(val) => {
                  setData({ ...data, method: val! });
                }}
              />
            </InlineForm>
            <InlineForm paddingY={20} label="Headers">
              <textarea
                name="headers"
                placeholder="headers"
                style={{ width: "100%" }}
                className="mt-2 w-full border border-gray-300 rounded-lg p-2"
                value={data.headers ?? ""}
                onChange={(val) => {
                  setData({ ...data, headers: val.target.value });
                }}
              />
            </InlineForm>
          </>
        )}

        <InlineForm paddingY={20} label="Project">
          <SelectPicker
            block
            data={projects}
            labelKey="title"
            valueKey="id"
            value={selectedProjectID}
            onChange={(val) => {
              setSelectedProjectID(val!);
              setSelectedProject(projects.find((p) => p.id == val)!);
            }}
            onSearch={getAllProjects}
            placeholder="Select Project"
          />
        </InlineForm>
        <InlineForm paddingY={20} label="Board">
          <SelectPicker
            block
            data={selectedProject?.boards ?? []}
            labelKey="title"
            valueKey="id"
            value={selectedBoardID}
            onChange={(val) => {
              setSelectedBoardID(val!);
              getBoard(val!)
                .then((res) => res.json())
                .then((res) => {
                  setSelectedBoard(res.data);
                });
            }}
            onSearch={getAllProjects}
            placeholder="Select Board"
          />
        </InlineForm>
        <InlineForm paddingY={20} label="Group">
          <SelectPicker
            block
            data={selectedBoard?.columns ?? []}
            labelKey="title"
            valueKey="id"
            value={selectedColumnID}
            onChange={(val) => {
              setSelectedColumnID(val!);
              setSelectedColumn(
                selectedBoard?.columns.find((b) => b.id == val)!
              );
            }}
            onSearch={getAllProjects}
            placeholder="Select Group"
          />
        </InlineForm>
        <InlineForm paddingY={20} label="Is Public">
          <Toggle
            checked={data.is_public}
            onChange={(val) => {
              setData({ ...data, is_public: val });
            }}
          />
        </InlineForm>
      </Modal.Body>
      <Modal.Footer>
        <Button
          appearance="primary"
          onClick={() => {
            createForm({
              ...data,
              project_id: selectedProjectID,
              board_id: selectedBoardID,
              column_id: selectedColumnID,
              is_public: data.is_public,
              picture: pic,
              cover: cover,
            })
              .then((res) => res.json())
              .then((res) => {
                nav(`/project/${selectedProjectID}/form/${res.data.last_id}`)
              });
          }}
          className="w-32"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalForm;
