import type { FC } from "react";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DropItemBox from "../components/DropItemBox";
import MainLayout from "../components/MainLayout";
import UpdateFeedBox from "../components/UpdateFeed";
import { DropBoxItem } from "../model/drop_box_item";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { ProjectContext } from "../objects/project_object";
import { getDropBox, updateDropBox } from "../repositories/drop_box";
import { getTextColorBasedOnBackground, initials } from "../utils/helper";
import { DropBox } from "../model/drop_box";
import CustomAvatar from "../components/CustomAvatar";
import { Avatar } from "rsuite";
import { PiPlusBold } from "react-icons/pi";
import ModalClient from "../components/ModalClient";
import { errorToast } from "../utils/helper-ui";
import { LoadingContext } from "../objects/loading";
import { addClient, getProject } from "../repositories/project";
import { Member } from "../model/member";
interface DropBoxDetailProps {}

const DropBoxDetail: FC<DropBoxDetailProps> = ({}) => {
  const { project: activeProject, setProject: setActiveProject } =
    useContext(ProjectContext);
  const [profile, setProfile] = useState<User | null>(null);
  const { color, setColor } = useContext(BgColorContext);
  const [mounted, setMounted] = useState(false);
  const { projectId, dropBoxId } = useParams();
  const [dropBoxItems, setDropBoxItems] = useState<DropBoxItem[]>([]);
  const nav = useNavigate();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [members, setMembers] = useState<Member[]>([]);
  const [dropBox, setDropBox] = useState<DropBox | null>(null);
  const [modalClientOpen, setModalClientOpen] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (activeProject) {
      let objMember = new Map<string, Member>();
      for (const member of activeProject?.members ?? []) {
        objMember.set(member.id, member);
      }
      for (const team of activeProject?.teams ?? []) {
        for (const member of team.members) {
          objMember.set(member.id, member);
        }
      }
      setMembers(Array.from(objMember.values()));
    }
  }, [activeProject]);

  useEffect(() => {
    if (!mounted) return;
    getDetail();
  }, [mounted, projectId, dropBoxId]);

  const getDetail = () => {
    getDropBox(projectId!, dropBoxId!)
      .then((res) => res.json())
      .then((val) => {
        setDropBox(val.data);
        setDropBoxItems(val.data.drop_box_items);
      });
  };

  const getProjectDetail = async () => {
    try {
      setIsLoading(true);
      let resp = await getProject(projectId!);
      let respJson = await resp.json();
      setActiveProject(respJson.data);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const info = () => (
    <div
      style={{ width: 400 }}
      className=" h-full bg-transparent ml-4 rounded-tl-[15px]  flex flex-col pr-4 space-y-4 "
    >
      <input
        type="text"
        className="font-[400] hover:font-[500] text-xl focus:ring-0 focus:outline-none focus:ring-gray-200 "
        style={{
          color: getTextColorBasedOnBackground(color),
          textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
          background: "transparent",
        }}
        value={dropBox?.title}
        onChange={(val) => {
          setDropBox({
            ...dropBox!,
            title: val.target.value,
          });
        }}
        onKeyUp={async (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            try {
              setIsLoading(true);
              const res = await updateDropBox(projectId!, dropBoxId!, {
                title: dropBox!.title,
                project_id: projectId!,
              });
              const val = await res.json();
              let resProject = await getProject(projectId!);
              let resProjectJson = await resProject.json();
              setActiveProject(resProjectJson.data);
            } catch (error) {
              errorToast(`${error}`);
            } finally {
              setIsLoading(false);
            }
          }
        }}
      />

      <div
        className="bg-white  p-4 rounded-lg flex flex-row gap-4  cursor-pointer"
        onClick={() => nav(`/project/${activeProject?.id}`)}
      >
        <div>
          <div className="w-16 rounded-lg aspect-square bg-violet-800 text-white flex justify-center items-center text-2xl">
            {activeProject?.picture_url ? (
              <img
                className="w-full h-full object-cover rounded-lg"
                src={activeProject?.picture_url}
              />
            ) : (
              initials(activeProject?.title)
            )}
          </div>
        </div>

        <div className="flex flex-col">
          <h3 className="font-[500] text-xl hover:font-[600]">
            {activeProject?.title}
          </h3>
          <p>{activeProject?.description}</p>
        </div>
      </div>
      <div>
        <h3
          className="font-[400] hover:font-[500] text-lg "
          style={{
            color: getTextColorBasedOnBackground(color),
            textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
          }}
        >
          Creator
        </h3>
        <div
          className="flex flex-row items-center gap-2 py-1"
          key={activeProject?.creator?.id}
          style={{
            color: getTextColorBasedOnBackground(color),
            textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
          }}
        >
          <CustomAvatar
            alt={activeProject?.creator?.user.full_name}
            src={activeProject?.creator?.user.picture_url}
          />
          <div className="flex flex-col">
            {activeProject?.creator?.user.full_name}
            <small>{activeProject?.creator?.role?.name}</small>
          </div>
        </div>
      </div>
      <div>
        <h3
          className="font-[400] hover:font-[500] text-lg "
          style={{
            color: getTextColorBasedOnBackground(color),
            textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
          }}
        >
          Members
        </h3>
        {activeProject?.members.map((e) => (
          <div
            className="flex flex-row items-center gap-2 py-1 mb-2"
            key={e.id}
            style={{
              color: getTextColorBasedOnBackground(color),
              textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
            }}
          >
            <CustomAvatar alt={e.user.full_name} src={e.user.picture_url} />
            <div className="flex flex-col">
              {e.user.full_name}
              <small>{e.role?.name}</small>
            </div>
          </div>
        ))}
      </div>
      <div>
        <h3
          className="font-[400] hover:font-[500] text-lg "
          style={{
            color: getTextColorBasedOnBackground(color),
            textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
          }}
        >
          Teams
        </h3>
        {activeProject?.teams.map((e) => (
          <div
            className="flex flex-row items-center gap-2 py-1 cursor-pointer"
            key={e.id}
            onClick={() => {}}
            style={{
              color: getTextColorBasedOnBackground(color),
              textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
            }}
          >
            <CustomAvatar
              key={e.id}
              alt={e.title}
              color={e.color}
              src={e.picture_url}
            />
            <div className="flex flex-col">
              <h3>{e.title}</h3>
              {e.description && <p className="text-xs ">{e.description}</p>}
            </div>
          </div>
        ))}
      </div>
      <div>
        <div className="flex flex-row justify-between items-center">
          <h3
            className="font-[400] hover:font-[500] text-lg "
            style={{
              color: getTextColorBasedOnBackground(color),
              textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
            }}
          >
            Clients
          </h3>
          <PiPlusBold
            className="text-white cursor-pointer"
            size={16}
            onClick={() => {
              setModalClientOpen(true);
            }}
          />
        </div>
        {activeProject?.clients.map((e) => (
          <div
            className="flex flex-row items-center gap-2 py-1 mb-2"
            key={e.id}
            style={{
              color: getTextColorBasedOnBackground(color),
              textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
            }}
          >
            <CustomAvatar alt={e.user.full_name} src={e.user.picture_url} />
            <div className="flex flex-col">
              <h3>{e.user?.full_name}</h3>
              {e.client_company && (
                <p className="text-xs ">{e.client_company?.name}</p>
              )}
              {e.user && <p className="text-xs ">{e.user?.email}</p>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  return (
    <MainLayout
      onLoadProfile={(val) => {
        setProfile(val!);
      }}
      onLoadPermissions={(val) => {
        setPermissions(val);
      }}
    >
      <div
        className="flex flex-col flex-1 overflow-x-auto  h-full"
        style={{ backgroundColor: color }}
      >
        <div className="flex flex-row h-full  ">
          <div
            className="  h-full ml-4   flex flex-col w-full overflow-y-auto items-center"
            style={{ height: "calc(100vh - 50px)" }}
          >
            <UpdateFeedBox
              profile={profile}
              onSubmit={() => {
                getDetail();
              }}
              channels={[]}
              members={members ?? []}
              clients={activeProject?.clients ?? []}
            />
            {dropBoxItems.map((e) => (
              <DropItemBox
                data={e}
                profile={profile}
                members={members ?? []}
                clients={activeProject?.clients ?? []}
              />
            ))}
          </div>
          {info()}
        </div>
      </div>
      <ModalClient
        open={modalClientOpen}
        onClose={() => setModalClientOpen(false)}
        existingClients={activeProject?.clients ?? []}
        onSave={(val) => {
          setIsLoading(true);
          addClient(
            projectId!,
            val.map((e) => e.id)
          )
            .then((res) => res.json())
            .then((resp) => {
              getProjectDetail();
              setModalClientOpen(false);
            })
            .catch(errorToast)
            .finally(() => setIsLoading(false));
        }}
      />
    </MainLayout>
  );
};
export default DropBoxDetail;
