import type { FC } from "react";
import MainLayout from "../components/MainLayout";
import { User } from "../model/user";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BgColorContext } from "../objects/color_object";
import { Team } from "../model/team";
import {
  createTeam,
  deleteMember,
  getTeam,
  getTeams,
  teamAddMember,
  updateTeam,
} from "../repositories/team";
import TeamCard from "../components/TeamCard";
import { LoadingContext } from "../objects/loading";
import { errorToast } from "../utils/helper-ui";
import {
  Avatar,
  Divider,
  Drawer,
  Input,
  Loader,
  Modal,
  SelectPicker,
  Uploader,
} from "rsuite";
import { CiCamera } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { asyncLocalStorage } from "../utils/storage";
import { COMPANY_ID, TOKEN } from "../objects/constants";
import ModalColor from "../components/ModalColor";
import { ColorResult } from "react-color";
import SearchPeople from "../components/SearchPeople";
import { initials } from "../utils/helper";
import { uploadFile } from "../repositories/file";
import { PiTrashBold } from "react-icons/pi";
import Swal from "sweetalert2";
import { BsPlusCircle } from "react-icons/bs";

interface TeamPageProps {}

const TeamPage: FC<TeamPageProps> = ({}) => {
  const [profile, setProfile] = useState<User>();
  const { color, setColor } = useContext(BgColorContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  const [search, setSearch] = useState("");
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [teams, setTeams] = useState<Team[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [token, setToken] = useState("");
  const [companyID, setCompanyID] = useState("");
  const nav = useNavigate();
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [teamColor, setTeamColor] = useState<ColorResult | null>(null);
  const [modalColorOpen, setModalColorOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [modalTeamDetail, setModalTeamDetail] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const [modalInvite, setModalInvite] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    asyncLocalStorage.getItem(TOKEN).then((v) => {
      setToken(v);
    });
    asyncLocalStorage.getItem(COMPANY_ID).then((v) => {
      setCompanyID(v);
    });
    getAllTeams();
  }, [mounted, page, limit, search]);

  const getAllTeams = async () => {
    try {
      setIsLoading(true);
      const res = await getTeams({ page, limit, search });
      const respJson = await res.json();
      setTeams(respJson.data);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (picturePath?: string | null, color?: string | null) => {
    try {
      try {
        const res = await updateTeam(selectedTeam!.id, {
          title: selectedTeam!.title,
          description: selectedTeam!.description,
          picture: picturePath ?? selectedTeam!.picture,
          color: color ?? selectedTeam!.color,
        });
        const respJson = await res.json();
        const team = await getTeam(selectedTeam!.id);
        const teamJson = await team.json();
        setSelectedTeam(teamJson.data);
        getAllTeams();
      } catch (error) {
        errorToast(`${error}`);
      }
    } catch (error) {
      errorToast(`${error}`);
    }
  };

  const clearForm = () => {
    setTitle("");
    setDescription("");
    setFile("");
    setFileUrl("");
    setTeamColor(null);
  };
  return (
    <MainLayout onLoadProfile={setProfile}>
      <div
        className="flex flex-col flex-1 overflow-x-auto  h-full"
        style={{ backgroundColor: color }}
      >
        <div className="  h-full bg-white ml-4 rounded-tl-[15px]  flex flex-col">
          <div className="p-4">
            <h1 className=" text-2xl font-[500]">Team</h1>
            <div className="grid grid-cols-4 gap-4 my-4">
              {teams.map((team) => (
                <TeamCard
                  key={team.id}
                  team={team}
                  onClick={(val) => {
                    setSelectedTeam(val);
                    setModalTeamDetail(true);
                  }}
                />
              ))}
              <TeamCard
                onClick={(val) => {
                  setModalOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          clearForm();
        }}
      >
        <Modal.Header>
          <Modal.Title>Add Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-3">
              <div className="flex flex-col space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Team Name
                  </label>
                  <Input
                    placeholder="Team Name"
                    value={title}
                    onChange={(e) => setTitle(e)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Description
                  </label>

                  <textarea
                    rows={3}
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full border border-gray-300 rounded-md p-2 focus:ring-1 focus:outline-none focus:ring-primary-300"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Color
                  </label>

                  <div
                    className=" aspect-square w-12 border-2 cursor-pointer"
                    style={{ backgroundColor: teamColor?.hex }}
                    onClick={() => setModalColorOpen(true)}
                  ></div>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <Uploader
                fileListVisible={false}
                listType="picture"
                onUpload={(file) => {
                  setIsLoading(true);
                }}
                accept="image/*"
                action={`${process.env.REACT_APP_API_URL}/file/upload`}
                onSuccess={async (resp) => {
                  setFile(resp.data.path);
                  setFileUrl(resp.data.url);
                  setIsLoading(false);
                }}
                headers={{
                  authorization: `Bearer ${token}`,
                  "ID-Company": companyID,
                }}
                onError={() => {
                  errorToast("Upload Failed");
                }}
              >
                <button style={{ width: 200, height: 200 }}>
                  {isLoading && <Loader backdrop center />}
                  {fileUrl ? (
                    <img src={fileUrl} width="100%" height="100%" />
                  ) : (
                    <CiCamera style={{ fontSize: 80 }} />
                  )}
                </button>
              </Uploader>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bg-violet-500 hover:bg-violet-700 text-white  py-2 px-4 rounded"
            onClick={async () => {
              setIsLoading(true);
              try {
                await createTeam({
                  title,
                  description,
                  color: teamColor?.hex ?? "",
                  picture: file,
                });
                setModalOpen(false);
                getAllTeams();
                clearForm();
              } catch (e) {
                errorToast("Create Team Failed");
              } finally {
                setIsLoading(false);
              }
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
      <ModalColor
        open={modalColorOpen}
        onClose={() => {
          setModalColorOpen(false);
        }}
        onChange={(val) => {
          setTeamColor(val!);
          if (selectedTeam) {
            update(null, val.hex);
          }
          setModalColorOpen(false);
        }}
        color={color}
      />
      <Drawer open={modalTeamDetail} onClose={() => setModalTeamDetail(false)}>
        <Drawer.Header>
          <Drawer.Title>{selectedTeam?.title}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body className="custom-drawer">
          <input
            type="file"
            accept="image/*"
            ref={fileRef}
            className="hidden"
            onChange={(val) => {
              uploadFile(val.target.files![0], (progress) => {
                console.log(progress);
              })
                .then((resp) => resp.json())
                .then((resp) => {
                  update(resp.data.path);
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          />
          <div className="flex flex-col space-y-4 cursor-pointer">
            <div onClick={() => fileRef?.current?.click()}>
              {selectedTeam?.picture_url ? (
                <img
                  src={selectedTeam?.picture_url}
                  alt=""
                  className="w-full h-[200px] object-cover rounded-lg opacity-80 hover:opacity-100"
                />
              ) : (
                <div className="w-full h-[200px] object-cover rounded-lg bg-gray-100 hover:bg-gray-300 justify-center items-center flex">
                  <CiCamera style={{ fontSize: 80 }} />
                </div>
              )}
            </div>
            <div>
              <h3 className="font-[500]">Name</h3>
              <input
                className="focus:ring-0 focus:outline-none focus:ring-gray-200  py-2 w-full"
                value={selectedTeam?.title}
                onChange={(val) =>
                  setSelectedTeam({
                    ...selectedTeam!,
                    title: val.target.value,
                  })
                }
                placeholder="Team Name"
                onBlur={() => update()}
              />
            </div>
            <div>
              <h3 className="font-[500]">Description</h3>
              <textarea
                className="focus:ring-0 focus:outline-none focus:ring-gray-200  py-2 w-full"
                value={selectedTeam?.description}
                onChange={(e) =>
                  setSelectedTeam({
                    ...selectedTeam!,
                    description: e.target.value,
                  })
                }
                placeholder="Description"
                onBlur={() => update()}
              />
            </div>
            <div>
              <h3 className="font-[500]">Color</h3>
              <div
                className=" aspect-square w-12 border-2 cursor-pointer"
                style={{ backgroundColor: selectedTeam?.color }}
                onClick={() => setModalColorOpen(true)}
              ></div>
            </div>
            <div>
              <div className="flex flex-row justify-between">
                <h3 className="font-[500] mb-4 text-xl">Members</h3>
                <BsPlusCircle className=" hover:text-blue-600"  onClick={() => setModalInvite(true)}/>
              </div>
              <ul>
                {(selectedTeam?.members ?? []).map((e) => (
                  <li
                    key={e.id}
                    className="flex flex-row justify-between items-center gap-2 mb-4 last:mb-0"
                  >
                    <div className="flex flex-row gap-2 items-center w-full">
                      <Avatar
                        circle
                        src={e.user?.picture_url}
                        alt={initials(e.user?.full_name)}
                        color="violet"
                        size="sm"
                      />
                      <div className="flex flex-col">
                        <h3 className="font-[500]">{e.user?.full_name}</h3>
                        <p className="text-xs">{e.role?.name}</p>
                      </div>
                    </div>
                    <PiTrashBold
                      className="text-red-200 hover:text-red-500"
                      size={24}
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You won't be able to revert this!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, delete it!",
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                            deleteMember(selectedTeam!.id, e.id).then(
                              (resp) => {
                                getTeam(selectedTeam!.id)
                                  .then((v) => v.json())
                                  .then((resp) => {
                                    setSelectedTeam(resp.data);
                                    getAllTeams();
                                  });
                              }
                            );
                          }
                        });
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div></div>
          </div>
          {/* <Divider></Divider>
          <h1 className="text-2xl font-[500]">Add Member</h1>
          <SearchPeople
            invitationType="MEMBER"
            team={selectedTeam}
            existingsMembers={selectedTeam?.members ?? []}
            disableTag
            onChangeMembers={(val) => {
              // selectedTeam!.members = val
              // updateTeam(selectedTeam!.id, selectedTeam!)
            }}
            onSelectMember={(val) => {
              teamAddMember(selectedTeam!.id, val.id).then((resp) => {
                getTeam(selectedTeam!.id)
                  .then((v) => v.json())
                  .then((resp) => {
                    setSelectedTeam(resp.data);
                    getAllTeams();
                  });
              });
            }}
            onDeleteMember={(val) => {
              deleteMember(selectedTeam!.id, val.id).then((resp) => {
                getTeam(selectedTeam!.id)
                  .then((v) => v.json())
                  .then((resp) => {
                    setSelectedTeam(resp.data);
                    getAllTeams();
                  });
              });
            }}
          /> */}
        </Drawer.Body>
      </Drawer>
      <Modal open={modalInvite} onClose={() => setModalInvite(false)}>
        <Modal.Header>
          <Modal.Title>Invite Member To Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchPeople
            invitationType="MEMBER"
            team={selectedTeam}
            existingsMembers={selectedTeam?.members ?? []}
            disableTag
            onChangeMembers={(val) => {
              // selectedTeam!.members = val
              // updateTeam(selectedTeam!.id, selectedTeam!)
            }}
            onSelectMember={(val) => {
              teamAddMember(selectedTeam!.id, val.id).then((resp) => {
                getTeam(selectedTeam!.id)
                  .then((v) => v.json())
                  .then((resp) => {
                    setSelectedTeam(resp.data);
                    getAllTeams();
                  });
              });
            }}
            onDeleteMember={(val) => {
              deleteMember(selectedTeam!.id, val.id).then((resp) => {
                getTeam(selectedTeam!.id)
                  .then((v) => v.json())
                  .then((resp) => {
                    setSelectedTeam(resp.data);
                    getAllTeams();
                  });
              });
            }}
          />
        </Modal.Body>
      </Modal>
    </MainLayout>
  );
};
export default TeamPage;
