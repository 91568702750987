import { ChannelReq } from "../model/channel";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/http";

export const createChannel = async (channel: ChannelReq) => {
  return await customFetch("channel", {
    method: "POST",
    body: JSON.stringify(channel),
  });
};

export const getChannels = async (pagination: PaginationReq) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };
  if (pagination.search && pagination.search != "") {
    params["search"] = pagination.search;
  }
  params["type"] = pagination.type ?? "";
  return await customFetch(`channel?${new URLSearchParams(params)}`);
};

export const countChannel = async () => {
  return await customFetch(`channel/count`);
};
export const getChannel = async (id: string) => {
  return await customFetch(`channel/${id}`);
};
export const getChannelMessage = async (id: string, messageId: string) => {
  return await customFetch(`channel/${id}/message/${messageId}`);
};
export const createChannelMessage = async (id: string, msg: any) => {
  return await customFetch(`channel/${id}/message`, {
    method: "POST",
    body: JSON.stringify(msg),
  });
};
export const channelFavorite = async (id: string) => {
  return await customFetch(`channel/${id}/favorite`, {
    method: "PUT",
  });
};
export const channelUnFavorite = async (id: string) => {
  return await customFetch(`channel/${id}/unfavorite`, {
    method: "PUT",
  });
};

export const deleteChannel = async (id: string) => {
  return await customFetch(`channel/${id}`, {
    method: "DELETE",
  });
};
