import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import "rsuite/dist/rsuite-no-reset.min.css";
import { BgColorContext } from "./objects/color_object";
import { ProjectContext } from "./objects/project_object";
import { PermissionsContext } from "./objects/permissions_object";
import { LoadingContext } from "./objects/loading";
import ProjectBoard from "./components/ProjectBoard";
import AppRoute from "./routes/AppRoute";
import { Project } from "./model/project";
import { CompanyContext } from "./objects/company_context";
import { Company } from "./model/company";
import { CounterContext, CounterNotif } from "./objects/counter_provider";
import {
  setCompanyIDStorage,
  setCompanyStorage,
  setProjectStorage,
} from "./utils/storage";
declare global {
  interface Window {
    JitsiMeetExternalAPI: any;
  }
}


const App: React.FC = () => {
  const [bgColor, setBgColor] = useState("#e53969");
  const [project, setProject] = useState<Project | null>(null);
  const [company, setCompany] = useState<Company | null>(null);
  const [counter, setCounter] = useState<CounterNotif>();
  // const { color, setColor } = useContext(BgColorContext);
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications"
        ) ||
        e.message.includes("Permission Exists")
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);
  return (
    <LoadingContext.Provider
      value={{ isLoading: loading, setIsLoading: setLoading }}
    >
      <CompanyContext.Provider
        value={{
          company,
          setCompany: (val) => {
            setCompanyStorage(val);
            setCompanyIDStorage(val?.id);
            setCompany(val);
          },
        }}
      >
        <ProjectContext.Provider
          value={{
            project,
            setProject: (val) => {
              setProject(val);
              setProjectStorage(val);
            },
          }}
        >
          <CounterContext.Provider value={{ counter, setCounter }}>
            <BgColorContext.Provider
              value={{ color: bgColor, setColor: setBgColor }}
            >
              <PermissionsContext.Provider
                value={{ permissions, setPermissions }}
              >
                <AppRoute />
              </PermissionsContext.Provider>
            </BgColorContext.Provider>
          </CounterContext.Provider>
        </ProjectContext.Provider>
      </CompanyContext.Provider>
    </LoadingContext.Provider>
  );
};

export default App;
