import type { FC } from "react";
import { useContext, useState } from "react";
import MainLayout from "../components/MainLayout";
import ProjectBoard from "../components/ProjectBoard";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { ProjectContext } from "../objects/project_object";

interface BoardDetailProps {}

const BoardDetail: FC<BoardDetailProps> = ({}) => {
  const { project: activeProject, setProject: setActiveProject } =
    useContext(ProjectContext);
    const [profile, setProfile] = useState<User>();
  const { color, setColor } = useContext(BgColorContext);

  return (
    <MainLayout onLoadProfile={setProfile} onLoadPermissions={(val) => {
      // console.log(val)
    }}>
      <div
        className="flex flex-col flex-1 overflow-x-auto  h-full"
        style={{ backgroundColor: color }}
      >
        <div className="  h-full bg-white ml-4 rounded-tl-[15px]  flex flex-col" style={{ height:"calc(100vh - 50px)"  }}>
          <ProjectBoard profile={profile} />
        </div>
      </div>
    </MainLayout>
  );
};
export default BoardDetail;
