import { asyncLocalStorage } from "./storage";
import { TOKEN, COMPANY_ID } from "../objects/constants";
import { clearStorage } from "./storage";

export async function customFetch(
    resource: string,
    config?: any,
    multipart?: boolean,
    fullUrl?: boolean,
    forceToken?: string
): Promise<Response> {
    const token = await asyncLocalStorage.getItem(TOKEN);
    const companyID = await asyncLocalStorage.getItem(COMPANY_ID);
    // const merchantID = localStorage.getItem(SELECTED_MERCHANT_ID);

    // console.log("companyID", companyID)

    if (!config) {
        config = {
            headers: {
                authorization: `Bearer ${forceToken ?? token ?? null}`,
            },
        };
    } else {
        config.headers = {
            authorization: `Bearer ${forceToken ?? token ?? null}`,
        };
    }

    if (!multipart) {
        config.headers["Content-Type"] = "application/json";
    }
    if (companyID) {
        config.headers["ID-Company"] = companyID;
    }
    config.headers["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // if (merchantID) {
    //     config.headers["ID-Merchant"] = merchantID;
    // }

    try {
        // request interceptor here
        const response = await fetch(
            fullUrl ? resource : `${process.env.REACT_APP_API_URL}/${resource}`,
            config
        );

        if (response.status !== 200) {
            const respJson = await response.json();
            throw respJson.message;
        }

        // response interceptor here
        return response;
    } catch (error) {
        if (`${error}`.includes("token is expired")) {
            await clearStorage();
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
}

