import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "rsuite";
import CustomAvatar from "../components/CustomAvatar";
import { Member } from "../model/member";
import { PiCheckBold } from "react-icons/pi";

interface ModalMemberProps {
  onClose: () => void;
  open: boolean;
  members: Member[];
  existingMembers?: Member[];
  title?: string;
  onSelectMember?: () => void;
  onSave?: (val: Member[]) => void;
}

const ModalMember: FC<ModalMemberProps> = ({
  open,
  onClose,
  members,
  existingMembers = [],
  title,
  onSave,
  onSelectMember,
}) => {
  const [selectedMembers, setselectedMembers] = useState<Member[]>([]);
  useEffect(() => {
    setselectedMembers(existingMembers);
  }, [existingMembers]);
  return (
    <Modal open={open} onClose={onClose}>
      {title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <div className="flex flex-wrap gap-4 gap-y-4">
          {(members ?? []).map((e) => {
            let exist = false;
            let checked = false;
            if (existingMembers.map((ex) => ex.id).includes(e.id)) exist = true;
            if (selectedMembers.map((ex) => ex.id).includes(e.id))
              checked = true;
            return (
              <div
                key={e.id}
                className={`flex flex-row gap-4 items-center py-4  px-8 rounded-3xl ${
                  checked
                    ? "bg-green-100 hover:bg-green-200"
                    : "bg-gray-100 hover:bg-gray-200"
                } cursor-pointer`}
                onClick={() => {
                  if (onSelectMember) {
                    onSelectMember();
                    return;
                  }
                  if (checked) {
                    setselectedMembers(
                      selectedMembers.filter((ex) => ex.id != e.id)
                    );
                  } else {
                    setselectedMembers([...selectedMembers, e]);
                  }
                }}
              >
                <CustomAvatar alt={e.user?.full_name} src={e.user?.picture_url} />
                <div className="flex flex-col">
                  <p>{e.user?.full_name}</p>
                  <small>{e.role.name}</small>
                </div>
                {checked && <PiCheckBold className="text-green-500" />}
              </div>
            );
          })}
        </div>
      </Modal.Body>
      {onSave && (
        <Modal.Footer>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
          <Button
            color="green"
            onClick={() => {
              onSave?.(selectedMembers);
              onClose();
              setTimeout(() => {
                setselectedMembers([]);
              }, 300);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default ModalMember;
