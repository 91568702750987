import { createContext } from "react";
import { Project } from "../model/project";

type ProjectContextType = {
  project?: Project | null;
  setProject: (project: Project | null) => void;
};

export const ProjectContext = createContext<ProjectContextType>({
  project: null,
  setProject: (val) => false,
});
