import React, { ReactNode, useContext } from "react";
import { PiBellSimpleRinging } from "react-icons/pi";
import { Avatar, Badge, Dropdown } from "rsuite";
import { BgColorContext } from "../objects/color_object";

export interface TopbarProps {
  children?: ReactNode
}
const Topbar: React.FC<TopbarProps> = ({children}) => {
  const { color, setColor } = useContext(BgColorContext);
  return (
    <div
      className="flex items-center justify-between  px-4 py-2 bg-white border-b"
      
    >
      {/* Left Side: Logo and "See Plans" button */}
      <div className="flex items-center">
        <div className="flex items-center space-x-2">
          {/* Logo */}
          <div className="flex items-center gap-2">
            <img src="/images/logo-agensia.svg" alt="" className="w-10 fill-violet-500" />
            <p className="font-[500]  text-violet-800 text-[16pt]">Agensia</p>
          </div>
        </div>
      </div>
      {children}
      {/* Right Side: Icons */}
      <div className="flex items-center space-x-4 text-gray-600">
        <Badge content={0} color="violet">
          <Dropdown
            className="custom-menu"
            title={<PiBellSimpleRinging size={20} className="text-violet-500" />}
            noCaret
            menuStyle={{ width: 240 }}
            placement="bottomEnd"
            trigger={"click"}
          >
            {/* <Dropdown.Item shortcut="⌘ C">Copy</Dropdown.Item>
            <Dropdown.Item shortcut="⌘ X">Cut</Dropdown.Item>
            <Dropdown.Item shortcut="⌘ V">Paste</Dropdown.Item> */}
          </Dropdown>
        </Badge>

        {/* <FaUserPlus size={20} />
        <FaCloudUploadAlt size={20} />
        <FaSearch size={20} />
        <FaQuestionCircle size={20} className="relative">
          <span className="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-500"></span>
        </FaQuestionCircle>
        <FaTh size={20} /> */}
        <Avatar
          src="https://i.pravatar.cc/150?u=1000"
          circle
          size="xs"
          bordered
          color="violet"
        />
      </div>
    </div>
  );
};

export default Topbar;
