import { Team, TeamReq } from "../model/team";
import { customFetch } from "../utils/http";
import { PaginationReq } from "../objects/pagination";

export const createTeam = async (team: TeamReq) => {
    return await customFetch(`team`, {
        method: "POST",
        body: JSON.stringify(team),
    });
    
};

export const getTeams = async (pagination: PaginationReq) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }
    return await customFetch(`team?${new URLSearchParams(params)}`);
    
};
export const getTeam = async (id: string) => {
    return await customFetch(`team/${id}`);
    
};

export const updateTeam = async (id: string, team: TeamReq) => {
    return await customFetch(`team/${id}`, {
        method: "PUT",
        body: JSON.stringify(team),
    });
    
};
export const teamAddMember = async (id: string, member_id: string) => {
    return await customFetch(`team/${id}/addMember`, {
        method: "PUT",
        body: JSON.stringify({member_id}),
    });
    
};
export const deleteMember = async (id: string, member_id: string) => {
    return await customFetch(`team/${id}/deleteMember`, {
        method: "PUT",
        body: JSON.stringify({member_id}),
    });
    
};

export const deleteTeam = async (id: string): Promise<void> => {
    await customFetch(`team/${id}`, {
        method: "DELETE",
    });
};
